import { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import VerifiedIcon from '../icons/VerifiedIcon.js';
import MatchedScribble from '../icons/MatchedScribble.js';

const RecruiterDashboardRotatingBanner = ({first_name, last_name, profile_picture, uuid, verifiedMember, paid }) => {

            return (
                <>
                    <div className="carouselCTAWrapper">
                    <div className="rf-bg-six d-sm-none d-md-block"></div>
                        <div className="container">
                            <div className="carouselCTAitem verified-promo">
                                <div className="carouselCTAitemInner">
                                    <div className="carouselCTAvisual">

                                        <div className="verifiedPromoVisual">

                                            <div className="verifiedPromoVisualTop">
                                                <VerifiedIcon />
                                                <MatchedScribble />
                                            </div>

                                            <div className="verifiedPromoVisualBottom">
                                                <div className="avatarHolder">
                                                    {profile_picture === null || typeof profile_picture === 'undefined' ? (
                                                        <img className="avatar" alt="" src={process.env.REACT_APP_RECFINDR_API+'/profile/blank.png'}/>
                                                    ) : (
                                                        <img className="avatar" alt="" src={`data:image/jpeg;base64,${profile_picture}`}/>
                                                    )} 
                                                </div>
                                                <span className="h4">{first_name} {last_name}</span>
                                            </div>
                                            
                                    </div>

                                    </div>
                                    <div className="carouselCTAcontent">
                                        <h3>Get verified</h3>
                                        <p>We all know our industry isn't exactly famous for its sterling reputation. It's often seen as a playground for the inauthentic, with its fair share of fake jobs and non-existent fake recruiter profiles.</p>
                                        <p>Create a Agency to get verified and proudly flaunt your authenticity to those hiring managers.  It's your chance to prove you're not ‘one of those.’.</p>

                                        <a href="/register/agency" className="btn btn-black">Create a Agency</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </>
            )

        }
 
export default RecruiterDashboardRotatingBanner