import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivateCompany from '../../hooks/useAxiosPrivateCompany';

import useAuth from '../../hooks/useAuth.js';

import PricingBundleFree from '../pricing-components/PricingBundleFree';
import PricingBundle1 from '../pricing-components/PricingBundle1';
import PricingBundle2 from '../pricing-components/PricingBundle2';
import PricingBundle3 from '../pricing-components/PricingBundle3';
import VerifiedIcon from '../icons/VerifiedIcon.js';
import PremiumIcon from '../icons/PremiumIcon.js';
import ErrorIcon from '../icons/ErrorIcon.js';
import CopyLinkIcon from '../icons/CopyLinkIcon.js';
import MultiScribble from '../icons/MultiScribble.js';
import { set } from 'lodash';

const CompanyAccountWelcome = (props) => {
	const { auth } = useAuth();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const rec = queryParams.get('rec'); // 'true' or 'false' as a string
	var owner;
	if (
		(window.location.hostname === 'uat.recfindr.co.uk' && queryParams.get('owner')) ||
		(window.location.hostname === 'localhost' && queryParams.get('owner'))
	) {
		var owner = true;
	} else {
		var owner = false;
	}

	const errRef = useRef();
	const [isLoading, setLoading] = useState(true);
	const [verifiedSuccess, setVerifiedSuccess] = useState('');
	const [verified, setVerified] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [err, setErr] = useState(false);
	const [profile, setProfile] = useState();
	const [members, setMembers] = useState();

	const [verifiedMemberCount, setVerifiedMemberCount] = useState();
	const [seats, setSeats] = useState();

	const [paidMemberCount, setPaidMemberCount] = useState();
	const [paidMembers, setPaidMembers] = useState();

	const [subscriptions, setSubscriptions] = useState([]);
	const [companySubscriptions, setCompanySubscriptions] = useState([]);

	

	const navigate = useNavigate();
	const axiosPrivateCompany = useAxiosPrivateCompany();

	// VERIFY PAID MEMBER
	async function handlePaidMember(e, subscriptionId, username) {
		e.persist(); // Persist the event to use in async operations

		// Cache necessary values from the event
		const { name, id, checked, type, value: eventValue } = e.target;
		const value = type === 'checkbox' ? checked : eventValue;

		var data = {
			company_id: profile.companyId,
			_id: name,
			value: checked,
			subscription_id: subscriptionId,
			username: username,
		};

		const verifyMember = async () => {
			try {
				console.log(JSON.stringify(data));
				const res = await axiosPrivateCompany.post('/api/company/allocatepaidmember', JSON.stringify(data));
				console.log(res.data);
				return res;
			} catch (err) {
				console.log(err);
				throw err; // Rethrow the error to handle it in the outer scope
			}
		};

		try {
			const response = await verifyMember();

			if (response && response.status === 200) {
				setPaidMemberCount((prevCount) => {
					if (checked) {
						if (prevCount >= seats) {
							alert('You must buy more seats');
							return prevCount;
						}
						return prevCount + 1;
					} else {
						return Math.max(0, prevCount - 1);
					}
				});

				setPaidMembers((prevMembers) => {
					const updatedMembers = [...prevMembers.updatePaidMembers];
					if (updatedMembers[id]) {
						updatedMembers[id] = { ...updatedMembers[id], paidByCompany: value };
					} else {
						updatedMembers[id] = { paidByCompany: value };
					}
					return { updatePaidMembers: updatedMembers };
				});
			} else {
				// Handle non-success scenarios
			}
		} catch (error) {
			console.error('Error in verifying member:', error);
		}
	}

	// VERIFY MEMBER
	function verifyMember(e) {
		console.log(e.target.id);
		const { name, id } = e.target;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		//console.log(value)

		if (e.target.checked === true) {
			/*
                if(verifiedMemberCount >= seats) {
                    alert('you must buy more seats')
                } else {*/
			setVerifiedMemberCount(verifiedMemberCount + 1);
			var updateMember = [...members.updateMember];
			//}
		} else if (e.target.checked === false) {
			setVerifiedMemberCount(verifiedMemberCount - 1);
			var updateMember = [...members.updateMember];
		}

		if (updateMember[id].profile) {
			updateMember[id] = {
				...updateMember[id],
				profile: {
					verifiedMember: value,
					//email_tld: e.target.attributes.email_tld.value
				},
			};
			console.log('null');
		} else {
			updateMember[id].profile.verifiedMember = value;
		}

		//console.log(updateMember)

		setMembers({
			updateMember,
		});

		var data = {
			company_id: profile.companyId,
			_id: name,
			value: e.target.checked,
			//email_tld: e.target.attributes.email_tld.value
		};

		const verifyMember = async () => {
			try {
				console.log(JSON.stringify(data));
				const res = await axiosPrivateCompany.post('/api/company/verifymember', JSON.stringify(data));
				console.log(res.data);
			} catch (err) {
				console.log(err);
			}
		};
		verifyMember();
	}

	// VERIFY DOMAIN
	function handleVerify() {
		let query = {
			domain: profile.companyDomain,
			key: profile.domainVerificationKey,
			companyId: profile.companyId,
			companyName: profile.companyName,
		};

		const data = JSON.stringify(query);
		const tryVerify = async () => {
			try {
				const response = await axiosPrivateCompany.post('/api/company/verify', data);
				console.log(response.data);
				if (response.status === 201) {
					setVerifiedSuccess(true);
					handleContinue(); // Call handleContinue directly after setting verification success.
				}
				setErrMsg(false);
			} catch (err) {
				console.log(err);
				if (!err?.response) {
					setErrMsg('No Server Response');
				} else if (err.response?.status === 401) {
					setErrMsg('Verificaton Key not found');
				} else {
					setErrMsg('Error, please contact support');
				}
			}
		};
		tryVerify();
	}

	function handleContinue() {
		setVerifiedSuccess(false);
		setVerified(true);
	}

	useEffect(() => {
		getProfile();
		console.log('render');
	}, [paidMemberCount]);

	const getProfile = async () => {
		let isMounted = true;
		const controller = new AbortController();
		try {
			const response = await axiosPrivateCompany.get('/api/company/', {
				single: controller.signal,
			});
			//console.log('data...'+JSON.stringify(response.data))
			//var profiledata = JSON.stringify(response.data);
			
			
			setVerified(response.data.verified);
			setMembers({ updateMember: response.data.members });
			setSubscriptions(response.data.subscriptions);
			isMounted && setProfile(response.data);

			setVerifiedMemberCount(
				response.data.members.map((member) => member.profile.verifiedMember).filter(Boolean).length
			);
			setCompanySubscriptions(response.data.company_subscriptions);

			var paidArray = [];
			response.data.members.map(function (member) {
				paidArray.push({
					_id: member._id,
					username: member.username,
					paidByCompany: member.profile.paidByCompany,
					subscriptionId: member.profile.subscriptionId,
				});
			});

			setPaidMembers({ updatePaidMembers: paidArray });
			console.log(paidMembers);

			setPaidMemberCount(response.data.paid_for_members.length);
			console.log(response.data.members);
			setSeats(response.data.stripe.seats);

			//setTest([response.data])
			setLoading(false);
			//console.log(profile.profiledata)
		} catch (err) {
			setLoading(false);
			console.log(err.response.data.message);
			setErrMsg(err.response.data.message);
			setErr(true);
		} finally {
			//let { username, domainVerificationKey } = profile
		}
	};
	/*
    const calculateSeatsAndMembers = (subscriptionId) => {
        // Calculate the number of seats and paid members for the given subscription
        // This is a placeholder for actual logic that would go here
        return {
            seats: 0,
            paidMemberCount: 0,
        };
    };
*/
	function formatDate(dateString) {
		const date = new Date(dateString);

		const year = date.getFullYear();
		const month = date.getMonth() + 1; // getMonth() returns 0-11
		const day = date.getDate();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();

		//return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
		return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
	}

	function extractMembers(companySubscriptions) {
		let allMembers = [];
		companySubscriptions.forEach((company) => {
			if (company.stripe && company.stripe.subscriptions) {
				company.stripe.subscriptions.forEach((subscription) => {
					if (subscription.members) {
						allMembers = allMembers.concat(subscription.members);
					}
				});
			}
		});
		return allMembers;
	}

	// Assuming `companySubscriptions` is your data from `company_subscriptions`
	const companymembers = extractMembers(companySubscriptions);

	// Filter out the current user's subscriptions
	const filteredSubscriptions = profile?.company_subscriptions
		.filter((companySubscription) => companySubscription.stripe.customerId !== profile.stripe.customerId)
		.flatMap((companySubscription) => companySubscription.stripe.subscriptions);

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className='hero-header bg-quaternary'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<h1 className='h3'>
								<div className='nameHolder'>
									<MultiScribble />
									<span>{profile?.companyDomain}</span>
								</div>
							</h1>
						</div>
					</div>
				</div>
			</div>

			<div className='app-main-wrapper'>
				<div className='rf-bg-six d-xs-none d-sm-none d-md-block'></div>
				{/****** SUBSCRIPTION BASED STUFF ******/}
				<div className='container mb-5'>
					<div className='dashboardToolbar'>
						<div className=''>
							<h3 className='mb-0'>Your Subscription</h3>
						</div>
					</div>

					{profile.stripe.activeSubscription ? (
						<>
							<div className='content-panel'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='tagsWrapper'>
											<span className='tag tag-grey'>
												<PremiumIcon /> <span className='mb-0 hfont'>Active subscription</span>{' '}
											</span>
											<span className='tag tag-white'>
												You have an active subscription.&nbsp;<a href='/agency/upgrade'>Upgrade now</a>
												&nbsp;for more seats.
											</span>
										</div>
									</div>
								</div>
							</div>

							{members.updateMember?.length > 0 ? (
								<>
									<div className='dashboardPanel'>
										<div className='row'>
											<div className='col-md-4'>
												<div className='dashboardExplainerPanel'>
													<div className='icon-medium mb-2'>
														<PremiumIcon />
													</div>
													<h4>You have {seats} premium seats available</h4>
													<p>
														Your subscription has a total of {paidMemberCount} premium seats allocated.{' '}
														<a className='link-underline' href='/agency/upgrade'>
															Upgrade now
														</a>{' '}
														for more or{' '}
														<a className='link-underline' href='/contact'>
															contact support
														</a>
														.
													</p>

													{/* {profile.paid_for_members.length > 0 ?? (
														<>
															<hr></hr>
															<h4>Premium members in your organisation</h4>
															<p>
																This includes premium members who may be allocated on another team members
																subscriptions
															</p>
															<ul>
																{profile?.paid_for_members.map((member) => (
																	<li key={member._id}>{member?.username}</li>
																))}
															</ul>
														</>
													)} */}

													{/* {companymembers.length > 0 ? (
														<>
															<h4>Premium members in your organisation</h4>
															<p>
																This includes premium members who may be allocated on another team members
																subscriptions
															</p>
															<ul>
																{companymembers.map((member) => (
																	<li key={member._id}>{member.username}</li>
																))}
															</ul>
														</>
													) : null} */}
												</div>
											</div>
											<div className='col-md-8'>
												<div className='content-panel mb-0'>
													<div className='row'>
														{profile.paid_for_members.length > 0 ?? (
															<div className='premiumUsersInfo'>
																<h4>Premium members in your organisation</h4>
																<p>Members assigned a premium seat in your organisation:</p>
																<ul className='premiumUsersList'>
																	{profile?.paid_for_members.map((member) => (
																		<li key={member._id}>{member?.username}</li>
																	))}
																</ul>
															</div>
														)}

														{companymembers.length > 0 ? (
															<div className='premiumUsersInfo'>
																<h4>Premium members in your organisation</h4>
																<p>Members assigned a premium seat in your organisation:</p>
																<ul className='premiumUsersList'>
																	{companymembers.map((member) => (
																		<li key={member._id}>
																			<PremiumIcon />
																			{member.username}
																		</li>
																	))}
																</ul>
															</div>
														) : null}
													</div>
													<div className='row'>
														{profile.stripe.subscriptions.map((subscription, index) => (
															<div className='planWrapper' key={subscription?.subscription_id}>
																<div className='planHeader'>
																	<h4>
																		Plan {index > 0 ?? index + 1}: {subscription?.product_name}{' '}
																		{subscription?.activeSubscription ? <></> : <>plan has expired</>}
																	</h4>
																	<div className='planDates'>
																		<span className=''>
																			Start date: {formatDate(subscription?.period_start)}
																		</span>
																		<span className=''>End date: {formatDate(subscription?.period_end)}</span>
																	</div>
																</div>
																<hr></hr>
																<div className='planDetails'>
																	<div className=''>
																		<span className=''>Available: {subscription?.number_of_seats}</span>
																	</div>
																	<div className=''>
																		<span className=''>
																			Allocated: {subscription?.number_of_seats_allocated}
																		</span>
																	</div>
																	<div className=''>
																		<span className=''>
																			Remaining:{' '}
																			{subscription?.number_of_seats -
																				subscription?.number_of_seats_allocated}
																		</span>
																	</div>
																	<div className=''>
																		<span className=''>
																			<a href={subscription?.invoiceLink} target='_blank'>
																				Receipt
																			</a>
																		</span>
																	</div>
																</div>

																{paidMembers.updatePaidMembers
																	.filter((member) => {
																		// Check if the member is part of this subscription
																		const isMemberInThisSubscription = subscription.members.some(
																			(subMember) => subMember._id === member._id
																		);

																		// Check if the member is paid for in any other subscription
																		const isMemberPaidForInAnotherSubscription =
																			profile.paid_for_members.some(
																				(paidMember) =>
																					paidMember._id === member._id &&
																					paidMember.subscription_id !== subscription.subscription_id
																			);

																		// For non-full subscriptions, show members not paid for in any other subscription or if they are part of this subscription
																		if (
																			subscription.number_of_seats_allocated < subscription.number_of_seats
																		) {
																			return (
																				!isMemberPaidForInAnotherSubscription || isMemberInThisSubscription
																			);
																		}

																		// If subscription is full, only show members who are part of this subscription
																		return isMemberInThisSubscription;
																	})
																	.map((member, i) => {
																		const isPaidMember = subscription.members.some(
																			(subMember) => subMember._id === member._id
																		);

																		return (
																			<div className='col-md-12' key={i}>
																				<div className='form-check form-switch form-switch-secondary'>
																					<input
																						className='form-check-input'
																						type='checkbox'
																						role='switch'
																						id={i}
																						name={member?._id}
																						username={member?.username}
																						checked={isPaidMember}
																						disabled={
																							(subscription.number_of_seats_allocated >=
																								subscription.number_of_seats ||
																								isPaidMember) &&
																							!['uat.recfindr.co.uk', 'localhost'].includes(
																								window.location.hostname
																							)
																						}
																						onChange={(e) =>
																							handlePaidMember(
																								e,
																								subscription?.subscription_id,
																								member?.username
																							)
																						}
																					/>
																					<label className='form-check-label' htmlFor={i}>
																						{member?.username}
																					</label>
																				</div>
																			</div>
																		);
																	})}
															</div>
														))}
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<></>
							)}
						</>
					) : (
						<>
							<div className='content-panel'>
								<div className='row'>
									<div className='col-md-12'>
										<div className='tagsWrapper'>
											<span className='tag tag-grey'>
												<PremiumIcon /> <span className='mb-0 hfont'>No active subscription</span>{' '}
											</span>
											<span className='tag tag-white'>
												Upgrade your plan to allocate premium accounts and maximise exposure to clients.
											</span>
										</div>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-3'>
									<PricingBundleFree
										email={auth.user}
										userId={auth.cuid}
										role={auth.role}
										stripeID={auth.stripeID}
									/>
								</div>
								<div className='col-md-3'>
									<PricingBundle1
										email={auth.user}
										userId={auth.cuid}
										role={auth.role}
										stripeID={auth.stripeID}
									/>
								</div>
								<div className='col-md-3'>
									<PricingBundle2
										email={auth.user}
										userId={auth.cuid}
										role={auth.role}
										stripeID={auth.stripeID}
									/>
								</div>
								<div className='col-md-3'>
									<PricingBundle3
										email={auth.user}
										userId={auth.cuid}
										role={auth.role}
										stripeID={auth.stripeID}
									/>
								</div>
							</div>
						</>
					)}
				</div>{' '}
				{/****** END SUBSCRIPTION BASED STUFF ******/}
				{/****** VERIFIED STUFF ******/}
				<section className=''>
					<div className='container'>
						<h3>Agency Verification</h3>
					</div>
				</section>
				{verifiedSuccess ? (
					<div className='container'>
						<div className='content-panel'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='tagsWrapper'>
										<span className='tag tag-grey'>
											<VerifiedIcon /> <span className='mb-0 hfont'>Organisation verified</span>{' '}
										</span>
										<span className='tag tag-white'>You are now the owner of {profile?.companyName}.</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<>
						<section className=''>
							<div className='container'>
								{/****** IS VERIFIED MESSAGE ******/}

								<p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
									{errMsg}
								</p>
								{verified || owner ? (
									<>
										<div className='content-panel'>
											<div className='row'>
												<div className='col-md-12'>
													<div className='tagsWrapper'>
														<span className='tag tag-grey'>
															<VerifiedIcon /> <span className='mb-0 hfont'>Organisation verified</span>{' '}
														</span>
														{profile?.owner ? (
															<>
																<span className='tag tag-white'>
																	You are the administrator of {profile?.companyDomain}
																</span>
															</>
														) : (
															<>
																<span className='tag tag-white'>
																	Only the owner of {profile?.companyDomain} can verify users
																</span>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</>
								) : (
									<></>
								)}

								{/****** Verify Users Area ******/}

								{verified || owner ? (
									<>
										{members.updateMember?.length > 0 ? (
											<>
												<div className='dashboardPanel'>
													<div className='row'>
														<div className='col-md-4'>
															<div className='dashboardExplainerPanel'>
																<span className='info-icon'>i</span>
																<h4>Verify team members registered on Recfindr</h4>
																<p>
																	These email addresses are associated with your company. Verify they work at
																	your company using the toggles.
																</p>
															</div>
														</div>
														<div className='col-md-8'>
															<div className='content-panel mb-0'>
																<div className='row'>
																	{members.updateMember.map((member, i) => (
																		<div className='col-md-12' key={i}>
																			{member?.profile?.verifiedMember ? true : false}
																			<div className='form-check form-switch'>
																				<input
																					className='form-check-input'
																					type='checkbox'
																					role='switch'
																					id={i}
																					name={member._id}
																					email_tld={member?.profile?.email_tld}
																					checked={member?.profile?.verifiedMember}
																					defaultValue={member?.profile?.verifiedMember}
																					onChange={verifyMember}
																					disabled={!(profile.owner || owner)} // Disables the input if profile.owner is false
																				/>

																				<label className='form-check-label' htmlFor={i}>
																					{member.username}
																				</label>
																			</div>
																		</div>
																	))}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										) : (
											<>
												<div className='dashboardToolbar'>
													<div className=''>
														<h4 className='mb-0'>Verify team members registered on Recfindr</h4>
													</div>
												</div>

												<div className='dashboardPanel'>
													<div className='row'>
														<div className='col-md-4'>
															<div className='dashboardExplainerPanel'>
																<p>
																	There are no potential team member detected. Team members must be registered
																	with an email address belonging to your verified domain.
																</p>
															</div>
														</div>
														<div className='col-md-8'>
															<div className='content-panel mb-0'>
																<div className='row'></div>
															</div>
														</div>
													</div>
												</div>
											</>
										)}
									</>
								) : (
									<>
										{err ? (
											'This company has been claimed by another member of your organisation. If you need assistance please contact support'
										) : (
											<>
												<div className=''>
													<div className='content-panel'>
														<div className='row'>
															<div className='col-md-12'>
																<div className='tagsWrapper'>
																	<span className='tag tag-grey'>
																		<ErrorIcon /> <span className='mb-0 hfont'>Organisation unverified</span>{' '}
																	</span>
																	<span className='tag tag-white'>
																		To manage {profile?.companyDomain} we need you to verify ownership.
																	</span>
																</div>
															</div>
														</div>
													</div>

													<div className='content-panel content-panel-medium'>
														<div className='row'>
															<div className='col-md-12'>
																<div className='verifiers'>
																	<span className='info-icon'>i</span>

																	<div className='formgroup'>
																		<h4>Verifying Your Agency</h4>
																		<p>
																			To verify ownership, we'll need you to prove that you own your domain.
																		</p>
																		<p>
																			Please add the following TXT record with the subdomain of{' '}
																			<span className='hfont'>_recfindr</span> to the DNS for{' '}
																			<span className='hfont'>{profile?.companyDomain}</span>
																		</p>

																		<div className='mb-4'>
																			<pre className='btn btn-with-icon icon-right'>
																				<span className='verificationKey d-block'>
																					{profile?.domainVerificationKey}
																				</span>
																				<input
																					type='text'
																					name='domainVerificationKey'
																					id='domainVerificationKey'
																					placeholder=''
																					className='required'
																					data-toggle='tooltip'
																					defaultValue={profile?.domainVerificationKey}
																					hidden
																				/>
																				<div
																					className='clickToCopy'
																					onClick={() => {
																						navigator.clipboard.writeText(profile?.domainVerificationKey);
																					}}>
																					<CopyLinkIcon />
																				</div>
																			</pre>
																		</div>

																		<p>Once this record has been added, click verify to claim ownership.</p>

																		<button
																			id='profileFinish'
																			className='btn btn-primary'
																			onClick={handleVerify}>
																			Verify
																		</button>
																	</div>
																</div>
															</div>
														</div>

														{profile?.verificationRequests.length > 1 ? (
															<div className='verifiers'>
																{profile?.verificationRequests.length} accounts attempting to verify this
																domain.
																<ul className='verifiersList'>
																	{profile.verificationRequests?.map((user, i) => (
																		<li key={i}>
																			<small>{user.username}</small>
																		</li>
																	))}
																</ul>
															</div>
														) : (
															<></>
														)}
													</div>
												</div>
											</>
										)}
									</>
								)}
							</div>
						</section>
					</>
				)}
			</div>
		</>
	);
};
export default CompanyAccountWelcome;
