import React from 'react';

const LocationIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    className="location-icon"
    viewBox="0 0 384 512"
  >
    <path
      fill="var(--color-black)"
      d="M336 192c0-79.5-64.5-144-144-144S48 112.5 48 192c0 16.3 7.7 42 24.7 75.4 16.4 32.2 38.8 66.4 62.1 98.3 20.3 27.9 40.7 53.3 57.2 73.1 16.5-19.8 36.9-45.2 57.2-73.1 23.2-31.9 45.6-66.2 62.1-98.3 17-33.4 24.7-59.1 24.7-75.4zm48 0c0 83.1-105.6 219-160.2 283.6-19 22.5-31.8 36.4-31.8 36.4s-12.8-13.9-31.8-36.4C105.6 411 0 275.1 0 192 0 86 86 0 192 0s192 86 192 192zm-160 0a32 32 0 1 0-64 0 32 32 0 1 0 64 0zm-112 0a80 80 0 1 1 160 0 80 80 0 1 1-160 0z"
    />
  </svg>

)
export default LocationIcon