import { useLocation } from "react-router-dom"
import { useState, useRef } from "react";
import { axiosPrivate } from "../../api/axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';





const RecruiterHeaderQuickLinks = (props) => {

    const location = useLocation();
    const [isLoading, setLoading] = useState(false);
    const [showPlans, setShowPlans] = useState(false);

    function handleShowPlans(e) {
        e.preventDefault();
        setShowPlans(true);
    }

    function handleShowPlansHide(e) {
        e.preventDefault();
        setShowPlans(false);
    }

    const [activeLink, setActiveLink] = useState('');

    const [viewMatched, setViewMatched] = useState(false)
    const [viewProfileViews, setViewProfileViews] = useState(false)
    const [viewCTAClicks, setViewCTAClicks] = useState(false)
    const [viewReviews, setViewReviews] = useState(false)

    const [ctaClicks, setCtaClicks] = useState()

    const [matches, setMatches] = useState([])
    const [views, setViews] = useState([])

    const matchedRef = useRef(null);
    const viewsRef = useRef(null);
    const ctaClicksRef = useRef(null);
    const reviewsRef = useRef(null);

    const viewMatchedHandler = () => {

        const getMatches = async () => {
            //setLoading(true);
            try {
                if (props?.paid === true)  {
                    const response = await axiosPrivate.post('/api/recruiters/matches', {
                    //single: controller.signal
                    });
                    console.log("Response from Recruiter /matches:", response.data);
                    // console.log('response.data.likes')
                    // console.log(response.data.likes)
                    
                    setMatches(response.data);
                } else {
                    setMatches({'paid':false})
                }
                //console.log(matches)
                
            } catch (err) {
                console.log(err);
            } finally {
                
            }
            //setLoading(false);
        }
        getMatches();
        //console.log('get matches')
        //console.log(matches)

    }

    const viewProfileViewsHandler = () => {
        
        const getViews = async () => {
            //setLoading(true);
            try {
                if (props?.paid === true)  {
                    const response = await axiosPrivate.post('/api/recruiters/showviews', {
                    //single: controller.signal
                    });
                    console.log("Response from Recruiter /showviews:", response.data);
                    //console.log(response.data)
                    setViews(response.data);
                } else {
                    setMatches({'paid':false})
                }
                
            } catch (err) {
                console.log(err);
            } finally {
                
            }
            //setLoading(false);
        }
        getViews();
        //console.log('get views')
        //console.log(matches)
    }

    const viewCTAClicksHandler = () => {
        const getCtaClicks = async () => {
            //setLoading(true);
            try {
                //if (props?.paid === true)  {
                    const response = await axiosPrivate.get('/api/interactions', {
                    //single: controller.signal
                    });
                    //console.log(response.data)
                    console.log(response.data);
                    setCtaClicks(response.data);
                    
                //} else {
                    setMatches()
                //}
                
            } catch (err) {
                console.log(err);
            } finally {
                
            }
            //setLoading(false);
        }
        getCtaClicks();
    }

    const [quickLinks, setQuickLinks] = useState([])
    const getAllQuickLinks = async () => {
        //setLoading(true);
        try {
            if (props?.paid === true)  {
                const response = await axiosPrivate.post('/api/recruiters/getAllQuicklinks', {
                //single: controller.signal
                });
                //console.log(response.data.likes)
                
                setQuickLinks(response.data);
            } else {
                setQuickLinks({'paid':false})
            }
            //console.log(matches)
            
        } catch (err) {
            console.log(err);
        } finally {
            console.log(quickLinks)
        }
        //setLoading(false);
    }

    // scroll to ref
    const scrollToRef = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    //console.log(props)
 
    //console.log(props)
    //console.log(props.views)
    if (isLoading) {
        return <div className="App"><div className="lds-facebook"><div></div><div></div><div></div></div></div>;
    }

    return (
        <div className="quickLinksWrapper">
            <div className="container">
                <div className="quickLinksBox">
                    <div className={`quickLink`} onClick={() => scrollToRef(props.matchesRef)}>
                        <div className="quickLink-top">
                            <span className="titleTag">Matches</span>
                            <FontAwesomeIcon icon={faChevronDown} className="chevDown" />
                        </div>
                        <div className="quickLink-middle">
                            <h3>
                                {props?.followerCount ? props.followerCount.length :'0'}
                            </h3>
                        </div>
                        <div className="quickLink-bottom">
                            <span className="tileText">How many employers have matched with you</span>
                        </div>
                    </div>

                    <div className={`quickLink`} onClick={() => scrollToRef(props.viewsRef)}>
                        <div className="quickLink-top">
                            <span className="titleTag">Profile views</span>
                            <FontAwesomeIcon icon={faChevronDown} className="chevDown" />
                        </div>
                        <div className="quickLink-middle">
                            {props?.paid ? 

                                <h3>{props?.clientCount?.past90Days ? props?.clientCount?.past90Days : '0'}
                                </h3> :
                                <div className="quicklink-upgrade-placeholder">
                                    <h3>?</h3>
                                </div>
                            }
                        </div>
                        <div className="quickLink-bottom">
                            <span className="tileText">
                            {props?.paid ? 
                                "How many employers have viewed your profile" :
                                "Upgrade to see who's viewed your profile"
                            }
                            </span>
                        </div>
                    </div>

                    <div className={`quickLink`} onClick={() => scrollToRef(props.ctasRef)}>
                        <div className="quickLink-top">
                            <span className="titleTag">CTA clicks</span>
                            <FontAwesomeIcon icon={faChevronDown} className="chevDown" />
                        </div>
                        <div className="quickLink-middle">
                            {props?.paid ? 

                                <h3>{props?.ctaCount ? props?.ctaCount : '0'}
                                </h3> :
                                <div className="quicklink-upgrade-placeholder">
                                    <h3>?</h3>
                                </div>
                            }
                        </div>
                        <div className="quickLink-bottom">
                            <span className="tileText">
                            {props?.paid ? 
                                "How many link clicks on your profile" :
                                "Upgrade to see who's interacting with your profile"
                            }
                            </span>
                        </div>
                    </div>

                    <div className={`quickLink`} onClick={() => scrollToRef(props.reviewsRef)}>
                        <div className="quickLink-top">
                            <span className="titleTag">Reviews</span>
                            <FontAwesomeIcon icon={faChevronDown} className="chevDown" />
                        </div>
                        <div className="quickLink-middle">
                            <h3>{props?.reviewCount ? props.reviewCount :'0'}
                            </h3>
                        </div>
                        <div className="quickLink-bottom">
                            <span className="tileText">
                            How many employers have left you a review
                            </span>
                        </div>

                    </div>

                </div>
                    
                 
        

                </div>
            </div>

    )
}

export default RecruiterHeaderQuickLinks