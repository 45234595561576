import axios from '../api/axios';
import useAuth from './useAuth';

const useCompanyRefreshToken = () => {
    const { setAuth } = useAuth();


    const refresh = async () => {
        //console.log('try get refresh token')
        const response = await axios.get('/refresh/company', {
            withCredentials: true
        });
        //console.log('here')
        //console.log(response.data)
        setAuth(prev => {
            return {
                ...prev,
                accessToken: response.data.accessToken,
                role: response.data.role,
                profile_complete: response.data.profile_complete,
                activeSubscription: response.data.activeSubscription,
                stripeID: response.data.stripeID,
                user: response.data.username,
                cuid: response.data.cuid,
            }
        }); 
        return response.data.accessToken; 
        
    }
    return refresh;
}



export default useCompanyRefreshToken