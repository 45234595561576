import React from 'react';

const SuccessIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 512 512"
    className="SuccessIcon"
  >
    <path
      fill="var(--color-primary)"
      d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm113-303L241 337l-17 17-17-17-64-64-17-17 34-33.9 17 17 47 47L335 175l17-17 33.9 34-17 17z"
    />
  </svg>

)
export default SuccessIcon