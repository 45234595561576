import { useState, useEffect, useRef, isLoading, setLoading, Component } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Autocomplete from 'react-google-autocomplete';
import FilesUploadComponent from '../FileUploads';
import ClientHeader from './ClientHeader';
import Carousel from 'react-bootstrap/Carousel';
import $ from 'jquery';
import WelcomeSetupHeader from '../WelcomeSetupHeader';
import industryMaster from '../industryMaster.json';
import IndustrySelect from '../industrySelects';
import ProgressBar from 'react-bootstrap/ProgressBar';
import useAuth from '../../hooks/useAuth';

const ClientAccountWelcome = (props) => {
	const { auth } = useAuth();
	const { updateProfileComplete } = useAuth();

	const [isLoading, setLoading] = useState(true);
	const [input, setInput] = useState('');
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const [panelMessage, setPanelMessage] = useState(1);
	const [carouselDone, setCarouselDone] = useState(false);

	const [validateFirstName, setValidateFirstName] = useState();
	const [validateLastName, setValidateLastName] = useState();
	const [validateJobTitle, setValidateJobTitle] = useState();
	const [validateCompany, setValidateCompany] = useState();

	// RT - attempting active state for prev / back buttons
	const [activeSlide, setActiveSlide] = useState(0);

	// RT - prev / back button states

	const updateActiveSlide = (direction) => () => {
		if (direction === 'next') {
			setActiveSlide((prevSlide) => prevSlide + 1);
		} else if (direction === 'back' && activeSlide > 0) {
			setActiveSlide((prevSlide) => prevSlide - 1);
		}
	};

	const lastSlideIndex = 1;
	/*
	const initialValues = {
		username: "",
		role: "",
		first_name: "",
		last_name: "",
		profile_picture: "",
		job_title: "",
		linkedin_url: "",
		website_url: "",
		phone_number: "",
		company: "",
		profile_complete: "",
		location_1: {
			name: "",
			coordinates: "",
		},
		industry_1: "",
		biography: "",
	};
*/

	//const [ test, setTest ] = useState(initialValues);
	const [profile, setProfile] = useState();

	// START FORM
	function handleChange(e) {
		console.log(e.target.value);
		if (e.target.classList.contains('required')) {
			if (!e.target.value == '') {
				e.target.classList.remove('invalid');
			} else {
				e.target.classList.add('invalid');
			}
		}
		const { name, value } = e.target;
		setProfile({
			...profile,
			[name]: value,
		});
	}

	function handleSubmit() {
		//e.preventDefault();
		//setLoading(true);
		console.log('submit hit');

		
		//console.log(updateURL)
		const data = JSON.stringify(profile);
		//console.log('data next')
		//
		console.log('handleSubmit')
		console.log(data)

		const upProfile = async () => {
			try {
				const res = await axiosPrivate.put('/api/clients/', data);
				/*const response = await axiosPrivate.get(profileURL, {
                    single: controller.signal
                });*/

				//setTest([response.data])
				//setLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		upProfile();
	}

	const uuid = sessionStorage.getItem('uuid');

	const profileURL = '/api/profile/';

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const getProfile = async () => {
			try {
				const response = await axiosPrivate.get(profileURL, {
					single: controller.signal,
				});
				//console.log(response.data)
				var profiledata = JSON.parse(response.data);

				const setValues = {
					username: profiledata.username,
					role: profiledata.role,
					first_name: profiledata.first_name,
					last_name: profiledata.last_name,
					profile_picture: profiledata.profile_picture,
					job_title: profiledata.job_title,
					linkedin_url: profiledata.linkedin_url,
					website_url: profiledata.website_url,
					phone_number: profiledata.phone_number,
					company: profiledata.company,
					profile_complete: false,
					location_1: {
						name: profiledata.location_1.name,
						coordinates: profiledata.location_1.coordinates,
					},
					industry_1: profiledata.industry_1,
					sector: profiledata.sector,
					sub_sector: profiledata.sub_sector,
					type: profiledata.type,
					biography: profiledata.biography,
					video: profiledata.video,
				};

				isMounted && setProfile(setValues);
				//setTest([response.data])
				setLoading(false);
				//console.log(profile.profiledata)
			} catch (err) {
				console.log(err);
			}
		};
		getProfile();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	/* Start Populates Select Boxes */
	/*
	const industriesData = industryMaster;

	const [industryOptions, setIndustryOptions] = useState([]);
	const [sectorOptions, setSectorOptions] = useState([]);
	const [subSectorOptions, setSubSectorOptions] = useState([]);

	const [industryInput, setIndustryInput] = useState("");
	const [sectorInput, setSectorInput] = useState("");
	const [subSectorInput, setSubSectorInput] = useState("");

	useEffect(() => {
		// Populate industry dropdown options
		const industries = industriesData.industries.map((industry) => (
			<option key={industry.name} value={industry.name}>
				{industry.name}
			</option>
		));
		setIndustryOptions(industries);
	}, []);

	const handleIndustryChange = (e) => {
		const selectedIndustry = e.target.value;
		setIndustryInput(selectedIndustry);

		//setProfile({industry_1: selectedIndustry})

		// Find selected industry in the JSON data
		const industry = industriesData.industries.find(
			(industry) => industry.name === selectedIndustry
		);

		if (industry) {
			// Populate sector dropdown options based on selected industry
			const sectors = industry.sectors.map((sector) => (
				<option key={sector.name} value={sector.name}>
					{sector.name}
				</option>
			));
			setSectorOptions(sectors);
		} else {
			setSectorOptions([]);
		}

		//setSubSectorOptions([]);
		//setSectorInput('');
		setProfile({
			...profile,
			industry_1: selectedIndustry,
		});
	};

	const handleSectorChange = (e) => {
		const selectedSector = e.target.value;
		setSectorInput(selectedSector);
		// Find selected industry in the JSON data
		const industry = industriesData.industries.find(
			(industry) => industry.name === industryInput
		);
		if (industry) {
			// Find selected sector in the JSON data
			const sector = industry.sectors.find(
				(sector) => sector.name === selectedSector
			);

			if (sector) {
				// Populate subsector dropdown options based on selected sector
				const subSectors = sector.subSectors.map((subSector) => (
					<option key={subSector} value={subSector}>
						{subSector}
					</option>
				));
				setSubSectorOptions(subSectors);
			} else {
				setSubSectorOptions([]);
			}
		}

		setProfile({
			...profile,
			sector: selectedSector,
		});
	};

	const handleSubSectorChange = (e) => {
		const selectedSubSector = e.target.value;
		//console.log(selectedSubSector);
		setSubSectorInput(selectedSubSector);
		setProfile({
			...profile,
			sub_sector: selectedSubSector,
		});
	};

	const [editIndustryOpen, setEditIndustryOpen] = useState(false);
	const editIndustry = () => {
		setEditIndustryOpen(true);
		handleSectorChange({ target: { value: profile?.sector } });
		handleSubSectorChange({ target: { value: profile?.sub_sector } });
	};
	const updateIndustry = () => {
		handleSubmit();
		setEditIndustryOpen(false);
	};
	*/

	/* End Populates Select Boxes */

	function handleNext() {
		console.log('ok');
		var link = document.getElementsByClassName('carousel-control-next');
		link.onclick.call(link);
	}

	const bsCarousel = useRef(null);

	const totalItems = 4; // Replace this with your actual number of items
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const handleClick = () => {
		let nextIndex = (index + 1) % totalItems;
		setIndex(nextIndex);
	};

	// Calculate progress as a percentage
	const progress = ((activeSlide + 1) / 2) * 100;

	const finishProfile = async () => {
		updateProfileComplete(true);
		setProfile((prevState) => ({
			...prevState,
			profile_complete: true,
		}));
		handleSubmit();
		navigate('/dashboard');
	};

	const toggleClick = (text) => (event) => {
		// Cache reused selectors
		const scrollTopForm = document.querySelector('.onboarding-wrapper');
		const profileFieldsAbout = $('.profileFields .about');
		const profileCarousel = document.getElementById('profileCarousel');

		// Scroll to form if it exists
		if (scrollTopForm) {
			window.scrollTo({
				top: scrollTopForm.offsetTop - 56,
				behavior: 'smooth',
			});
		}

		// Log the provided text
		console.log(text);

		// If the about section is active, proceed with validation
		if (profileFieldsAbout.hasClass('active')) {
			console.log('about active');

			// Initial value set to the number of validations
			let aboutValidator = 6;

			// Function to check profile fields and log if invalid
			const checkProfileField = (fieldName, logMessage) => {
				if (!profile[fieldName]) {
					console.log(logMessage);
					const element = document.getElementById(fieldName);
					element.classList.add('invalid');
					aboutValidator -= 1;

					switch (fieldName) {
						case 'first_name':
							setValidateFirstName(logMessage);
							break;
						case 'last_name':
							setValidateLastName(logMessage);
							break;
						case 'company':
							setValidateCompany(logMessage);
							break;
						case 'job_title':
							setValidateJobTitle(logMessage);
							break;
						default:
					}
				}
			};

			// Validate required fields
			checkProfileField('first_name', 'First name is required');
			checkProfileField('last_name', 'Last name is required');
			checkProfileField('company', 'Company name is required');
			checkProfileField('job_title', 'Job title is required');

			// Log the final validator count
			console.log(aboutValidator);

			// If all validations passed, execute submission and handle navigation
			if (aboutValidator === 6) {
				handleSubmit();

				// Helper function to toggle class lists for profileCarousel
				const updateCarouselClasses = (classesToAdd, classesToRemove) => {
					classesToRemove.forEach((className) => profileCarousel.classList.remove(className));
					classesToAdd.forEach((className) => profileCarousel.classList.add(className));
				};

				// Navigation logic based on the text argument
				switch (text) {
					case 'next':
						bsCarousel.current.next();
						setProfile((prevState) => ({
							...prevState,
							profile_complete: true,
						}));
						setPanelMessage(8);
						setCarouselDone(true);
						updateCarouselClasses(
							['col-md-8', 'offset-md-2', 'profileFinish', 'theme-transparent'],
							['col-md-7', 'offset-md-2']
						);
						$('.profileNext').addClass('d-none');
						break;
					case 'back':
						bsCarousel.current.prev();
						setPanelMessage(1);
						break;
				}
			}
		}
	};
	/*
	const handleProfileChange = (newProfile) => {
		console.log(newProfile);
		console.log("hit");
		setProfile((prevProfile) => ({
			...prevProfile,
			...newProfile,
		}));
		console.log(profile);
	};

	const initialIndustry = profile?.industry_1.map((item) => ({
		value: item,
		label: item,
	}));

	const initialSectors = profile?.sector.map((item) => ({
		value: item,
		label: item,
	}));

	const initialSubSectors = profile?.sub_sector.map((item) => ({
		value: item,
		label: item,
	}));
*/
	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}

	const headerProps = {
		loggedIn: true,
		profile_picture: profile?.profile_picture,
	};

	return (
		<>
			<div className='app-main-wrapper onboarding-wrapper'>
				<div className='rf-bg-four d-xs-none d-sm-none d-md-block'></div>

				<section className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<ProgressBar now={progress} label={`${Math.round(progress)}%`} className='profileProgressBar' />
						</div>
					</div>
				</section>

				<section className='container'>
					<article className='' style={{ position: 'relative' }}>
						<div className='row'>
							<div className='col-md-4'>
								{panelMessage === 1 ? (
									<div>
										<h3>Well, congratulations</h3>
										<p>
											You've finally decided to search for the right consultant to help with your hiring
											needs. About time, right? But before you dive into the world of recruitment expertise,
											let's make sure your profile is up to scratch.
										</p>
										<p>Fill out the information on the right hand side to get started.</p>
									</div>
								) : (
									''
								)}
							</div>

							<div className='col-md-7 offset-md-1' id='profileCarousel'>
								<form onSubmit={handleSubmit}>
									<Carousel
										id='editProfile'
										className='profileFields'
										interval={null}
										ref={bsCarousel}
										wrap={false}
										activeIndex={index}
										onSelect={handleSelect}
										fade={true}>
										<Carousel.Item className='about'>
											<h3 className='h4'>About you</h3>
											<p>Update your photo and personal details.</p>

											<div className='formgroup'>
												<FilesUploadComponent picture={profile?.profile_picture} />
											</div>

											<div className='formgroup'>
												<label>First name *</label>
												<input
													type='text'
													id='first_name'
													className='required'
													name='first_name'
													placeholder='First name *'
													defaultValue={profile.first_name}
													onChange={handleChange}
													required
												/>
												{validateFirstName ? <div className='errmsg'>{validateFirstName}</div> : null}

												<label>Last name *</label>
												<input
													type='text'
													id='last_name'
													className='required'
													name='last_name'
													placeholder='Last name *'
													defaultValue={profile.last_name}
													onChange={handleChange}
													required
												/>
												{validateLastName ? <div className='errmsg'>{validateLastName}</div> : null}

												<label>Company *</label>
												<input
													type='text'
													id='company'
													className='required'
													name='company'
													placeholder='Company *'
													defaultValue={profile.company}
													onChange={handleChange}
													required
												/>
												{validateCompany ? <div className='errmsg'>{validateCompany}</div> : null}

												<label>Job Title *</label>
												<input
													type='text'
													name='job_title'
													id='job_title'
													placeholder='Job Title *'
													className='required'
													title='requried'
													defaultValue={profile.job_title}
													onChange={handleChange}
												/>
												{validateJobTitle ? <div className='errmsg'>{validateJobTitle}</div> : null}

												<label>Location:</label>
												<Autocomplete
													apiKey={process.env.REACT_APP_GOOGLE_API}
													defaultValue={profile.location_1.name}
													id='location_1'
													className='required'
													//placeholder="Location 1"
													onPlaceSelected={(place) => {
														console.log(place);
														console.log('Lat', place.geometry.location.lat());
														console.log('Lng', place.geometry.location.lng());
														let lng1 = place.geometry.location.lng();
														let lat1 = place.geometry.location.lat();
														setProfile((prevState) => ({
															...prevState,
															location_1: {
																...prevState.location_1,
																name: place.formatted_address,
																//type: "point",
																coordinates: [Number(lng1.toFixed(4)), Number(lat1.toFixed(4))],
															},
														}));
													}}
													onKeyPress={(e) => {
														e.key === 'Enter' && e.preventDefault();
													}}
												/>

												<label>Email</label>
												<div className={`formgroup formgroup-withicon`}>
													<img
														src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/email.svg'}
														className='inputIcon'
													/>
													<input
														type='email'
														id='username'
														className='required'
														placeholder='Email'
														name='username'
														defaultValue={profile.username}
														onChange={handleChange}
														required
														disabled
													/>

													<div className='tooltip-container'>
														<img
															src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
															className='tooltip-icon'
														/>
														<span className='tooltiptext'>
															This is associated with your login and cannot be changed
														</span>
													</div>
												</div>

												<label>Website</label>
												<div className={`formgroup formgroup-withicon`}>
													<img
														src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/website.svg'}
														className='inputIcon'
													/>
													<input
														type='text'
														id='website_url'
														name='website_url'
														placeholder='Website'
														defaultValue={profile.website_url}
														onChange={handleChange}
													/>

													<div className='tooltip-container'>
														<img
															src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
															className='tooltip-icon'
														/>
														<span className='tooltiptext'>Your company website</span>
													</div>
												</div>
											</div>
										</Carousel.Item>

										<Carousel.Item className='location final-slide'>
											<div
												className='text-center d-flex flex-column align-content-center'
												id='profile_complete_group'>
												<h2>Simple as that.</h2>
												<p className=''>
													Start searching for the right recruitment consultant by hitting the button below.
												</p>
												<div className='btn-celebrate-wrapper'>
													<button
														id='profileFinish'
														className='btn btn-black btn-lg align-self-center'
														onClick={finishProfile}>
														Let's go
													</button>
												</div>
											</div>
										</Carousel.Item>
									</Carousel>
								</form>
							</div>

							<div className='d-flex flex-row align-items-center justify-content-end'>
								<button
									id='profileNext'
									className='profileNext'
									onClick={(e) => {
										//updateActiveSlide("next")();
										toggleClick('next')(e);
									}}>
									Next
								</button>
							</div>
						</div>

						<img
							src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/scribble_3.svg'}
							className='bgScribble'
						/>

						<br />
					</article>
				</section>
			</div>
		</>
	);
};
export default ClientAccountWelcome;
