//import React from "react";
import { useState } from 'react';
import { NavLink } from "react-router-dom";
import RecfindrLogo from '../icons/RecfindrLogo.js';
import { useEffect } from 'react';



const CompanyNav = (props) => {


    const [isRecruiter, setIsRecruiter] = useState(false);
	
    useEffect(() => {
        if (sessionStorage.getItem('recruiterAccount') !== null) {
            setIsRecruiter(true);
        }
    }, [])


    return (
        <header className={`headerLinks`}>
            <div className="logo">
                <NavLink to="/agency/welcome">
                    <RecfindrLogo />
                </NavLink>
            </div>
            
            <nav>
                <ul>
                    <li>
                    {isRecruiter === true ? (
                        // Render NavLink for when rec=true
                        <a href="/dashboard"
                        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                        >
                        Recruiter Dashboard
                        </a>
                    ) : (
                        // Render the original NavLink for when rec is not true
                        <NavLink
                        className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                        to="/welcome"
                        >
                        Dashboard
                        </NavLink>
                    )}
                    </li>
                    <li className="d-none">
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/pricing">Pricing</NavLink>
                    </li>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/agency/logout">Logout</NavLink>
                    </li>
                    
                </ul>
            </nav>
        </header>
    )
}

export default CompanyNav;