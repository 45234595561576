import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';

import axios from '../api/axios';
const LOGIN_URL = '/auth/company';

const CompanyLogin = () => {
    const { setAuth } = useAuth();

    const navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [activation, setActivation] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleCompanyActivationEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/register/activationEmail/company',
                JSON.stringify({ user }),
                {
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));

        } catch (err) {
            if (!err?.response) {
                //setErrMsg('No Server Response');
            } 
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: {
                    'Content-Type': 'application/json',
                    //'Access-Control-Allow-Origin': 'http://localhost:3000',
                    //'Access-Control-Allow-Methods': 'OPTIONS, DELETE, POST, GET, PATCH, PUT',
                    //'Access-Control-Allow-Headers': '*'
                    },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
            //console.log('here'+JSON.stringify(response));
            //console.log('here'+JSON.stringify(response.config.data));

            
            const accessToken = response?.data?.accessToken;
            const role = response?.data?.role;
            const uuid = response?.data?.uuid;
            const profile_complete = response?.data?.profile_complete;
            const stripeID = response?.data?.stripe?.customerId;
            const activeSubscription = response?.data?.stripe?.activeSubscription;
            setAuth({ user, pwd, uuid, role, profile_complete, accessToken, stripeID, activeSubscription });
            setUser('');
            setPwd('');
            sessionStorage.setItem("role", role);
            
            //navigate(from,{replace: true, state:{role: role}});
            navigate("/agency", {replace: true, state:{role: role}});
            
            //console.log('Login.js... uuid is... '+uuid)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 402) {
                setErrMsg('Account not activated');
                setActivation(true);
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (

            <>

                <div className='app-main-wrapper'>
                    <section className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className='form-panel'>
                                    <h1 className='h3 text-center'>Company Login</h1>
                                    {activation === true
                                            ?
                                            <button id="#activationButton" className="btn btn-secondary mt-3" onClick={handleCompanyActivationEmail}>Resend Activation Email</button>
                                            :<></>
                                        }
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            id="username"
                                            ref={userRef}
                                            autoComplete="off"
                                            onChange={(e) => setUser(e.target.value)}
                                            value={user}
                                            placeholder="Email"
                                            required
                                        />
                                        <input
                                            type="password"
                                            id="password"
                                            onChange={(e) => setPwd(e.target.value)}
                                            value={pwd}
                                            placeholder="Password"
                                            required
                                        />
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                                        <button id="#loginButton" className="btn btn-lg btn-secondary align-self-center">Login</button>
                                    </form>
                                </div>

                                <div className="col-md-12 text-center">
                                    <p>
                                            Don't have an account? 
                                    </p>
                                    <p><Link to="/register" className='hfont'>Recruiter Registration</Link><span className='p-divider'>|</span><Link to="/register/agency" className='hfont'>Agency Registration</Link></p>
                                    <p>
                                    <Link to="/recover/agency/" className='hfont'>Recover Password</Link>
                                    </p>
                                </div>
                                <div className="rf-bg-four d-sm-none d-md-block"></div>
                            </div>
                        </div>
                    </section>
                </div>

            </>

    )
}

export default CompanyLogin
