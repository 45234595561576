import axios from 'axios';
const BASE_URL = process.env.REACT_APP_RECFINDR_API;
//const BASE_URL = 'http://192.168.8.189:3000';
//const BASE_URL = process.env.REACT_APP_RECFINDR_API;
// const BASE_URL = 'http://localhost:9000'

export default axios.create({
    baseURL: BASE_URL,
});



export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': BASE_URL,
        //'Access-Control-Allow-Methods': 'OPTIONS, DELETE, POST, GET, PATCH, PUT',
        //'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method,Access-Control-Request-Headers, Authorization'
        },
        withCredentials: true
});

export const axiosPrivateCompany = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': BASE_URL,
        //'Access-Control-Allow-Methods': 'OPTIONS, DELETE, POST, GET, PATCH, PUT',
        //'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method,Access-Control-Request-Headers, Authorization'
        },
        withCredentials: true
});

export const axiosPrivateUploader = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': BASE_URL,
        //'Access-Control-Allow-Methods': 'OPTIONS, DELETE, POST, GET, PATCH, PUT',
        //'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method,Access-Control-Request-Headers, Authorization'
        },
        withCredentials: true
});