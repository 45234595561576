import React from 'react';

const PremiumIcon = (props) => (

  <svg
  {...props}
  className="premium-icon"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  width="18.8px"
  height="18.8px"
  viewBox="0 0 18.8 18.8"
  xmlSpace="preserve"
>
  <path
    style={{
      fill: "var(--color-secondary)",
    }}
    d="M18.8,9.4c0-0.6-0.2-1.3-0.6-1.8c-0.4-0.5-0.9-1-1.4-1.2c0.2-0.6,0.3-1.3,0.1-1.9 c-0.1-0.6-0.4-1.2-0.9-1.7C15.6,2.3,15,2,14.4,1.9c-0.6-0.1-1.3-0.1-1.9,0.1c-0.3-0.6-0.7-1.1-1.2-1.4S10,0,9.4,0 C8.8,0,8.1,0.2,7.6,0.6S6.6,1.4,6.3,2C5.7,1.8,5.1,1.7,4.4,1.9C3.8,2,3.2,2.3,2.8,2.8C2.3,3.2,2,3.8,1.9,4.4C1.7,5.1,1.8,5.7,2,6.3 C1.4,6.6,0.9,7,0.6,7.6S0,8.7,0,9.4c0,0.6,0.2,1.3,0.6,1.8c0.4,0.5,0.9,1,1.4,1.2c-0.2,0.6-0.3,1.3-0.1,1.9C2,15,2.3,15.6,2.7,16 c0.5,0.4,1.1,0.7,1.7,0.9c0.6,0.1,1.3,0.1,1.9-0.1c0.3,0.6,0.7,1.1,1.2,1.4c0.5,0.4,1.2,0.6,1.8,0.6c0.6,0,1.3-0.2,1.8-0.6 s1-0.9,1.2-1.4c0.6,0.2,1.3,0.3,1.9,0.2c0.6-0.1,1.2-0.4,1.7-0.9c0.5-0.5,0.8-1,0.9-1.7s0.1-1.3-0.2-1.9c0.6-0.3,1.1-0.7,1.4-1.2 C18.6,10.7,18.8,10,18.8,9.4z M6,6.5L8.4,6l0.3,1.6c0-1.5,1-2.1,2.2-2.1l0.5,2.4C10.2,8,9,8.4,9.3,10.1l0.6,2.8l-2.4,0.5L6,6.5z  M10.7,11.7c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4C11.4,13.2,10.7,12.5,10.7,11.7z"
  />
</svg>

)
export default PremiumIcon