import { Link } from "react-router-dom"

import PricingBundleFree from "./pricing-components/PricingBundleFree";
import PricingBundle1 from "./pricing-components/PricingBundle1.js";
//import PricingBundle1Promo45 from "./pricing-components/PricingBundle1Promo45.js";
import PricingBundle2 from "./pricing-components/PricingBundle2";
import PricingBundle3 from "./pricing-components/PricingBundle3";
import BigScribble from './icons/BigScribble.js';

import useAuth from "../hooks/useAuth.js";


const Pricing = (props) => {

    const { auth } = useAuth();
    console.log(auth)

    return (
        <>

        <section className="app-main-wrapper">  
            <div className="rf-bg-six d-xs-none d-sm-none d-md-block"></div>

            <div className="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h1 className="h2 text-center">Which plan is right for you?</h1>
                            <p className="text-center"></p>
                        </div>
                    </div>

                    <div className="row">
                        <BigScribble/>
                        <div className="col-md-3">
                            <PricingBundleFree/>
                        </div>
                        <div className="col-md-3">
                            <PricingBundle1/>
                        </div>
                        <div className="col-md-3">
                            <PricingBundle2/>
                        </div>
                        <div className="col-md-3">
                            <PricingBundle3/>
                        </div>
                    </div>

                    <div className="col-md-12 text-center mt-5">
                        <p>
                                Need more seats? <b><a href={process.env.REACT_APP_MARKETING_WEBSITE+'/contact'}>Contact Support</a></b>
                        </p>
                    </div>

                </div>
            </div>
            
        </section>
        
        </>
    )
}

export default Pricing