import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import RecruiterHeader from './RecruiterHeader';
import RecruiterHeaderQuickLinks from './RecruiterHeaderQuickLinks';
import RecruiterHeaderMatches from './RecruiterHeaderMatches';
import RecruiterHeaderProfileViews from './RecruiterHeaderProfileViews';
import RecruiterHeaderWelcome from './RecruiterHeaderWelcome';
import RecruiterReviews from './RecruiterReviews';
import RecruiterHeaderCTAClicks from './RecruiterHeaderCTAClicks';
import RecruiterDashboardRotatingBanner from './RecruiterDashboardRotatingBanner';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RecruiterDashboard = () => {
	const axiosPrivate = useAxiosPrivate();
	const location = useLocation();
	const searchParams = window.location.search;
	let navigate = useNavigate();
	const [isLoading, setLoading] = useState(true);
	const [recruiter, setRecruiter] = useState();
	const [recruiterLoaded, setRecruiterLoaded] = useState(false);
	const [profilePicture, setProfilePicture] = useState();
	const [hasSubscriptionWithoutMembers, setHasSubscriptionWithoutMembers] = useState(false);
	const [intent, setIntent] = useState(null);
	const [profileComplete, setProfileComplete] = useState(false);

	useLayoutEffect(() => {
		document.body.classList.add('recruiter-dashboard-alt');
		const html = document.documentElement;
		html.classList.add('theme-seven');
		return () => {
			html.classList.remove('theme-seven');
		};
	});

	const profileURL = '/api/profile/';
	const getProfile = async () => {
		try {
			setLoading(true);
			const response = await axiosPrivate.get(profileURL);
			//console.log('profiledata....'+response.data)
			var profiledata = JSON.parse(response.data);

			if (!profiledata?.profile_complete) {
				navigate(`/welcome${searchParams}`);
			}

			const setValues = {
				id: profiledata?.id,
				role: profiledata?.role,
				uuid: profiledata?.uuid,
				username: profiledata?.username,
				first_name: profiledata?.first_name,
				profile_complete: profiledata?.profile_complete,
				profilePicture: profiledata?.profile_picture,
				reviewScore: profiledata?.reviewScore,
				reviewCount: profiledata?.reviewCount,
				followers: profiledata?.followers,
				views: profiledata.views,
				verifiedMember: profiledata?.verifiedMember,
				email_tld: profiledata?.email_tld,
				stripe: profiledata?.stripe,
				paid: profiledata?.paid,
				companyTLDExists: profiledata?.companyTLDExists,
				mySubscriptions: profiledata?.mySubscriptions,
				intent: profiledata?.intent,

			};
			console.log(setValues)

			if(profiledata.profile_complete){
				setProfileComplete(true);
			}
			if (profiledata?.paid === false && profiledata?.intent?.item && profiledata?.intent?.fulfilled === false) {
				setIntent(profiledata?.intent);
				console.log('Intent:', profiledata?.intent);
			}

			setProfilePicture(profiledata?.profile_picture);
			setRecruiter(setValues);
			setRecruiterLoaded(true);
			setLoading(false);
			
		} catch (err) {
			console.log(err);
		} finally {
			
		}
	};

	useEffect(() => {
		setLoading(true);
		let isMounted = true;
		const controller = new AbortController();
		const signal = controller.signal;

		getProfile();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	useEffect(() => {
		let isMounted = true; // Flag to track the mounting status
	
		const checkSubscriptions = () => {
			if (!recruiter) return; // Only proceed if recruiter is set
	
			const subscriptions = recruiter?.mySubscriptions?.subscriptions;
			if (!subscriptions || subscriptions.length === 0) return; // Only proceed if recruiter has subscriptions
			for (let subscription of subscriptions) {
				if (subscription.activeSubscription && subscription.number_of_seats > 0 && (!subscription.members || subscription.members.length === 0)) {
					if (isMounted) setHasSubscriptionWithoutMembers(true); // Update state only if mounted
					console.log('hasSubscriptionWithoutMembers FOUND');
					return; // Stop the loop as we found a matching subscription
				}
			}
			if (isMounted) setHasSubscriptionWithoutMembers(false); // Update state only if mounted
		};
	
		checkSubscriptions();
	
		// Cleanup function
		return () => {
			isMounted = false; // Set the flag to false when the component unmounts
		};
	}, [recruiter]);
	


	const headerProps = {
		profile_picture: profilePicture,
	};

	// refs to scroll to sections
	const matchesSectionRef = useRef(null);
	const profileViewsSectionRef = useRef(null);
	const reviewsSectionRef = useRef(null);
	const ctaClicksSectionRef = useRef(null);

	if (searchParams === '?free=1') {
		var upProfile = async () => {
			var intentData = {
				intent: {
					fulfilled: null,
					plan: null,
					item: null,
					coupon: null,
				},
			};
			try {
				const res = await axiosPrivate.put('/api/recruiters/', intentData);
				setIntent(null);
			} catch (err) {
				console.log(err.response || err);
			}
		};
		upProfile();
	}

	sessionStorage.setItem('recruiterAccount', true);
	

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}

	if (recruiterLoaded === true) {
		return (
			<>

			{/* Start Intent Notification */}
			{intent ? (
				<>
						<div className='notification-bar bg-grey'>
							<div className='container'>
								<div className='col-md-12 d-flex align-items-center justify-content-center'>
							
									<form
										action={process.env.REACT_APP_RECFINDR_API + '/api/subscribe'}
										method='POST'
										className='intentForm'>
										<input name='plan' id='plan' readOnly hidden value={intent?.plan}></input>
										{recruiter?.stripe?.customerId && (
											<input
												name='stripeID'
												id='stripeID'
												readOnly
												hidden
												defaultValue={recruiter?.stripe?.customerId}></input>
										)}
										{recruiter?.id && (
											<input name='userId' id='userId' readOnly hidden defaultValue={recruiter?.id}></input>
										)}
										{recruiter?.username && (
											<input name='email' id='email' readOnly hidden defaultValue={recruiter?.username}></input>
										)}
										{recruiter?.role && (
											<input name='role' id='role' readOnly hidden defaultValue={recruiter?.role}></input>
										)}
										<input
											className='userQuantity'
											name='quantity'
											type='number'
											id='quantity'
											aria-describedby=''
											min='1'
											defaultValue='1'
											readOnly
											hidden
										/>
										<input name='item' id='item' readOnly hidden value={intent?.item}></input>
										<input name='coupon' id='item' readOnly hidden value={intent?.coupon}></input>
										<div className='row'>
											<div className='col-md-6 text-end'>
												<span className='hfont'>{profileComplete ? <>You pre selected a plan for {intent.plan}</> : <>Welcome aboard, you pre selected a plan for {intent.plan}</>}</span>
												<span className='d-block small'>
													<a href='/upgrade'>Choose a different plan?</a>
												</span>
											</div>
											<div className='col-md-6'>
												<button id='priceSelect' className='btn btn-secondary btn-lg' type='submit'>
													Checkout
												</button>
												<button id='priceSelect' className='btn btn-black btn-lg' onClick={() => setIntent(null)}>
													Cancel
												</button>
											</div>
										</div>
									</form>
									
								</div>
							</div>
						</div>
				</>
			) : (
				null
			)}
			{/* End Intent Notification */}
			
				<section className='app-main-wrapper mt-0 pb-0'>
					<RecruiterHeaderWelcome
						first_name={recruiter?.first_name}
						profile_picture={profilePicture}
						uuid={recruiter?.uuid}
						verifiedMember={recruiter?.verifiedMember}
						paid={recruiter?.paid}
						hasSubscriptionWithoutMembers={hasSubscriptionWithoutMembers}
					/>

					<div className=''>
						<RecruiterHeaderQuickLinks
							reviewScore={recruiter?.reviewScore}
							reviewCount={recruiter?.reviewCount}
							followerCount={recruiter?.followers}
							clientCount={recruiter?.views?.clients}
							viewsCount={recruiter?.views?.all}
							ctaCount={recruiter?.views?.cta}
							paid={recruiter?.paid}
							matchesRef={matchesSectionRef}
							viewsRef={profileViewsSectionRef}
							reviewsRef={reviewsSectionRef}
							ctasRef={ctaClicksSectionRef}
						/>
					</div>
					<div className='scrolltop-offset' ref={matchesSectionRef}>
						<RecruiterHeaderMatches
							reviewScore={recruiter?.reviewScore}
							reviewCount={recruiter?.reviewCount}
							followerCount={recruiter?.followers}
							clientCount={recruiter?.views?.clients}
							viewsCount={recruiter?.views?.all}
							ctaCount={recruiter?.views?.cta}
							paid={recruiter?.paid}
						/>
					</div>
					<div className='scrolltop-offset' ref={profileViewsSectionRef}>
						<RecruiterHeaderProfileViews
							reviewScore={recruiter?.reviewScore}
							reviewCount={recruiter?.reviewCount}
							followerCount={recruiter?.followers}
							clientCount={recruiter?.views?.clients}
							viewsCount={recruiter?.views?.all}
							ctaCount={recruiter?.views?.cta}
							paid={recruiter?.paid}
						/>
					</div>
					<div className='scrolltop-offset' ref={ctaClicksSectionRef}>
						<RecruiterHeaderCTAClicks
							reviewScore={recruiter?.reviewScore}
							reviewCount={recruiter?.reviewCount}
							followerCount={recruiter?.followers}
							clientCount={recruiter?.views?.clients}
							viewsCount={recruiter?.views?.all}
							ctaCount={recruiter?.views?.cta}
							paid={recruiter?.paid}
						/>
					</div>
					<div className='scrolltop-offset' ref={reviewsSectionRef}>
						<RecruiterReviews props={recruiter?.uuid} />
					</div>
					{
    					!recruiter?.companyTLDExists &&
						<RecruiterDashboardRotatingBanner
							first_name={recruiter?.first_name}
							last_name={recruiter?.last_name}
							profile_picture={profilePicture}
							uuid={recruiter?.uuid}
							verifiedMember={recruiter?.verifiedMember}
							paid={recruiter?.paid}
						/>
					}
				</section>
				
			</>
		);
	}

	return <section></section>;
};

export default RecruiterDashboard;
