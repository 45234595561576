import { NavLink, Link } from "react-router-dom"

import BigScribble from '../icons/BigScribble.js';
import RecfindrLogo from '../icons/RecfindrLogo.js';
import LinkedinIcon from '../icons/LinkedinIcon.js';
import InstagramIcon from '../icons/InstagramIcon.js';
import YoutubeIcon from '../icons/YoutubeIcon.js';
import TiktokIcon from '../icons/TiktokIcon.js';


const SiteFooter = (props) => {

    {/* Get year - copyright */}
    const currentYear = new Date().getFullYear();

    {/* Scroll to top on click */}
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>

        <footer>
            <div className="">
                <div className="footer-top">
                    {/* Branding */}
                    <div className="footer-branding">
                        <a href={process.env.REACT_APP_MARKETING_WEBSITE}>
                            <RecfindrLogo />
                        </a>
                    </div>

                    {/* Navigation Links */}
                    <nav className="footer-nav">
                        <ul className="sitemap-links">
                            <p className="hfont">Sitemap</p>
                            <li><a href={process.env.REACT_APP_MARKETING_WEBSITE}>Home</a></li>
                            <li><a href={process.env.REACT_APP_MARKETING_WEBSITE+'/pricing'}>Pricing</a></li>
                            <li><a href={process.env.REACT_APP_MARKETING_WEBSITE+'/faqs'}>FAQ's</a></li>
                            <li><a href={process.env.REACT_APP_MARKETING_WEBSITE+'/contact'}>Contact</a></li>
                        </ul>
                        <ul className="sitemap-links">
                            <p className="hfont">Recruiters</p>
                            <li><Link to="/login">Login</Link></li>
                            <li><Link to="/register">Register</Link></li>
                            <li><Link to="/login/agency">Super Admin Agency Account</Link></li>
                        </ul>
                        <ul className="sitemap-links">
                            <p className="hfont">Employers</p>
                            <li><Link to="/login">Login</Link></li>
                            <li><Link to="/register">Register</Link></li>
                        </ul>

                        {/* Social Media Links */}
                        <div className="footer-social">
                            <a href="https://www.linkedin.com/company/recfindr/?originalSubdomain=uk" target="_blank" rel="noopener"><LinkedinIcon/></a>
                            <a href="https://www.instagram.com/recfindr" target="_blank" rel="noopener"><InstagramIcon/></a>
                            <a href="https://www.youtube.com/channel/UCJ7zHgkdMhSLpUCUYpIqj9g" target="_blank" rel="noopener"><YoutubeIcon/></a>
                            <a href="https://www.tiktok.com/@recfindr?lang=en" target="_blank" rel="noopener"><TiktokIcon/></a>
                        </div>
                    </nav>
                </div>

                <div className="footer-bottom">

                    {/* Additional Information */}
                    <div className="footer-info">
                        <p>© {currentYear} Recfindr. All rights reserved.</p>
                        <a href={process.env.REACT_APP_MARKETING_WEBSITE+'/terms'}>Website Terms</a>
                        <a href={process.env.REACT_APP_MARKETING_WEBSITE+'/privacy'} target="_blank">Privacy</a>
                        <a href={process.env.REACT_APP_MARKETING_WEBSITE+'/cookiepolicy'}>Cookies</a>
                        <a href={process.env.REACT_APP_MARKETING_WEBSITE+'/gdpr'}>GDPR</a>
                    </div>

                    {/* Back to top */}
                    <Link to="#" onClick={scrollToTop}>Back to top</Link>

                </div>
            </div>
            <BigScribble />
        </footer>
        
        </>
    )
}

export default SiteFooter