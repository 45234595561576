import { useState, useEffect } from "react";
import { axiosPrivate } from "../../api/axios";
import EnvelopeIcon from '../icons/EnvelopeIcon.js';
import MatchedScribble from '../icons/MatchedScribble.js';
import MailIcon from '../icons/MailIcon.js';
import RecruiterSendNewMessage from "./RecruiterSendNewMessage.js";

import Modal from "react-modal/lib/components/Modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const RecruiterHeaderMatches = (props) => {

    const [matches, setMatches] = useState([]);

    useEffect(() => {
        const timer = setTimeout(fetchMatchesData, 1000); // Delay of 1 second
        return () => clearTimeout(timer);
    }, []);

    const fetchMatchesData = async () => {
        try {
            const response = await axiosPrivate.post('/api/recruiters/getAllQuicklinks');

            console.log("Response from Recruiter /getAllQuicklinks:", response.data);

            setMatches(response.data?.matches || []);
            // console.log(response.data?.matches)
            
        } catch (err) {
            console.log(err);
        }
    };

    /*** Msg MODAL */
	//const [msgIsOpen, setMsgIsOpen] = useState();
	const [msgModalContent, setMsgModalContent] = useState({ id: '', name: '' });
	const [msgIsOpen, setMsgIsOpen] = useState(false);

	function openMsgModal() {
		setMsgIsOpen(true);
        console.log(msgModalContent)
        console.log('openMsgModal')
	}
    

	function afterMsgOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeMsgModal() {
		setMsgIsOpen(false);
	}

	const msgModal = (e) => {
        const id = e.currentTarget.getAttribute('data-id');
        const name = e.currentTarget.getAttribute('data-name');

        var data = {
			id: id,
		};
		//console.log(e.target.dataset.id )
		//var getProfile = '/api/recruiters/view/';
		setMsgModalContent({
			id: id,
			name: name,
		});

		console.log(data);
		openMsgModal();
	};

    


	/*** END Msg MODAL */

    return (
        <div className="matchesWrapper">
            <div className="container">
                <div className="dashboardToolbar">
                    <div className="">
                        <h3 className="mb-0">Your matches</h3>
                    </div>
                </div>
                <div className="row">
                    {matches && matches?.length > 0 ? (
                        matches.map(match => (
                            <div className="col-md-6" key={match._id}>
                                <div className="matchesuserTile">
                                    <button className="btn btn-icon"
                                            onClick={msgModal}
                                            data-id={match?._id} 
                                            data-name={match?.first_name} >
                                        <EnvelopeIcon 
                                            className="mailIcon" 
                                        />
                                    </button>

                                    <div className="matchesuserTile-userinfo">

                                    <div className="avatarWrapper">
                                        <div className="avatarImageWrapper">
                                            {match.profilePicture === null || typeof match.profilePicture === 'undefined' ? (
                                                <img className="" alt="" src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'} />
                                            ) : (
                                                <img className="" alt="" src={`data:image/jpeg;base64,${match.profilePicture}`} />
                                            )}
                                        </div>

                                        <MatchedScribble/>
                                    </div>

                                        <div className="matchesuserTile-bio">
                                            <span className="tileName">{match.first_name} {props.paid && match.last_name}</span>
                                            <span className="tileCompany">{match.company}</span>
                                            <div className="tagsWrapper">
                                                <span className="tileDate tag tag-micro tag-grey">Matched {new Date(match.date).toLocaleDateString('en-GB')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="no-content-message-panel">
                            <h4>No matches found</h4>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                isOpen={msgIsOpen}
                onAfterOpen={afterMsgOpenModal}
                onRequestClose={closeMsgModal}
                ariaHideApp={false}
                contentLabel="Msg"
                id="msgModal"
                shouldCloseOnOverlayClick={true}
                overlayClassName="msgModalOverlay"
                className="container"
                style={{
                    overlay: {
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        zIndex: 10000,
                    },
                    content: {
                        zIndex: 10000,
                    },
                }}
            >
                <div className="modal-body">
                    <div className="direct-message-panel">
                        <button className="closeRec" onClick={closeMsgModal}>
                            <FontAwesomeIcon icon={faTimes} />{" "}
                        </button>
                        <RecruiterSendNewMessage
                            ruid={msgModalContent?.id}
                            first_name={msgModalContent?.first_name}
                        />
                    </div>
                </div>
            </Modal>
            
        </div>
    )
}

export default RecruiterHeaderMatches;