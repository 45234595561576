import React from 'react';

const ArrowPrevIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 448 512"
  >
    <path
      fill="var(--color-black)"
      d="M25.4 278.6 2.7 256l22.6-22.6 144-144L192 66.7l45.2 45.3-22.6 22.6-89.4 89.4H448v64H125.3l89.4 89.4 22.5 22.6-45.2 45.3-22.6-22.6-144-144z"
    />
  </svg>

)
export default ArrowPrevIcon