import React from 'react';

const MessageIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 512 512"
  >
    <path
      fill="var(--color-black)"
      d="M112 48v167.6L64 183V0h384v183l-48 32.6V48H112zm144 362-27-18.3L48 268.9V464h416V268.9L283 391.7 256 410zm208-199.1 48-32.6V512H0V178.3l48 32.6L256 352l208-141.1zM184 96h168v48H160V96h24zm0 80h168v48H160v-48h24z"
    />
  </svg>

)
export default MessageIcon