import React from 'react';

const MailIcon = (props) => (
   <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="mail-icon"
    viewBox="0 0 58 57"
  >
    <circle cx={29} cy={28.5} r={28.2} fill="var(--color-white)" />
    <path
      fill="var(--color-black)"
      d="M18.8,23.3l10.2,7l10.2-7v-1.9H18.8V23.3z M39.2,26.2l-10.2,7l-10.2-7v9.4h20.4V26.2z M16.5,35.5V19.1h25.1v18.8H16.5V35.5z "
    />
  </svg>
)
export default MailIcon