import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import industryMaster from "./industryMaster.json";


const EmploymentSelect = ({ permanent, contract, onSelectionChange}) => {

    

    const options = [
      { value: 'contract', label: 'Contract' },
      { value: 'permanent', label: 'Permanent' },
    ];

    const initialState = options.filter(option => 
        (option.value === 'contract' && contract) || 
        (option.value === 'permanent' && permanent)
    );

    const [selectedOptions, setSelectedOptions] = useState(null);
  
    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        onSelectionChange(selectedOptions); // pass selected options back to parent
        //console.log(`Options selected:`, selectedOptions);
    };

      // Update state if props change
    useEffect(() => {
        setSelectedOptions(initialState);
    }, [contract, permanent]);

  

  const selectStyles = {
    menuPortal: styles => ({
        ...styles,
        fontSize: '16px'
    }),
    control: styles => ({
        ...styles,
        backgroundColor: '#f7f7f7',
        border:'none',
        fontSize: '16px',
        padding: 'var(--spacer-1)',
    }),
    dropdownIndicator: styles => ({ 
        ...styles, 
        color: '#000',
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }), // Add this style rule to hide the indicatorSeparator
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#000' : '#f7f7f7',
      borderRadius: state.isSelected ? '10px' : '',
      color: state.isSelected ? 'white' : 'black',
      border:'none',
      "&:hover": {                                                // overriding hover
            ...provided,                                          // apply initial styles
            backgroundColor:'var(--color-primary)',
            color:'var(--color-black)',
            borderRadius: '10px',
        },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: 'var(--color-primary)', // This is the background color of the selected options
      borderRadius: '5px',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#fff', // This is the font color of the selected options
      fontSize: '16px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '#fff', // This is the color of the remove button
      ':hover': { // Change the color on hover
        color: '#ff0000',
        backgroundColor: 'transparent',
      }
    }),
  };

  return (
    <div>
      <Select
        isMulti
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        styles={selectStyles}
        classNamePrefix="rfSelectMulti"
      />
    </div>
  );
}

export default EmploymentSelect