//import { useState } from 'react';
import LoginUser from './LoginUser';
//import WelcomeSetupHeader from './WelcomeSetupHeader';

//const LOGIN_URL = '/auth';



const Login = () => {
	//const [key, setKey] = useState('user');

	return (
		<>
			<div className='app-main-wrapper'>
				<section className='container'>
					<div className='row'>
						<div className='col-lg-6 offset-lg-3 accountTypeToggle'>
							<LoginUser />

							{/*
                        <Tabs
                            id="LoginTabs"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                            >
                            <Tab eventKey="user" title="Recfindr User">
                                <LoginUser />
                            </Tab>
                            <Tab eventKey="company" title="Company Profile">
                                <CompanyLogin />
                            </Tab>
                        </Tabs>
                        */}
						</div>
					</div>
				</section>
				<div className='rf-bg-four d-sm-none d-md-block'></div>
			</div>
		</>
	);
};

export default Login;
