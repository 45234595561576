import { useRef, useState, useEffect } from "react";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';
import { Link } from "react-router-dom";

//const USER_REGEX = /^(?:[A-Z\d][A-Z\d_-]{5,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
// eslint-disable-next-line no-control-regex
const USER_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const DOMAIN_REGEX = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/;
const COMPANYNAME_REGEX = /^[a-zA-Z|\s\d]{3,30}$/;
const REGISTER_URL = '/register/company';

const CompanyRegister = () => {
    const userRef = useRef();
    const domainRef = useRef();
    const companyNameRef = useRef();
    const errRef = useRef();
    //const roleRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [domain, setDomain] = useState('');
    const [validDomain, setValidDomain] = useState(false);
    const [domainFocus, setDomainFocus] = useState(false);

    const [companyName, setCompanyName] = useState('');
    const [validCompany, setValidCompany] = useState(false);
    const [companyNameFocus, setCompanyNameFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [role, setRole] = useState(4000);

    //const [validRole, setValidRole] = useState(false);
    //const [roleFocus, setRoleFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [GDPR, setGDPR] = useState(false);
	const [validGDPR, setValidGDPR] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidDomain(DOMAIN_REGEX.test(domain));
    }, [domain])

    useEffect(() => {
        setValidCompany(COMPANYNAME_REGEX.test(companyName));
    }, [companyName])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd, domain, companyName])

    const handleGDPR = (e) => {
		setGDPR(e.target.checked);
		setValidGDPR(e.target.checked);
		console.log(GDPR);
	};

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        const v3 = DOMAIN_REGEX.test(domain);
        const v4 = COMPANYNAME_REGEX.test(companyName);
        const v5 = GDPR;

        if (!v1 || !v2 || !v3 || !v4 || !v5) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            console.log(JSON.stringify({ user, pwd, role, domain, companyName }))
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ user, pwd, role, domain, companyName }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            setUser('');
            setDomain('');
            setCompanyName('');
            setPwd('');
            setMatchPwd('');
            setRole(4000)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else if (err.response?.status === 400) {
                setErrMsg(err.response.data.message);
                console.log(err.response.data.message)
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    


    return (
        <>
            
            {success ? (
                <div className='app-main-wrapper'>
                    <section className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="form-success-panel">
                                <h1 className="h2">Success!</h1>
                                <p>Check your inbox to activate your account</p>
                                <p>
                                    <a className="btn btn-primary btn-lg" href="/login">Sign In</a>
                                </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="rf-bg-one d-sm-none d-md-block"></div>
                </div>
            ) : (
                <div className='app-main-wrapper'>
                    <section className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="form-panel">
                                    <h1 className="h3 text-center">Company Profile Registration</h1>
                                    <form onSubmit={handleSubmit}>
                                        <label htmlFor="username">
                                            Email:
                                        </label>
                                        <div className="input-live-val-wrapper">
                                            <input
                                                type="text"
                                                id="username"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                required
                                                aria-invalid={validName ? "false" : "true"}
                                                aria-describedby="uidnote"
                                                onFocus={() => setUserFocus(true)}
                                                onBlur={() => setUserFocus(false)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faExclamation} className={validName || !user ? "hide" : "invalid"} />
                                        </div>
                                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                            <span>Must be a vaild email address</span>
                                        </p>


                                        <label htmlFor="password">
                                            Password:
                                        </label>
                                        <div className="input-live-val-wrapper">
                                            <input
                                                type="password"
                                                id="password"
                                                onChange={(e) => setPwd(e.target.value)}
                                                value={pwd}
                                                required
                                                aria-invalid={validPwd ? "false" : "true"}
                                                aria-describedby="pwdnote"
                                                onFocus={() => setPwdFocus(true)}
                                                onBlur={() => setPwdFocus(false)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faExclamation} className={validPwd || !pwd ? "hide" : "invalid"} />
                                        </div>
                                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                            <span>8 to 24 characters.<br /><br />
                                            Must include uppercase and lowercase letters, a number and a special character.<br /><br />
                                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span></span>
                                        </p>


                                        <label htmlFor="confirm_pwd">
                                            Confirm Password:
                                        
                                        </label>
                                        <div className="input-live-val-wrapper">
                                            <input
                                                type="password"
                                                id="confirm_pwd"
                                                onChange={(e) => setMatchPwd(e.target.value)}
                                                value={matchPwd}
                                                required
                                                aria-invalid={validMatch ? "false" : "true"}
                                                aria-describedby="confirmnote"
                                                onFocus={() => setMatchFocus(true)}
                                                onBlur={() => setMatchFocus(false)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faExclamation} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                        </div>
                                        <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                            <span>Must match the first password input field</span>
                                        </p>

                                        <label htmlFor="companyName">
                                            Company Name:
                                        </label>
                                        <div className="input-live-val-wrapper">
                                            <input
                                                type="text"
                                                id="companyName"
                                                ref={companyNameRef}
                                                autoComplete="off"
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                value={companyName}
                                                required
                                                aria-invalid={validCompany ? "false" : "true"}
                                                aria-describedby="companynote"
                                                onFocus={() => setCompanyNameFocus(true)}
                                                onBlur={() => setCompanyNameFocus(false)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className={validCompany ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faExclamation} className={validCompany || !companyName ? "hide" : "invalid"} />
                                        </div>
                                        <p id="companynote" className={companyNameFocus && companyName && !validCompany ? "instructions" : "offscreen"}>
                                            <span>Must be a vaild company name</span>
                                        </p>

                                        <label htmlFor="domain">
                                            Domain Name:
                                        </label>
                                        <div className="input-live-val-wrapper">
                                            <input
                                                type="text"
                                                id="domain"
                                                ref={domainRef}
                                                autoComplete="off"
                                                onChange={(e) => setDomain(e.target.value)}
                                                value={domain}
                                                required
                                                aria-invalid={validDomain ? "false" : "true"}
                                                aria-describedby="domainnote"
                                                onFocus={() => setDomainFocus(true)}
                                                onBlur={() => setDomainFocus(false)}
                                            />
                                            <FontAwesomeIcon icon={faCheck} className={validDomain ? "valid" : "hide"} />
                                            <FontAwesomeIcon icon={faExclamation} className={validDomain || !domain ? "hide" : "invalid"} />
                                        </div>
                                        <p id="domainnote" className={domainFocus ? "instructions" : "offscreen"}>
                                            <span>Must be a vaild domain name for account verification</span>
                                        </p>

                                        <div className='checkbox'>
                                            <label>I agree to <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/terms`} target='_blank'>terms of service</a> and <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/privacy`} target='_blank'>privacy policy</a></label>
                                            <input type='checkbox' checked={GDPR} onChange={handleGDPR} />
                                        </div>

                                        <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live='assertive'>
                                            {errMsg}
                                        </p>

                                        <button
                                            id='registerButton'
                                            className='btn btn-lg btn-secondary align-self-center'
                                            disabled={!validName || !validPwd || !validMatch ? true : false || !validGDPR}>
                                            Register
                                        </button>
                                    </form>
                                    
                                </div>
                                
                                <div className="col-md-12 text-center">
                                    <p>Already Registered</p>
                                    <p><Link to="/login" className='hfont'>Recruiter Login</Link><span className='p-divider'>|</span><Link to="/login/agency" className='hfont'>Agency Login</Link></p>
                                    <p>
                                    <Link to="/recover" className='hfont'>Recover Password</Link>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <div className="rf-bg-four d-sm-none d-md-block"></div>
                </div>
                
            )}
            
        </>
    )
}

export default CompanyRegister
