import { useState, useEffect, isLoading, setLoading } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ScrollToBottom from 'react-scroll-to-bottom';
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const RecruiterDirectRecruiterMessage = (props) => {

    
    const axiosPrivate = useAxiosPrivate(); 

    const [isLoading, setLoading] = useState(true);
    const [chatId, setChatId] = useState("");
    const [msg, setMsg] = useState([]);
    const [reply, setReply] = useState("");


    useEffect(() => {
        let isMounted = true;
        setLoading(true);
        

        const controller = new AbortController();

        console.log(props.dataFromParent);
        setChatId(props.dataFromParent)
        let data = {"chat_id":props.dataFromParent}
        
        console.log(data)
        var msgUrl = '/api/messages/thread';
        const getMsg =  async () => {
            try {
                const res = await axiosPrivate.post(msgUrl, data);
                
                if (res.data.length === 0) { console.log("Array is empty!") }
                console.log(res.data)
                setMsg(res.data.messages)
                
                
                
                setLoading(false);
            } catch(err) {
                console.log(err);
            }
            setLoading(false);
        }
        getMsg()
        
        return () => {
            isMounted = false;
            controller.abort();
        }
        
    },[])

    function handleKeyPress(e) {
        if(e.key === 'Enter'){
            //console.log('enter press here! ')
            //handleSubmit(e)
            e.stopPropagation();
        }
    }


    const handleSendMessage = e => {
        e.preventDefault();
        setLoading(true);

        console.log('submit hit')

        var query = {
            chat_id:chatId,
            uid:props.ruid,
            //uid:'6480bb683b57c35cbfc95ff0',
            msg:reply
        }

        const data = JSON.stringify(query);
        
        console.log(query)

        

        var replyURL = '/api/messages';
        const Reply =  async () => {
            try {
                const res = await axiosPrivate.post(replyURL, data);

                if (res.data.length === 0) { console.log("Array is empty!") }
                

                /** Start Refresh Chat **/
                var refreshdata = {"chat_id":chatId}
                //console.log('chat id..'+chatId)
                //console.log('refresh the data..'+JSON.refreshdata)
                var msgUrl = '/api/messages/thread';
                const getMsg =  async () => {
                    try {
                        const res = await axiosPrivate.post(msgUrl, refreshdata);
                        
                        if (res.data.length === 0) { console.log("Array is empty!") }
                        console.log(res.data)
                        setMsg(res.data.messages)
                        setReply('')

                        setLoading(false);
                        
                    } catch(err) {
                        console.log(err);
                    }
                    setLoading(false);
                }
                getMsg()
                /** End Refresh Chat **/

                setLoading(false);
                
                
            } catch(err) {
                console.log(err);
            }
            setLoading(false);
        }
        Reply()
        
        
    }


    
    if (isLoading) {
        return <></>;
    }




    return (
        <>

            <div className="inbox direct-message-inbox">

            <h4>Chat with {props.first_name}</h4>

            <div className="direct-message-content">

                <ScrollToBottom className="tmsgbox" mode="bottom">
                    
                    <ul className="msgbox-messages" id="recruiter-messages">
                        {msg.map((item, i) => <li key={i}
                        className={` message-holder holder-`+item.sender.role}>
                            <div className={`message-bubble role`+item?.sender?.role}>
                                
                                <div className="message-sender-wrapper">
                                    {item?.sender?.profile?.profile_picture === null || typeof item?.sender?.profile?.profile_picture === 'undefined' ? (
                                    <img className="inboxAvatarBadge small" alt="" src={process.env.REACT_APP_RECFINDR_API+'/profile/blank.png'}/>
                                    ) : (
                                        <img className="inboxAvatarBadge small" alt="" src={`data:image/jpeg;base64,${item?.sender?.profile?.profile_picture}`}/>
                                    )} 
                                    <span className="msgbox-username">{item?.sender?.profile?.first_name}</span>
                                </div>
                                
                                <span className="msgbox-message">
                                    <div dangerouslySetInnerHTML={{ __html: item?.message }} />
                                </span>

                                {/*<span className="msgbox-time">{formatDate(item?.sent)}</span>*/}
                            </div>
                        </li>)}
                    </ul>

                </ScrollToBottom>

                <form className="replyoptions">

                                <textarea
                                    value={reply}
                                    onChange={(e) => setReply(e.target.value)}
                                    type="text"
                                    placeholder="Start typing..."
                                    rows="5"
                                    maxLength={1000}
                                    minLength={3}
                                    style={{ fontFamily: "'LabilGrotesk-Light', Arial, sans-serif FontAwesome", width: '100%' }}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                ></textarea>

                        <button onClick={handleSendMessage}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>

                </form>

            </div>
            
            </div>


            
        </>
    )
}

export default RecruiterDirectRecruiterMessage