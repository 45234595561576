import React from 'react';

const CalendlyIcon = (props) => (

  <svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  height="1em"
  viewBox="0 0 448 512"
>
  <path
    fill="var(--color-black)"
    d="M152 24V0H104V24 64H0v80 48V464v48H48 400h48V464 192 144 64H344V24 0H296V24 64H152V24zM48 192H400V464H48V192z"
  />
</svg>

)
export default CalendlyIcon