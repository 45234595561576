import { useLocation, setLoading, isLoading } from "react-router-dom"
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

//import ClientReviews from './ClientReviews';
import ClientHeader from "./ClientHeader";
//import ClientHeaderQuickLinks from "./ClientHeaderQuickLinks";
import ClientHeaderWelcome from "./ClientHeaderWelcome";
import ClientSearch from "./ClientSearch.js";
import ClientFollowing from "./ClientFollowing";


const ClientDashboard = () => {

    const axiosPrivate = useAxiosPrivate(); 
    const location = useLocation();
    
    const [isLoading, setLoading] = useState(true);

    const [ client, setClient ] = useState()
    const [ clientLoaded, setClientLoaded ] = useState(false)
    const [ profilePicture, setProfilePicture ] = useState()
    const [ defaultLocation, setDefaultLocation ] = useState()
    
    const profileURL = '/api/profile/'

    const getProfile =  async () => {
        try {
            const response = await axiosPrivate.get(profileURL);
            //console.log('profiledata....'+response.data)
            var profiledata = JSON.parse(response.data);

            const setValues = {
                id: profiledata?.id,
                username: profiledata?.username,
                first_name: profiledata?.first_name,
                profile_complete: profiledata?.profile_complete,
                //reviewScore: profiledata?.reviewScore,
                //reviewCount: profiledata?.reviewCount,
                //followers: profiledata?.followers,
                //verifiedMember: profiledata?.verifiedMember,
                //email_tld: profiledata?.email_tld,
                industry_1: profiledata?.industry_1,
                sector: profiledata?.sector,
                sub_sector: profiledata?.sub_sector,
            };

            setProfilePicture({profile_picture: profiledata?.profile_picture})
            setDefaultLocation({default_location: profiledata?.location_1})

            setClient(setValues);
            setClientLoaded(true)
            setLoading(false);

            
        } catch(err) {
            console.log(err);
        }
    }


    useEffect(() => {
        setLoading(true)
        let isMounted = true;
        const controller = new AbortController();
        //const signal = controller.signal;
        
        getProfile();
        
        return () => {
            isMounted = false;
            controller.abort();
        }
    },[])

        const headerProps = {
            profile_picture: profilePicture?.profile_picture,
            header_color: 'theme-seven'
        }
        




    
return (
    <section>

        {/*<ClientHeader props={headerProps}/>*/}
        {/*<ClientHeaderWelcome props={client?.first_name}/>*/}
        <ClientFollowing client={client}/>

    </section>
)






    
}


export default ClientDashboard
