import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import ClientSearch from "./client-components/ClientSearch.js";

const Search = () => {
    
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setLoading] = useState(true);

  /* GET CLIENT PROFILE START */
  const [ setClient ] = useState()
  const [ setClientLoaded ] = useState(false)
  const [ profilePicture, setProfilePicture ] = useState()
  const [ setDefaultLocation ] = useState()
  
  const profileURL = '/api/profile/'

  const getProfile =  async () => {
      try {
          const response = await axiosPrivate.get(profileURL);
          //console.log('profiledata....'+response.data)
          var profiledata = JSON.parse(response.data);

          const setValues = {
              id: profiledata?.id,
              username: profiledata?.username,
              first_name: profiledata?.first_name,
              profile_complete: profiledata?.profile_complete,
              //reviewScore: profiledata?.reviewScore,
              //reviewCount: profiledata?.reviewCount,
              //followers: profiledata?.followers,
              //verifiedMember: profiledata?.verifiedMember,
              //email_tld: profiledata?.email_tld,
              industry_1: profiledata?.industry_1,
              sector: profiledata?.sector,
              sub_sector: profiledata?.sub_sector,
          };

          setProfilePicture({profile_picture: profiledata?.profile_picture})
          setDefaultLocation({default_location: profiledata?.location_1})

          setClient(setValues);
          setClientLoaded(true)
          setLoading(false);

          
      } catch(err) {
          console.log(err);
      }
  }
  /* GET CLIENT PROFILE END */
  

  useEffect(() => {
      setLoading(true)
      let isMounted = true;
      const controller = new AbortController();
      //const signal = controller.signal;
      
      getProfile();
      
      return () => {
          isMounted = false;
          controller.abort();
      }
  },[])

  const headerProps = {
    profile_picture: profilePicture?.profile_picture,
    header_color: 'theme-seven'
  }

  return (
        <>
            <ClientSearch />
        </>
  )
}

export default Search