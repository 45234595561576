import React from 'react';

const ReviewIcon = (props) => (

  <svg
  {...props}
  className="reviewicon"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  width="58px"
  height="57px"
  viewBox="0 0 58 57"
  xmlSpace="preserve"
>
  <circle
    className="reviewbg"
    style={{
      fill: "var(--color-light-grey)",
    }}
    cx={29}
    cy={28.5}
    r={28.2}
  />
      <path 
      style={{
        fill: "var(--color-black)",
      }}
      d="M24.1,41.8l2.4-1.6l4.9-3.3h8.2H42v-2.4V19.8v-2.4h-2.4H18.4H16v2.4v14.7v2.4h2.4h3.3h2.4v2v0.5V41.8z M30.1,34.9l-3.5,2.4 v-0.3v-2.4h-2.4h-5.7V19.8h21.2v14.7h-8.2h-0.7L30.1,34.9z M28.3,22.9h-5v5h2.9v1.4h-1.1H24v2.1h1.1h2.1h1.1v-1.1v-2.5v-2.1v-1.1 V22.9z M34.7,22.9h-5v5h2.9v1.4h-1.1h-1.1v2.1h1.1h2.1h1.1v-1.1v-2.5v-2.1v-1.1V22.9z" />

</svg>

)
export default ReviewIcon