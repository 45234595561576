import React from 'react';

const SterlingIcon = (props) => (

<svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 512 512"
    className="sterling-icon"
  >
    <path
      fill="var(--color-black)"
      d="M464 256a208 208 0 1 0-416 0 208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0 256 256 0 1 1-512 0zm261.5-96c-16.8 0-30.4 13.7-30.3 30.5l.2 33.5H288v32h-56.3l.3 41.2v5.9l-2.7 5.3-6 11.6H352v48H144.7l18-35 21.3-41.4-.3-35.6H160v-32h23.5l-.2-33.1c-.3-43.5 34.8-78.9 78.3-78.9 10 0 19.8 1.9 29.1 5.6l30.3 12.1 22.3 8.9-17.8 44.6-22.3-8.9-30.3-12.1c-3.6-1.4-7.4-2.2-11.3-2.2z"
    />
  </svg>

)
export default SterlingIcon