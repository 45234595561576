import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {

        //console.log('try get refresh token')
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            //console.log('useRefreshToken!!')
            //console.log('prev token '+JSON.stringify(prev.accessToken));
            //console.log('new token '+response.data.accessToken);
            //console.log('responce-data...'+JSON.stringify(response.data))
            //console.log('prev...'+JSON.stringify(prev))
            //console.log(response.data)
            return {
                ...prev,
                accessToken: response.data.accessToken,
                role: response.data.role,
                profile_complete: response.data.profile_complete,
                cuid: response.data.cuid,
                paid: response.data.paid,
                paidBySelf: response.data.paidBySelf,
                paidByCompany: response.data.paidByCompany,
                user: response.data.username,
                stripeID: response.data.stripeID
            }
        });      
        return response.data.accessToken; 
        
    }
    return refresh;
}



export default useRefreshToken