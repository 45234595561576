//import { Link } from "react-router-dom"

import PremiumIcon from '../icons/PremiumIcon.js';
import UnderlineScribble from '../icons/UnderlineScribble.js';
import ErrorIcon from '../icons/ErrorIcon.js';
import SuccessIcon from '../icons/SuccessIcon.js';
import useAuth from '../../hooks/useAuth.js';

/*
const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.id)
    console.log(e.target.value)
}
*/

const PricingBundleFree = ({ email, userId, role, stripeID }) => {
	const { auth } = useAuth();
	console.log(auth);

	var freeURL = process.env.REACT_APP_PUBLIC_URL + '/register';
	if (role === 3001) {
		var freeURL = process.env.REACT_APP_PUBLIC_URL + '/dashboard?free=1';
	} else if (role === 4000) {
		var freeURL = process.env.REACT_APP_PUBLIC_URL + '/agency/';
	}

	const goToDashboard = () => {
		window.location.href = '/dashboard?free=1';
	};

	return (
		<div className='pricing-panel pricingBundleFree' id='BundleFree'>
			<div className='pricing-top'>
				<span className='pricePlanName'>
					<PremiumIcon /> Free
				</span>
				<div className='priceValue'>
					<div className='priceMain'>
						<div className='priceCurrency'>&#163;</div>
						<h1 className='h2'>0</h1>
					</div>
				</div>

				<span className='tag tag-light-grey'>free, forever</span>
			</div>
			<div className='pricing-features'>
				<span>What's included</span>
				<UnderlineScribble />

				<ul>
					<li>
						<span className='featureListItem'>
							<SuccessIcon /> Match with employers
						</span>
					</li>
					<li>
						<span className='featureListItem'>
							<SuccessIcon /> Add employer reviews
						</span>
					</li>
					<li>
						<span className='featureListItem'>
							<ErrorIcon /> Profile views insight
						</span>
					</li>
					<li>
						<span className='featureListItem'>
							<ErrorIcon /> Profile clicks insight
						</span>
					</li>
					<li>
						<span className='featureListItem'>
							<ErrorIcon /> Add direct contact details
						</span>
					</li>
					<li>
						<span className='featureListItem'>
							<ErrorIcon /> Feature First in search
						</span>
					</li>
					<li>
						<span className='featureListItem'>
							<ErrorIcon /> Premium profile
						</span>
					</li>
				</ul>
			</div>
			<div className='pricing-cta'>
				{!auth.role ? (
					<form action={freeURL} method='GET'>
						<input type='hidden' name='free' value='1' />
						<button id='priceSelect' className='priceSelect mt-3 btn btn-grey btn-lg' type='submit'>
							Select
						</button>
					</form>
				) : (
					<button id='priceSelect' className='priceSelect mt-3 btn btn-grey btn-lg' onClick={goToDashboard}>
						Select
					</button>
				)}
			</div>
		</div>
	);
};

export default PricingBundleFree;
