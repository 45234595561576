import React from "react";
import { NavLink, Link } from "react-router-dom";
import RecfindrLogo from '../icons/RecfindrLogo.js';


const DefaultNav = (props) => {

    return (
        <header className={`headerLinks`}>
            <div className="logo">
                <a href={process.env.REACT_APP_MARKETING_WEBSITE}>
                    <RecfindrLogo />
                </a>
            </div>
            
            <nav>
                <ul>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/login">Login</NavLink>
                    </li>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/register">Register</NavLink>
                    </li>
                    
                    {/* Removed Logout NavLink since we're moving it to dropdown */}
                </ul>
            </nav>
        </header>
    )
}

export default DefaultNav;