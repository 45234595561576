import { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import SuccessIcon from '../icons/SuccessIcon';
import BlankIcon from '../icons/BlankIcon';

function ProfessionalQuestionsAccordion({ defaultValue = {}, onProfessionalAnswersChange }) {
	const [answers, setAnswers] = useState(() => {
		return { ...defaultValue };
	});

	const [answeredQuestions, setAnsweredQuestions] = useState(() => {
		return Object.entries(defaultValue || {}).reduce((acc, [key, value]) => {
			if (value.length >= 10) acc.push(parseInt(key));
			return acc;
		}, []);
	});

	const handleQuestionAnswered = (questionNumber, value) => {
		setAnswers((prevAnswers) => {
			const newAnswers = {
				...prevAnswers,
				[questionNumber]: value,
			};
			// Call the passed in onProfessionalAnswersChange prop function with newAnswers
			onProfessionalAnswersChange(newAnswers);
			return newAnswers;
		});

		if (value.length >= 1 && !answeredQuestions.includes(questionNumber)) {
			setAnsweredQuestions((prev) => {
				const newAnsweredQuestions = [...prev, questionNumber];
				// Optionally, if you want to pass the answeredQuestions to parent as well, you can do it here.
				// onAnsweredQuestionsChange(newAnsweredQuestions);
				return newAnsweredQuestions;
			});
		} else if (value.length < 1 && answeredQuestions.includes(questionNumber)) {
			setAnsweredQuestions((prev) => {
				const newAnsweredQuestions = prev.filter((q) => q !== questionNumber);
				// Optionally, if you want to pass the answeredQuestions to parent as well, you can do it here.
				// onAnsweredQuestionsChange(newAnsweredQuestions);
				return newAnsweredQuestions;
			});
		}
	};

	const isQuestionDisabled = (questionNumber) => {
		return answeredQuestions.length >= 3 && !answeredQuestions.includes(questionNumber);
	};

	const isQuestionAnswered = (questionNumber) => {
		return answeredQuestions.includes(questionNumber);
	};

	const ProfessionalQuestions = {
		1: 'What are some notable placements you’ve made recently?',
		2: 'What’s your sweet spot in recruitment and why?',
		3: 'How do you go about understanding a company’s culture and specific needs for a role?',
		4: 'Can you give an example of how you’ve tailored your recruitment strategy to fit a client’s unique requirements?',
		5: 'What’s your typical process for sourcing and vetting candidates?',
		6: 'How do you ensure the quality of the candidates you present?',
		7: 'How frequently do you update your clients during the recruitment process?',
		8: 'What’s your preferred method of communication, and how flexible are you with adapting to communication styles?',
		9: 'What networks or platforms do you primarily use for sourcing candidates?',
		10: 'How do you leverage your network and resources to find hard-to-reach or passive candidates?',
		11: 'Talk through your process for advertising jobs?',
	};

	const ProfessionalQuestionsIntroText = {
		1: 'Sharing a recent successful placement highlights your current market relevant and success, showcasing your capability and recent achievements to a prospective client.',
		2: 'This gives you the chance to showcase your area of specialisation or preference.',
		3: 'This can help establish your thoroughness and commitment to tailored solutions, building trust with potential clients.',
		4: 'Providing specific examples of customised strategies can illustrate flexibility and adaptability, appealing to clients with unique or challenging hiring needs.',
		5: 'Explaining your sourcing and vetting process can highlight your thoroughness and quality control, assuring clients of your commitment to finding the best candidates.',
		6: 'This showcases your dedication to quality over quantity, emphasising your focus on presenting only top-tier candidates',
		7: 'Discussing communication frequency and style can help set clear expectations and demonstrate your commitment to transparency and regular updates',
		8: 'This shows your willingness to adapt to different clients preferences, highlighting their client-centric approach and flexibility',
		9: 'Discussing the networks and platforms you use can demonstrate your reach and resourcefulness, particularly in accessing diverse candidate pools.',
		10: 'Your ability to tap into a wider talent pool, including passive candidates, which is often crucial for finding the best talent.',
		11: 'Describing your job advertising process can showcase your marketing skills and ability to effectively attract candidates, increasing the appeal to potential clients.',
		// ... Add intro texts for all questions
	};

	return (
		<>
			<h4>Answer 3 'work you' questions</h4>
			<Accordion>
				{Object.entries(ProfessionalQuestions).map(([key, question], i) => (
					<Accordion.Item key={key} eventKey={key}>
						<Accordion.Header disabled={isQuestionDisabled(parseInt(key))}>
							{question}
							{isQuestionAnswered(parseInt(key)) ? <SuccessIcon /> : <BlankIcon />}
						</Accordion.Header>
						<Accordion.Body>
							<hr></hr>
							<p className='intro-text'>{ProfessionalQuestionsIntroText[key]}</p>
							<textarea
								rows='2'
								maxLength='300'
								type='text'
								id={key}
								name={key}
								value={answers[key] || ''} // Ensure that the value attribute is set based on the answers state
								className={`formgroup p-4 required ${isQuestionAnswered(parseInt(key)) ? 'answered' : ''}`}
								placeholder='Start typing...'
								onChange={(e) => handleQuestionAnswered(parseInt(key), e.target.value)}
								required
							/>
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
			<div className='accordionQuestionsFeedback'>{answeredQuestions.length} of 3 questions complete</div>
		</>
	);
}
export default ProfessionalQuestionsAccordion;
