import React from 'react';

const PhoneIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 384 512"
  >
    <path
      fill="var(--color-black)"
      d="M64 48V464H320V48H64zM16 0H64 320h48V48 464v48H320 64 16V464 48 0zM160 400h64 16v32H224 160 144V400h16z"
    />
  </svg>

)
export default PhoneIcon