import { NavLink } from "react-router-dom";
import PremiumIcon from '../icons/PremiumIcon.js';

const ButtonUpgrade = () => {
    return (
        <NavLink className={'btn btn-secondary btn-with-icon btn-upgrade'} to="/pricing">
            <PremiumIcon /> Upgrade now
        </NavLink>
    );
}

export default ButtonUpgrade;
