import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";


const Logout = () => {

    const navigate = useNavigate();
    const { setAuth } = useAuth();

    const axiosPrivate = useAxiosPrivate();
    const [setIsLoading ] = useState(true);

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();
        const Logout =  async () => {
            try {
                const response = await axiosPrivate.get('/logout', {
                    single: controller.signal
                });
                console.log(response.data)
                setAuth(true)
                //navigate('/', { state: { from: location }, replace: true });
            } catch(err) {
                console.log(err);
                navigate('/login');
            }
        }
        Logout();
        return () => {
            //isMounted = false;
            controller.abort();
        }
    },)



    return (
        <>
        <div className="app-main-wrapper">
            <div className="rf-bg-four d-sm-none d-md-block"></div>
            <section className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="form-panel d-flex flex-column align-items-center justify-content-center">
                            <h1 className="h2">Logged out</h1>

                            <div>
                                <a href="/login" className="btn btn-secondary btn-lg">Sign in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </>
    )
}

export default Logout
