//import { Link } from "react-router-dom"
import { useState, useEffect } from "react";
import PremiumIcon from "../icons/PremiumIcon.js";
import UnderlineScribble from "../icons/UnderlineScribble.js";
import ErrorIcon from "../icons/ErrorIcon.js";
import SuccessIcon from "../icons/SuccessIcon.js";

/*
const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.id)
    console.log(e.target.value)
}
*/

const PricingBundle3 = ({email, userId, role, stripeID}) => {

	const [actionUrl, setActionUrl] = useState(process.env.REACT_APP_RECFINDR_API + "/api/subscribe");
	const [method, setMethod] = useState("POST");

    useEffect(() => {
        // Check if any of the variables do not exist
        if (!userId || !email || !role) {
            // Set a different action URL
			setMethod("GET");
            setActionUrl(process.env.REACT_APP_PUBLIC_URL+"/register");
        }
    }, [stripeID, userId, email, role]);

	return (
		<div className="pricing-panel pricingBundle3" id="Bundle3">
			<div className="pricing-top">
				<span className="pricePlanName">
					<PremiumIcon /> 15 users
				</span>
				<div className="priceValue">
					<div className="priceMain">
						<div className="priceCurrency">&#163;</div>
						<h1 className="h2">449</h1>
					</div>
				</div>
				<span className="tag tag-light-grey">per month</span>
                <p className="priceNote">
					Memberships are charged annually - vat. not included.
				</p>
			</div>
			<div className="pricing-features">
				<span>What's included</span>
				<UnderlineScribble />

				<ul>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Match with employers
						</span>
					</li>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Add employer reviews
						</span>
					</li>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Profile views insight
						</span>
					</li>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Profile clicks insight
						</span>
					</li>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Add direct contact details
						</span>
					</li>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Feature First in search
						</span>
					</li>
					<li>
						<span className="featureListItem">
							<SuccessIcon /> Premium profile
						</span>
					</li>
				</ul>
			</div>
			<div className="pricing-cta">
			<form
					action={actionUrl}
					method={method}
				>
					<input
						name="plan"
						id="plan"
						readOnly
						hidden
						value="15 users"
					></input>
					{
					stripeID &&
					<input
						name="stripeID"
						id="stripeID"
						readOnly
						hidden
						defaultValue={stripeID}
					></input>
					}
					{
					userId &&
					<input
						name="userId"
						id="userId"
						readOnly
						hidden
						defaultValue={userId}
					></input>
					}
					{
					email &&
					<input
						name="email"
						id="email"
						readOnly
						hidden
						defaultValue={email}
					></input>
					}
					{
					role &&
					<input
						name="role"
						id="role"
						readOnly
						hidden
						defaultValue={role}
					></input>
					}
					<input
						className="userQuantity"
						name="quantity"
						type="number"
						id="quantity"
						aria-describedby=""
						min="1"
						defaultValue="1"
						readOnly
						hidden
					/>
					<input
						name="item"
						id="item"
						readOnly
						hidden
						value={process.env.REACT_APP_PRICE_15_USERS}
					></input>
					<button
						id="priceSelect"
						className="btn btn-secondary btn-lg"
						type="submit"
					>
						Select
					</button>
				</form>
			</div>
			{/****** FOR TESTING HIDDEN FOR NOW ******/}



		</div>
	);
};

export default PricingBundle3;