import React from 'react';

const ErrorIcon = (props) => (

<svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  height="1em"
  viewBox="0 0 512 512"
  className="ErrorIcon"

>
  <path
    fill="var(--color-tertiary)"
    d="M256 512a256 256 0 1 0 0-512 256 256 0 1 0 0 512zm97.9-320-17 17-47 47 47 47 17 17-33.9 33.9-17-17-47-47-47 47-17 17-33.9-33.9 17-17 47-47-47-47-17-17 33.9-33.9 17 17 47 47 47-47 17-17 33.9 33.9z"
  />
</svg>

)
export default ErrorIcon