import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Select from 'react-select';

// Custom date formatting function
const customDateFormatter = (date) => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(date).toLocaleDateString('en-GB', options);
};

const RecruiterCTAPhoneChart = ({ ctaClicks }) => {
  // State to manage the selected date range
  const [selectedDateRange, setSelectedDateRange] = useState(7); // Default to 7 days
  const [totalInteractions, setTotalInteractions] = useState(0); // State to store total interactions

  // Date range options
  const dateRangeOptions = [
    { value: 7, label: '7 Days' },
    { value: 30, label: '30 Days' },
    { value: 90, label: '90 Days' },
  ];

  // Calculate total interactions for the selected date range
  useEffect(() => {
    if (ctaClicks && ctaClicks.length > 0 && ctaClicks[0]?.interactions) {
      const interactions = ctaClicks[0].interactions;
      const endDate = new Date(); // Current date and time
      const startDate = new Date(); // Current date and time

      startDate.setHours(0, 0, 0, 0); // Set start date to beginning of the day
      endDate.setHours(23, 59, 59, 999); // Set end date to end of the day
    
    
      // Set the start date to the beginning of the selected date range (e.g., 7 days ago)
      startDate.setDate(startDate.getDate() - (selectedDateRange - 1));
        
      const totalInteractionsForRange = interactions.filter(
        (interaction) =>
          interaction.interactionType === 'phone' &&
          new Date(interaction.date) >= startDate &&
          new Date(interaction.date) <= endDate
      ).length;
    
      setTotalInteractions(totalInteractionsForRange);
      //console.log(totalInteractionsForRange);
    }
  }, [ctaClicks, selectedDateRange]);

  // Check if ctaClicks is empty or doesn't exist
  if (!ctaClicks || ctaClicks.length === 0 || !ctaClicks[0]?.interactions) {
    return <div className="no-content-message-panel no-interactions-panel">
    <h4>No phone interactions</h4>
  </div>;
  }

  const interactions = ctaClicks[0].interactions; // Extract the interactions array

  // Filter interactions to include only phone interactions
  const phoneInteractions = interactions.filter(
    (interaction) => interaction.interactionType === 'phone'
  );

  // Calculate the date range based on the selectedDateRange
  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999); // Use end of yesterday as the end date
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - (selectedDateRange - 1));
  startDate.setHours(0, 0, 0, 0); // Set start date to beginning of the day


  // Create an object to store the counts of interactions by date
  const chartData = {};

  // Populate chartData with dates within the date range
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    chartData[customDateFormatter(currentDate)] = 0; // Format date label
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Update chartData with actual interaction counts
  phoneInteractions.forEach((interaction) => {
    const interactionDate = customDateFormatter(new Date(interaction.date));
    chartData[interactionDate] += 1;
  });

  //console.log(phoneInteractions)

  // Calculate the maximum count of phone interactions
  const maxCount = Math.max(...Object.values(chartData));

  // Determine the number of Y-axis ticks based on the maximum count with a minimum of 5
  const numTicks = Math.max(maxCount >= 5 ? maxCount + 1 : 5, 5);

  // Define a custom tooltip content component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload; // Assuming payload is an array

      return (
        <div className="custom-chart-tooltip">
          <p className="custom-chart-tooltip-date">Date: {data.date}</p>
          <p className="custom-chart-tooltip-count">Clicks: {data.count}</p>
        </div>
      );
    }
    
    return null;
  };

  return (
    <div className="chart-wrapper">
      <div className="chart-header">
        <div className="chart-title">
          <h4>
            Phone clicks <span className="interactions-count">{totalInteractions}</span>
          </h4>
        </div>
        <div className="chart-daterange-wrapper">
         <span>Range:</span>
          <Select // Use react-select for date range selection
            options={dateRangeOptions.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            value={{ value: selectedDateRange, label: `${selectedDateRange} Days` }}
            onChange={(selectedOption) => setSelectedDateRange(selectedOption.value)}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            classNamePrefix="rfSelectSingleSortingDropdown"            
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={320}>
        <BarChart data={Object.entries(chartData).map(([date, count]) => ({ date, count }))}>
          <CartesianGrid horizontal={true} vertical={false} stroke="#ebebeb" />
          <XAxis dataKey="date" tickFormatter={customDateFormatter} />
          <YAxis ticks={Array.from({ length: numTicks }, (_, i) => i + 1)} domain={[0, numTicks]} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="count" name="phone" fill="#00b1b0" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RecruiterCTAPhoneChart;