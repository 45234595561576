import { useState, memo } from 'react';
import { Link } from 'react-router-dom';

import ViewProfile from './viewProfile';
import useAuth from '../hooks/useAuth';
import { axiosPrivate } from '../api/axios';

import ClientHeader from './client-components/ClientHeader';
import RecruiterHeader from './recruiters-components/RecruiterHeader';




const ViewProfileWrapper = ({ uuid }) => {
	const [loading] = useState(false);
	const [setFollowing] = useState(false);
	const [backgroundImage, setBackgroundImage] = useState();

	const { auth } = useAuth();

	var url
	if (uuid) {
		var url = uuid;
	} else {
		var url = window.location.href.split('/')[4];
	}

	/*
    const [like, setLike] = useState('unmatched')

    const toggleProfileLike = ({id, like}) => {
        const query = {
            id,
            like
        }
        if (like === 1) {
            setLike('matched')
            setFollowing(true)
            handleLike(query)
            //toggleLike(id, true)
        } else if (like === 0) {
            setLike('unmatched')
            setFollowing(false)
            handleDislike(query)
            //toggleLike(id, false)
        }
    }
*/
	const toggleLike = (id, like) => {
		//setFollowing(like);
		/*
        const updatedList = listRecruiters.map((recruiter) => {
        if (recruiter._id === id) {
            return {
            ...recruiter,
            following: like,
            };
        }
        return recruiter;
        });
        */
		//console.log('toggle in tile');
		//console.log(updatedList)
		//setRefresh(!refresh)
		//setListRecruiters(updatedList);
	};

	const handleDislike = async ({ id }) => {
		//console.log('here...' + id);
		var query = {
			id: id,
			like: 0,
		};
		//console.log(query);
		try {
			const res = await axiosPrivate.post('/api/like', query);

			if (res.data.length === 0) {
				console.log('Array is empty!');
			}
			console.log(res.data);
			setFollowing(false);
			//setRecruiters(res.data)
		} catch (err) {
			console.log(err);
		}
	};

	const handleLike = async ({ id }) => {
		//console.log('here...' + id);
		var query = {
			id: id,
			like: 1,
		};
		console.log(query);
		try {
			const res = await axiosPrivate.post('/api/like', query);

			if (res.data.length === 0) {
				console.log('Array is empty!');
			}
			//console.log(res.data);
			setFollowing(true);
			//setRecruiters(res.data)
		} catch (err) {
			console.log(err);
		}
	};

	const handleBackgroundImage = (image) => {
		setBackgroundImage(image);
	};

	/*
	const getHeaderComponent = (role) => {
		switch (role) {
			case 3002:
				return <ClientHeader />;
			case 3001:
				return <RecruiterHeader />;
			default:
				return null;
		}
	};
	*/
	return (
		<>
			{loading ? (
				<>
					<div className='lds-fullscreen lds-facebook'>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</>
			) : (
				<>
					{auth?.cuid ? (
						<>
							{/*{getHeaderComponent(auth.role)}*/}

							<div className='app-main-wrapper-condensed'>
								<section className='container'>
									<div className='row'>
										<div className='col-md-12'>
											<div className='profilePageWrapper'>
												<div className='profileBGimageWrapper'>
													{backgroundImage === null || typeof backgroundImage === 'undefined' ? null : (
														<img
															className='profilePicture'
															alt=''
															src={`data:image/jpeg;base64,${backgroundImage}`}></img>
													)}
												</div>
												<div className='rf-bg-six d-xs-none d-sm-none d-md-block'></div>

												<ViewProfile
													uuid={uuid}
													handleLike={handleLike}
													handleDislike={handleDislike}
													toggleLike={toggleLike}
													sendBackgroundImage={handleBackgroundImage}
												/>
											</div>
										</div>
									</div>
								</section>
							</div>
						</>
					) : (
						<>
							<div className='app-main-wrapper-condensed'>
								<img
									className='profile-loggedout-placeholder-img'
									src='/profile-loggedout-placeholder.webp'
									alt=''
								/>

								<section className='container'>
									<div className='row'>
										<div className='col-md-12'></div>

										<div className='col-md-6 offset-md-3'>
											<div className='form-success-panel'>
												<h1 className='h3'>Ooops... </h1>
												<p>You must be logged in as a Hiring Manager to view a profiles.</p>
												<Link className='btn btn-secondary' to='/login'>
													Login
												</Link>
											</div>
										</div>
									</div>
								</section>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default memo(ViewProfileWrapper);
