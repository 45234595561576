import { useRef, useState, useEffect, } from "react";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';
import { Link, useNavigate } from "react-router-dom";

//const USER_REGEX = /^(?:[A-Z\d][A-Z\d_-]{5,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
// eslint-disable-next-line no-control-regex
const USER_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
const RECOVER_URL = '/recover/agency';

const PasswordRecover = () => {
    const userRef = useRef();
    const errRef = useRef();
    //const roleRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);


    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])


    useEffect(() => {
        setErrMsg('');
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);

        if (!v1) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            console.log(JSON.stringify({ user }))
            const response = await axios.post(RECOVER_URL,
                JSON.stringify({ user }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
            setUser('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Not Found');
            } else {
                setErrMsg('Error, Please Contact Support')
            }
            errRef.current.focus();
        }
    }

    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/login/agency');
    }


    return (
        <>
        {success ? (
                <div className='app-main-wrapper'>
                    <section className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="form-success-panel">
                                <h1 className="h3">Check your email</h1>
                                <p>Check your inbox to activate your account</p>
                                <p>
                                    <button className="btn btn-secondary btn-lg"  onClick={navigateToLogin}>Back to Sign In</button>
                                </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="rf-bg-one d-sm-none d-md-block"></div>
                </div>
            ) : (
                <div className="app-main-wrapper">
                    <section className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div id="recover" className="form-panel">
                                    <h1 className="h3 text-center">Password Recovery</h1>
                                    <p className="text-center">If you’re having trouble logging in, you can reset your password by submitting your email address below. If that matches an account on our system, we’ll send you instructions to reset your password.</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-live-val-wrapper">
                                            <input
                                                type="text"
                                                id="username"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => setUser(e.target.value)}
                                                value={user}
                                                placeholder="Email address"
                                                className="mt-4 flex-grow"
                                                required
                                                aria-invalid={validName ? "false" : "true"}
                                                aria-describedby="uidnote"
                                                onFocus={() => setUserFocus(true)}
                                                onBlur={() => setUserFocus(false)}
                                            />
                                                <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faExclamation} className={validName || !user ? "hide" : "invalid"} />
                                        </div>
                                        <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                                            <span>
                                            Must be a vaild email address
                                            </span>
                                        </p>
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive"><span>{errMsg}</span></p>

                                        <button id="recoveryButton" className="btn btn-lg btn-secondary align-self-center" disabled={!validName ? true : false}>Recover Password</button>
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <p>Already registered? <Link to="/login" className='hfont'>Login</Link></p>
                        </div>
                    </section>
                    <div className="rf-bg-four d-sm-none d-md-block"></div>
                </div>
        )}
        </>
    )
}

export default PasswordRecover
