import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import industryMaster from './industryMaster.json';

const IndustrySelect = ({
	initialIndustry,
	initialSectors,
	initialSubSectors,
	errorIndustry,
	errorSector,
	errorSubSector,
	onProfileChange,
	title1,
	title2,
	title3,
	title1_tip,
	title2_tip,
	title3_tip,
	role,
	paid
}) => {
	const [selectedIndustry, setSelectedIndustry] = useState(initialIndustry);
	const [selectedSectors, setSelectedSectors] = useState(initialSectors);
	const [selectedSubSectors, setSelectedSubSectors] = useState(initialSubSectors);

	const jsonData = industryMaster;
	//console.log(jsonData)

	console.log(role, paid)

	useEffect(() => {
		const onLoadHandleIndustryChange = (selectedIndustry) => {
			//console.log('has..')
			//console.log(initialIndustry)

			if (!selectedIndustry) {
				setSelectedIndustry({ value: 'Financial Services', label: 'Financial Services' });
				//console.log('not found')
			} else {
				//console.log('found')

				const postIndustry = selectedIndustry;
				onProfileChange({
					industry_1: [postIndustry.value],
					sector: [],
					sub_sector: [],
				});
			}
		};
		onLoadHandleIndustryChange();
	}, []); // Empty dependency array ensures the effect runs only once on mount

	const handleIndustryChange = (selectedOption) => {
		setSelectedIndustry(selectedOption);
		setSelectedSectors([]);
		setSelectedSubSectors([]);

		const postIndustry = selectedOption;
		onProfileChange({
			industry_1: [postIndustry.value],
			sector: [],
			sub_sector: [],
		});
	};

	const handleSectorChange = (selectedOptions) => {
		setSelectedSectors(selectedOptions);
		setSelectedSubSectors([]);

		const postSectors = selectedOptions.map((item) => item.value);
		onProfileChange({
			industry_1: [selectedIndustry.value],
			sector: postSectors,
			sub_sector: [],
		});
	};

/*	
const handleSectorChange = (selectedOptions) => {
  
	let limit = Number.MAX_SAFE_INTEGER; // Default to no limit
  
	if (role && role.includes(3001)) { // Check if role exists and includes 3001
	  limit = paid ? 3 : 1; // Apply limit based on payment status
	}
  
	// Apply the limit
	const limitedOptions = selectedOptions?.slice(0, limit) || [];
  
	// Update state even if values are the same
	setSelectedSectors([...limitedOptions]);
  
	const postSectors = limitedOptions.map((item) => item.value);
	onProfileChange({
	  industry_1: [selectedIndustry.value],
	  sector: postSectors,
	  sub_sector: [],
	});
  };
  */

/*
	const handleSubSectorChange = (selectedOptions) => {
		setSelectedSubSectors(selectedOptions);

		const postSubSectors = selectedOptions.map((item) => item.value);
		onProfileChange({
			industry_1: [selectedIndustry.value],
			sector: selectedSectors.map((item) => item.value),
			sub_sector: postSubSectors,
		});
	};
*/

const handleSubSectorChange = (selectedOptions) => {
	let limit = Number.MAX_SAFE_INTEGER; // Default to no limit
  
	if (role && role.includes(3001)) { // Check if role exists and includes 3001
	  limit = paid ? 3 : 1; // Apply limit based on payment status
	}
  
	// Apply the limit to subsectors
	const limitedSubSectors = selectedOptions?.slice(0, limit) || [];
  
	// Update state with limited subsectors
	setSelectedSubSectors(limitedSubSectors);
  
	// Map subsectors with values for payload
	const postSubSectors = limitedSubSectors.map((item) => item.value);
  
	// Update profile data with limited subsectors
	onProfileChange({
	  industry_1: [selectedIndustry.value],
	  sector: selectedSectors.map((item) => item.value), // Keep original sectors
	  sub_sector: postSubSectors,
	});
  };
  
	const industryOptions = jsonData.industries.map((industry) => ({
		value: industry.name,
		label: industry.name,
	}));

	let sectorOptions = [];
	let subSectorOptions = [];

	if (selectedIndustry) {
		const selectedIndustryData = jsonData.industries.find(
			(industry) => industry.name === selectedIndustry.value
		);
		if (selectedIndustryData && selectedIndustryData.sectors) {
			const sectorSet = new Set();
			selectedIndustryData.sectors.forEach((sector) => {
				sectorSet.add(sector.name);
			});

			sectorOptions = Array.from(sectorSet).map((sectorName) => ({
				value: sectorName,
				label: sectorName,
			}));

			if (selectedSectors) {
				const selectedSectorNames = selectedSectors.map((sector) => sector.value);
				const selectedSectorsData = selectedIndustryData.sectors.filter((sector) =>
					selectedSectorNames.includes(sector.name)
				);

				const subSectorsSet = new Set();
				selectedSectorsData.forEach((sector) => {
					sector.subSectors.forEach((subSector) => {
						subSectorsSet.add(subSector);
					});
				});

				subSectorOptions = Array.from(subSectorsSet).map((subSector) => ({
					value: subSector,
					label: subSector,
				}));
			}
		}
	}

	// adding classes to select items

	const customStyles = {
		container: (provided, state) => ({
			...provided,
		}),
		menu: (provided, state) => ({
			...provided,
		}),
		option: (provided, state) => ({
			...provided,
		}),
		singleValue: (provided, state) => ({
			...provided,
		}),
	};

	

	//console.log(industryOptions)

	// Render the Autosuggest component
	return (
		<div id='sectorSelector'>
			<div className='selector-group select-option-single'>
				{title1 ? <label id='industry_1_label'>{title1}</label> : <label>Industry</label>}
				<div className='formgroup-withicon'>
					<Select
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
						options={industryOptions}
						value={selectedIndustry}
						onChange={handleIndustryChange}
						placeholder='Industry'
						id='industry_1'
						classNamePrefix='rfSelectSingle'
						styles={customStyles}
					/>

					<div className='tooltip-container'>
						<img
							src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
							className='tooltip-icon'
						/>
						<span className='tooltiptext'>{title1_tip ? <>{title1_tip}</> : <></>}</span>
					</div>
				</div>
			</div>

			{errorIndustry && <div className='errmsg'>{errorIndustry}</div>}

			<div className='selector-group select-option-multi'>
				{selectedIndustry && (
					<>
						{title2 ? <label id='sector_label'>{title2}</label> : <label>Sectors</label>}
						<div className='formgroup-withicon'>
							<Select
								menuPortalTarget={document.body}
								menuPosition={'fixed'}
								options={sectorOptions}
								isMulti={true}
								value={selectedSectors}
								onChange={handleSectorChange}
								placeholder='Sectors'
								// maxMenuHeight={100}
								id='sector'
								classNamePrefix='rfSelectMulti'
							/>
							<div className='tooltip-container'>
								<img
									src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
									className='tooltip-icon'
								/>
								<span className='tooltiptext'>{title2_tip ? <>{title2_tip}</> : <></>}</span>
							</div>
						</div>
					</>
				)}
			</div>

			{errorSector && <div className='errmsg'>{errorSector}</div>}

			<div className='selector-group select-option-multi'>
				{/*selectedSectors.length > 0 && (<>*/}
				{title3 ? <label id='sub_sector_label'>{title3}</label> : <label>Subsectors</label>}
				<div className='formgroup-withicon'>
					<Select
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
						options={subSectorOptions}
						isMulti
						value={selectedSubSectors}
						onChange={handleSubSectorChange}
						placeholder='Sub-sectors'
						//maxMenuHeight={80}
						id='sub_sector'
						classNamePrefix='rfSelectMulti'
					/>
					<div className='tooltip-container'>
						<img
							src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
							className='tooltip-icon'
						/>
						<span className='tooltiptext'>{title3_tip ? <>{title3_tip}</> : <></>}</span>
					</div>
				</div>

				{/*
                  </>
                })}*/}
			</div>

			{errorSubSector && <div className='errmsg'>{errorSubSector}</div>}
		</div>
	);
};

export default IndustrySelect;
