import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../../api/axios";
import RecruiterCTAAllChart from "./RecruiterCTAAllChart";
import RecruiterCTAWebsiteChart from "./RecruiterCTAWebsiteChart";
import RecruiterCTACalendlyChart from "./RecruiterCTACalendlyChart";
import RecruiterCTAEmailChart from "./RecruiterCTAEmailChart";
import RecruiterCTAPhoneChart from "./RecruiterCTAPhoneChart";
import RecruiterCTAVideoChart from "./RecruiterCTAVideoChart";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ButtonUpgrade from './ButtonUpgrade.js'; // Importing the upgrade button

const RecruiterHeaderCTAClicks = (props) => {
  const [ctaClicks, setCtaClicks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    if(props.paid === true) {
      const fetchData = async () => {
        try {
          if (props?.paid === true) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await axiosPrivate.post('/api/recruiters/getAllQuicklinks');
            //console.log("Response from HeaderCTAClicks /getAllQuicklinks:", response.data);
            setCtaClicks(response.data?.interactions);
          } else {
            setCtaClicks({'paid': false});
          }
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [props?.paid]);

  return (
    <section className="myCTAsWrapper">
      <div className="container">
        <div className="dashboardToolbar">
          <div className="">
            <h3 className="mb-0">CTA clicks</h3>
          </div>
        </div>
    
        <div className="dashboardPanel">
          <div className="row">
            <div className="col-md-4">
              <div className="dashboardExplainerPanel">
                <span className="info-icon">i</span>
                <h5>CTA's Intro / Upsell Info / Explainer text</h5>
                <p>Insights into your ctas text - increase engagement by keeping your profile up to date etc etc.</p>
              </div>
            </div>
            <div className="col-md-8">
              {isLoading ? (
                <div className="loading-placeholder">
                  <div className="lds-facebook"><div></div><div></div><div></div></div>
                </div>
              ) : props?.paid === false ? ( // Checking if it's not a paid user
                <div className="ohDang">
                  <img className="ohdang-image" src="/ohdang-ctas.webp" alt=""/>
                  <h3>Oh dang!</h3>
                  <p>This information is only available to premium users.</p>
                  <p>Upgrade now for engagement insights.</p>
                  <ButtonUpgrade />
                </div>
              ) : (
                <Tabs defaultActiveKey="allInteractions" id="cta-clicks-tabs">
                  <Tab eventKey="allInteractions" title="All">
                    <RecruiterCTAAllChart ctaClicks={ctaClicks} />
                  </Tab>
                  <Tab eventKey="websiteInteractions" title="Website">
                    <RecruiterCTAWebsiteChart ctaClicks={ctaClicks} />
                  </Tab>
                  <Tab eventKey="calendlyInteractions" title="Calendar">
                    <RecruiterCTACalendlyChart ctaClicks={ctaClicks} />
                  </Tab>
                  <Tab eventKey="emailInteractions" title="Email">
                    <RecruiterCTAEmailChart ctaClicks={ctaClicks} />
                  </Tab>
                  <Tab eventKey="phoneInteractions" title="Phone">
                    <RecruiterCTAPhoneChart ctaClicks={ctaClicks} />
                  </Tab>
                  <Tab eventKey="videoInteractions" title="Video">
                    <RecruiterCTAVideoChart ctaClicks={ctaClicks} />
                  </Tab>
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RecruiterHeaderCTAClicks;