import React, { useRef, useState } from 'react';
import useAxiosPrivateUploader from '../hooks/useAxiosPrivateUploader';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BackgroundAvatarOverlay from './icons/BackgroundAvatarOverlay';

const BackgroundFilesUploadComponent = (props) => {

    //console.log(props.picture)

    const axiosPrivateUploader = useAxiosPrivateUploader(); 
    const axiosPrivate = useAxiosPrivate(); 
    //const location = useLocation();

    const [selectedBackground, setSelectedBackground] = useState();
    const [isSelected, setIsSelected] = useState(false);

    const changeBackgroundHandler = async (event) => {
        setSelectedBackground(event.target.files[0]);
        setIsSelected(true);

        var uploadURL = '/api/upload/background'
        const formData = new FormData();
        formData.append('File', event.target.files[0]);
    
        if (event.target.files[0].size > 2000000) {
            alert("Please upload file less than 2MB. Thanks!!");
            $(this).val('');
        } else {
            try {
                const res = await axiosPrivateUploader.post(uploadURL, formData);
                console.log(res);
                window.location.reload(false);
            } catch (err) {
                console.log(err);
            }
        }
    };
    


    const handleRemoveBackground = () => {
        var uploadURL = '/api/upload/background/remove'
        const data = {}

        const removeImage =  async () => {

            try {
                const res = await axiosPrivate.post(uploadURL, data);
                /*const response = await axiosPrivate.get(profileURL, {
                    single: controller.signal
                });*/

                //setTest([response.data])
                window.location.reload(false);
                console.log(res)

            } catch(err) {
                console.log(err);
            }
        }
            removeImage()
    };
    

    const backgroundInputRef = useRef(null);

    /*const handleClick = () => {
        if (!isSelected) {
          backgroundInputRef.current.click();
        }
    };*/
    
    return (
        <div className="backgroundContainer">

                <div className="background-upload-wrapper">
                    <div className="background-input-wrapper">
                        <div className="background-label">
                        <label>Background Image</label>
                            <input type="file" name="backgroundFile" accept=".gif,.jpg,.jpeg,.png" onChange={changeBackgroundHandler} id="backgroundInput" ref={backgroundInputRef} style={{ display: 'none' }}/>
                        </div>
                        <div className="backgroundFileChosen">
                            <label htmlFor="backgroundInput" id="backgroundInputLabel" className="btn btn-secondary">Choose a file</label>
                            <div className="chosen">
                            {props?.picture === null || typeof props?.picture === 'undefined' ? (
                                <span className="d-block">.jpg, .jpeg, .png .gif</span>
                            ) : (
                                ''
                            )}
                            </div>
                        </div>
                    </div>
                    <div className="background-image-wrapper">
                        <div className="background-image-info">
                        {props?.picture === null || typeof props?.picture === 'undefined' ? (
                            '1440 x 400'
                        ) : (
                            <img className="background" alt="" src={`data:image/jpeg;base64,${props.picture}`}></img>
                        )}               
                        </div>
                        <div className="background-image-info">
                            {props?.picture === 'blank.png' ? (
                            ''
                        ) : (
                            <span className="d-block"><FontAwesomeIcon icon={faTimes} onClick={handleRemoveBackground} /></span>
                        )}
                        </div>
                        <BackgroundAvatarOverlay />
                    </div>
                </div>

        </div>
            
    )
}

export default BackgroundFilesUploadComponent