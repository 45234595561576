import PricingBundleFree from "./pricing-components/PricingBundleFree";
import PricingBundle1 from "./pricing-components/PricingBundle1.js";
//import PricingBundle1Promo45 from "./pricing-components/PricingBundle1Promo45.js";
import PricingBundle2 from "./pricing-components/PricingBundle2";
import PricingBundle3 from "./pricing-components/PricingBundle3";
import BigScribble from './icons/BigScribble.js';

import useAuth from "../hooks/useAuth.js";


const Upgrade = (props) => {

    const { auth } = useAuth();
    console.log("auth:", auth);
    console.log("auth.activeSubscription:", auth.activeSubscription);
    console.log("Type of auth.activeSubscription:", typeof auth.activeSubscription);

    return (
        <>

        <section className="app-main-wrapper">  
            <div className="rf-bg-six d-xs-none d-sm-none d-md-block"></div>

            <div className="pricing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <h1 className="h2 text-center">Which plan is right for you?</h1>
                        </div>
                    </div>

                    <div className="row">
                        <BigScribble/>
                        <div className="col-md-3">
                            <PricingBundleFree email={auth.user} userId={auth.cuid} role={auth.role} stripeID={auth.stripeID}/>
                        </div>
                        <div className="col-md-3">
                            <PricingBundle1 email={auth.user} userId={auth.cuid} role={auth.role} stripeID={auth.stripeID}/>
                            {/*
                                auth.activeSubscription === true ?
                                    <PricingBundle1Promo45 email={auth.user} userId={auth.cuid} role={auth.role} stripeID={auth.stripeID}/>
                                    :
                                    <PricingBundle1 email={auth.user} userId={auth.cuid} role={auth.role} stripeID={auth.stripeID}/>
                            */}
                        </div>
                        <div className="col-md-3">
                            <PricingBundle2 email={auth.user} userId={auth.cuid} role={auth.role} stripeID={auth.stripeID}/>
                        </div>
                        <div className="col-md-3">
                            <PricingBundle3 email={auth.user} userId={auth.cuid} role={auth.role} stripeID={auth.stripeID}/>
                        </div>
                    </div>

                    <div className="col-md-12 text-center mt-5">
                        <p>
                            Need more seats? <b><a href={process.env.REACT_APP_MARKETING_WEBSITE+'/contact'}>Contact Support</a></b>
                        </p>
                    </div>

                </div>
            </div>
            
        </section>
        
        </>
    )
}

export default Upgrade