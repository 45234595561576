import React from 'react';

const LinkIcon = (props) => (

<svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 512 512"
  >
    <path
      fill="var(--color-black)"
      d="M320 0h-32v64h114.7L201.4 265.4 178.7 288l45.3 45.3 22.6-22.6L448 109.3V224h64V0H320zM32 32H0v480h488V320h-64v128H64V96h128V32H32z"
    />
  </svg>

)
export default LinkIcon