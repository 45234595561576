import { useState, useEffect, useRef, isLoading, setLoading, Component } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import RecruiterHeader from "./RecruiterHeader";
import $ from "jquery";



const RecruiterAccount = (props) => {
    

    const [isLoading, setLoading] = useState(true);
    const [input, setInput] = useState("");
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate(); 
    const [showPlans, setShowPlans] = useState(false);


    const initialValues = {
        username: "",
        role: "",
        first_name: "",
        last_name: "",
        profile_picture: "",
        job_title: "",
        headline_stat_1: "",
        headline_stat_2: "",
        headline_stat_3: "",
        linkedin_url: "",
        website_url: "",
        phone_number: "",
        company: "",
        location_1: {
            name: "",
            coordinates: ""
        },
        location_2: {
            name: "",
            coordinates: ""
        },
        industry_1: "",
        industry_2: "",
        biography: "",
        past_assignment_job_title: "",
        past_assignment_location: "",
        past_assignment_salary: "",
        active_roles_job_title: "",
        active_roles_salary: "",
        video: ""
    };

    //const [ test, setTest ] = useState(initialValues);
    const [ profile, setProfile ] = useState();

    // START FORM
    function handleChange(e) {
        console.log(e.target.value)
        if(e.target.classList.contains('required')){
            if(!e.target.value == ''){
                e.target.classList.remove('invalid')
            } else {
                e.target.classList.add('invalid')
            }
        }
        const { name, value } = e.target;
        setProfile({
        ...profile,
        [name]: value,
        });
    }

    
    function handleSubmit(){
        //e.preventDefault();
        //setLoading(true);
        console.log('submit hit')

        let updateURL
        switch(profile.role) {
            case 5150:{
                console.log('Admin')
                updateURL = '/api/recruiters/'
                alert('logged in as admin')
              break;
            }
            case 2001:{
                console.log('Testing')
                updateURL = '/api/recruiters/'
                alert('logged in as test')
              break;
            }
            case 3000:{
                console.log('Candidate')
                updateURL = '/api/candidate/'+uuid
              break;
            }
            case 3001:{
                console.log('Recruiter')
                updateURL = '/api/recruiters/'
              break;
            }
            case 3002:{
                console.log('Client')
                updateURL = '/api/client/'+uuid
            break;
            }
            default:
        }
        console.log(updateURL)
        const data = JSON.stringify(profile);
        console.log('data next')
        console.log(data)

        const upProfile =  async () => {
            try {
                const res = await axiosPrivate.put(updateURL, data);
                /*const response = await axiosPrivate.get(profileURL, {
                    single: controller.signal
                });*/

                //setTest([response.data])
                //setLoading(false);
            } catch(err) {
                console.log(err);
            }
        }
        upProfile()
      };


    const uuid = sessionStorage.getItem("uuid");

    const profileURL = '/api/profile/'



    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getProfile =  async () => {
            try {
                const response = await axiosPrivate.get(profileURL, {
                    single: controller.signal
                });
                console.log(response.data)
                var profiledata = JSON.parse(response.data);

                const setValues = {
                    username: profiledata.username,
                    role: profiledata.role,
                    first_name: profiledata.first_name,
                    last_name: profiledata.last_name,
                    profile_picture: profiledata.profile_picture,
                    job_title: profiledata.job_title,
                    headline_stat_1: profiledata.headline_stat_1,
                    headline_stat_2: profiledata.headline_stat_2,
                    headline_stat_3: profiledata.headline_stat_3,
                    linkedin_url: profiledata.linkedin_url,
                    website_url: profiledata.website_url,
                    phone_number: profiledata.phone_number,
                    company: profiledata.company,
                    location_1: {
                        name: profiledata.location_1.name,
                        coordinates: profiledata.location_1.coordinates
                    },
                    location_2: {
                        name: profiledata.location_2.name,
                        coordinates: profiledata.location_2.coordinates
                    },
                    industry_1: profiledata.industry_1,
                    industry_2: profiledata.industry_2,
                    biography: profiledata.biography,
                    past_assignment_job_title: profiledata.past_assignment_job_title,
                    past_assignment_location: profiledata.past_assignment_location,
                    past_assignment_salary: profiledata.past_assignment_salary,
                    active_roles_job_title: profiledata.active_roles_job_title,
                    active_roles_salary: profiledata.active_roles_salary,
                    video: profiledata.video,
                    profile_complete: profiledata.profile_complete,
                    stripe :{
                        activeSubscription: profiledata.stripe.activeSubscription
                    },
                    verifiedMember : profiledata.verifiedMember,
                    paidByCompany: profiledata.paidByCompany,
                    paidBySelf: profiledata.paidBySelf,
                    paid: profiledata.paid,
                };

                console.log(profile)



                isMounted && setProfile(setValues);
                //console.log(JSON.stringify(profile.stripe.activeSubscription))
                //setTest([response.data])
                setLoading(false);
                //console.log(profile.profiledata)

            } catch(err) {
                console.log(err);
            }
        }
        getProfile();
        return () => {
            isMounted = false;
            controller.abort();
        }
    },[])



    function handleNext(){
        console.log('ok')
        var link = document.getElementsByClassName('carousel-control-next');
        link.onclick.call(link);
    }

    const bsCarousel = useRef(null);

    function checkItem(){

        setTimeout(function() {
            
            if ($('.profileFields .about').hasClass('active') == true) {
                    let element = document.getElementById("profileBack");
                    element.classList.add("d-none");
            } else {
                    let element = document.getElementById("profileBack");
                    element.classList.remove("d-none");
            }

            if ($('.profileFields .history').hasClass('active') == true) {
                    let elementNext = document.getElementById("profileNext");
                    elementNext.classList.add("d-none");
                    let elementBack = document.getElementById("profileBack");
                    elementBack.classList.add("d-none");
                    let elementFinish = document.getElementById("profileFinish");
                    elementFinish.classList.remove("d-none");
            }

          }, 1000);
        
        

    }
    checkItem()

    const finishProfile =  async () => {
        try {
            const data = JSON.stringify(profile)
            const res = await axiosPrivate.put('/api/recruiters/', data);
            console.log(res)
        } catch(err) {
            console.log(err);
        } finally {
            navigate('/dashboard')
        }
    }
    

    const toggleClick = text=> event=> {

        checkItem()
        if(text == 'finish'){
            setProfile({
                ...profile,
                profile_complete: true,
                });
            handleSubmit();
            window.location.replace(process.env.REACT_APP_PUBLIC_URL)
        }

        console.log(text)
        if($('.profileFields .about').hasClass('active') == true){

            console.log('about active')

            var aboutValidator = 4
            if(!profile['first_name']){
                console.log('First name is required')
                let element = document.getElementById("first_name");
                element.classList.add("invalid");
                aboutValidator -= 1;
            }
            
            if(!profile['last_name']){
                console.log('Last name is required')
                let element = document.getElementById("last_name");
                element.classList.add("invalid");
                aboutValidator -= 1;
            }

            if(!profile['industry_1']){
                console.log('At least one industry is required')
                let element = document.getElementById("industry_1");
                element.classList.add("invalid");
                aboutValidator -= 1;
            }

            if(!profile['company']){
                console.log('Company name is required')
                let element = document.getElementById("company");
                element.classList.add("invalid");
                aboutValidator -= 1;
            }

            console.log(aboutValidator)

            if(aboutValidator === 4 ){
                handleSubmit();
                if(text == 'next') {
                    bsCarousel.current.next();
                    
                } else if(text == 'back'){
                    bsCarousel.current.prev();
                    
                }
            }



        } else if($('.profileFields .contactInfo').hasClass('active') == true){
            console.log('contact active')
            const USER_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
            




            
            var contactValidator = 2
            if(!profile['username']){
                console.log('Email is required')
                let element = document.getElementById("username");
                element.classList.add("invalid");
                contactValidator -= 1;
            } else if (USER_REGEX.test(profile['username'].toLowerCase()) == false) {
                console.log('Email is incorrect format')
                let element = document.getElementById("username");
                element.classList.add("invalid");
                contactValidator -= 1;
            }
                
            if(!profile['phone_number']){
                console.log('Phone number is required')
                let element = document.getElementById("phone_number");
                element.classList.add("invalid");
                contactValidator -= 1;
            }
            console.log(contactValidator)
            if(contactValidator === 2 ){
                handleSubmit();
                if(text == 'next') {
                    bsCarousel.current.next();
                } else if(text == 'back'){
                    bsCarousel.current.prev();
                }
            }

        } else if($('.profileFields .more').hasClass('active') == true){
            console.log('more active')
           
            var moreValidator = 1;

            if(!profile['job_title']){
                console.log('Job Title is required')
                let element = document.getElementById("job_title");
                element.classList.add("invalid");
                moreValidator -= 1;
            }
            console.log(moreValidator)
            if(moreValidator === 1 ){
                handleSubmit();
                if(text == 'next') {
                    bsCarousel.current.next();
                } else if(text == 'back'){
                    bsCarousel.current.prev();
                }
            }
        } else if($('.profileFields .location').hasClass('active') == true){
            console.log('location active')
            var locationValidator = 1;

            if(!profile['location_1']['name']){
                console.log('At least one location is required')
                let element = document.getElementById("location_1");
                element.classList.add("invalid");
                locationValidator -= 1;
            }
            console.log(locationValidator)
            if(locationValidator === 1 ){
                handleSubmit();
                if(text == 'next') {
                    bsCarousel.current.next();
                } else if(text == 'back'){
                    bsCarousel.current.prev();
                }
            }
        } else if($('.profileFields .history').hasClass('active') == true){
            console.log('history active')
            var historyValidator = 1;

            if(profile['profile_complete'] == false){
                console.log('At least one location is required')
                let element = document.getElementById("profile_complete_group");
                element.classList.add("invalid");
                historyValidator -= 1;
            }
            console.log(historyValidator)
            if(historyValidator === 1 ){
                if(text == 'finish') {
                    finishProfile();
                    console.log('trigger')
                } 
            }
        }

    };




    if (isLoading) {
        return <div className="App"><div className="lds-facebook"><div></div><div></div><div></div></div></div>;
    }

    const headerProps = {
        profile_picture: profile.profile_picture,
        header_color: 'theme-five',
    }

    function handleShowPlans(e) {
        e.preventDefault();
        setShowPlans(true);
    }



    return (
    <div>

        
        
        <RecruiterHeader props={headerProps} color="theme-five" />

        <h1>Preferences, Licence info</h1>

        </div>
  )


}
export default RecruiterAccount