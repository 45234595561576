import { Outlet } from "react-router-dom";
import SiteFooter from './utility/SiteFooter';
import RecruiterNav from './utility/RecruiterNav';
import ClientNav from './utility/ClientNav';
import CompanyNav from './utility/CompanyNav';
import DefaultNav from './utility/DefaultNav';
import FinishProfileNav from "./utility/FinishProfileNav";

import useAuth from "../hooks/useAuth";

const Layout = () => {
  const { auth } = useAuth();
  const role = auth?.role;
  const profileComplete = auth?.profile_complete;

  if (auth.role && !profileComplete) {
    return <main className="App">
            <FinishProfileNav />
              <Outlet />
            <SiteFooter />
            </main>;
  }

  if (Array.isArray(role)) {
    if (role.includes(3001)) {
        return (
            <main className="App">
                <RecruiterNav />
                <Outlet />
                <SiteFooter />
            </main>
        );
    } else if (role.includes(3002)) {
        return (
            <main className="App">
                <ClientNav />
                <Outlet />
                <SiteFooter />
            </main>
        );
    } else if (role.includes(4000)) {
        return (
            <main className="App">
                <CompanyNav />
                <Outlet />
                <SiteFooter />
            </main>
        );
    } else {
        return (
            <main className="App">
                <DefaultNav />
                <Outlet />
                <SiteFooter />
            </main>
        );
    }
} else {
    console.error("Role is not defined or not an array");
    return (
        <main className="App">
            <DefaultNav />
            <Outlet />
            <SiteFooter />
        </main>
    );
}



 
};

export default Layout;