import { useRef, useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import ActivateAccount from './ActivateAccount';

import axios from '../api/axios';

const LOGIN_URL = '/auth';

const LoginUser = () => {

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    //const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [activation, setActivation] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    useEffect(() => {
        document.body.style.backgroundColor = "#f7f7f7";
        // cleanup function
        return () => {
          document.body.style.backgroundColor = null; // or reset to initial color
          // reset other styles
        };
      }, []); // Empty array means this runs once on mount and cleanup on unmount
    

    const handleActivationEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/register/activationEmail',
                JSON.stringify({ user }),
                {
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    withCredentials: true
                }
            );
            //console.log(JSON.stringify(response?.data));
            if (response?.status === 201) {
                setInfoMsg('Activation email sent, check your inbox');
            }

        } catch (err) {
            if (!err?.response) {
                //setErrMsg('No Server Response');
            } 
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: {
                    'Content-Type': 'application/json',
                    //'Access-Control-Allow-Origin': 'http://localhost:3000',
                    //'Access-Control-Allow-Methods': 'OPTIONS, DELETE, POST, GET, PATCH, PUT',
                    //'Access-Control-Allow-Headers': '*'
                    },
                    withCredentials: true
                }
            );
            //console.log(JSON.stringify(response?.data));
            //console.log('here'+JSON.stringify(response));
            //console.log('here'+JSON.stringify(response.config.data));

            
            const accessToken = response?.data?.accessToken;
            const role = response?.data?.role;
            //const uuid = response?.data?.uuid;
            const profile_complete = response?.data?.profile_complete;
            const cuid = response?.data?.cuid;
            const stripeID = response?.data?.stripeID;
            setAuth({ user, pwd, role, profile_complete, accessToken, cuid, stripeID });
            setUser('');
            setPwd('');
            sessionStorage.setItem("role", role);
            
            //navigate(from,{replace: true, state:{role: role}});
            navigate("/", {replace: true, state:{role: role}});
            
            //console.log('Login.js... uuid is... '+uuid)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 406) {
                setErrMsg('Account not activated');
                setActivation(true);
            } else if (err.response?.status === 401) {
                console.log(err.response)
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <>


                <div id="login" className="form-panel">
                <h1 className="h2 text-center">Login</h1>
                
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        id="username"
                        className=""
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        placeholder="Email"
                        required
                    />
                    <input
                        type="password"
                        id="password"
                        className="mt-4"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        placeholder="Password"
                        required
                    />
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive"><span>{errMsg}</span></p>
                    <p className={infoMsg ? "infoMsg" : "offscreen"} aria-live="assertive"><span>{infoMsg}</span></p>

                    <button id="#loginButton" className="btn btn-secondary btn-lg align-self-center">Login</button>
                    
                    <ActivateAccount />

                    {activation === true
                        ?
                        <div className="d-flex flex-column">
                            <hr className=""></hr><p className="text-center">Not received your activation email?</p><button id="activationButton" className="btn btn-secondary align-self-center" onClick={handleActivationEmail}>Resend Activation Email</button>
                        </div>
                        :<></>
                    }
                </form>
                
                </div>

                <div className="col-md-12 text-center">
                    <p>Don't have an account? <Link to="/register" className='hfont'>Register here</Link></p>
                    <p>
                       <Link to="/recover" className='hfont'>Recover Password</Link>
                    </p>
                </div>


        </>
    )
}

export default LoginUser
