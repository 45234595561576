import { useState, useEffect, useRef } from "react";
import { axiosPrivate } from "../../api/axios";
import ArrowNextIcon from "../icons/ArrowNextIcon.js";
import ArrowPrevIcon from "../icons/ArrowPrevIcon.js";
import ButtonUpgrade from "./ButtonUpgrade.js";

// For swiper (imports)
import { Swiper, SwiperSlide, Navigation, Pagination } from "swiper/react";
import SwiperCore from "swiper";
// For swiper (import styles)
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const RecruiterHeaderProfileViews = (props) => {
	const [views, setViews] = useState([]);

	// For swiper (makes it double stacked)
	const viewPairs = Array.isArray(views) ? chunkArray([...views], 2) : [];

	function chunkArray(myArray, chunk_size) {
		var results = [];

		while (myArray.length) {
			results.push(myArray.splice(0, chunk_size));
		}

		return results;
	}

	

	// For swiper (swiper ref)
	const swiperRef = useRef(null);

    // For swiper (handle slide pagination)

	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slidePrev();
			updateNavigationButtons(swiperRef.current.swiper);
		}
	};

	const handleNextSlide = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
            updateNavigationButtons(swiperRef.current.swiper);
        }
	};

	// RT - SWIPER PAG DISABLED BUTTONS STUFF

	const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

	const updateNavigationButtons = (swiper) => {
		setIsPrevButtonDisabled(swiper.isBeginning);
		setIsNextButtonDisabled(swiper.isEnd);
	};

    const onSwiperInit = (swiper) => {
        updateNavigationButtons(swiper);
    };
    
    const onSlideChange = (swiper) => {
        updateNavigationButtons(swiper);
    };

	useEffect(() => {
		let isMounted = true; // Add this line

		const timer = setTimeout(() => {
			if (isMounted) {
				// Check the flag before setting state
				fetchProfileViewsData();
			}
		}, 1000); // Delay of 1 second

		async function fetchProfileViewsData() {
			try {
				if (props?.paid === true) {
					const response = await axiosPrivate.post(
						"/api/recruiters/getAllQuicklinks"
					);
					if (response.data && response.data.views) {
						if (isMounted) setViews(response.data.views); // Check the flag before setting state
					} else {
						if (isMounted) setViews([]); // if matches are not present in the response
					}
				} else {
					if (isMounted) setViews({ paid: false });
				}
			} catch (err) {
				console.log(err);
			}
		}

		// RT - DISABLED PAG STUFF - Set a timeout to check for the Swiper instance
		const checkSwiperInstance = setTimeout(() => {
			if (isMounted && swiperRef.current && swiperRef.current.swiper) {
				updateNavigationButtons(swiperRef.current.swiper);
			}
		}, 50); // A short timeout to allow for Swiper instance to be available

		return () => {
			isMounted = false;
			clearTimeout(timer);
			clearTimeout(checkSwiperInstance); // Swiper instance timer
		};
	}, [props?.paid]);

	return (
		<section className="profileViewsWrapper">
			<div className="container">
				<div className="dashboardToolbar">
					<div className="">
						<h3 className="mb-0">Profile views</h3>
					</div>
					<div className="">
						<button
							className={`swiper-toolbar-prev btn btn-white ${
								isPrevButtonDisabled ? "disabled" : ""
							}`}
							onClick={handlePrevSlide}
							disabled={isPrevButtonDisabled}
						>
							<ArrowPrevIcon />
						</button>
						<button
							className={`swiper-toolbar-next btn btn-white ${
								isNextButtonDisabled ? "disabled" : ""
							}`}
							onClick={handleNextSlide}
							disabled={isNextButtonDisabled}
						>
							<ArrowNextIcon />
						</button>
					</div>
				</div>

				<div className="row">
					<div className="col-md-12">
						{views?.paid === false ? (
							<div className="ohDang">
								<img
									className="ohdang-image"
									src="/ohdang-profileviews.webp"
									alt=""
								/>
								<h3>Oh dang!</h3>
								<p>This information is only available to premium users.</p>
								<p>Upgrade now to see who's checking you out.</p>
								<ButtonUpgrade />
							</div>
						) : views && views?.length > 0 ? (
							<Swiper
								ref={swiperRef}
                                onInit={onSwiperInit}
                                onSlideChange={onSlideChange}
								autoHeight={false}
								centeredSlides={false}
								slidesPerView="3"
								className="profileViewsSwiper"
								spaceBetween={24}
							>
								{viewPairs.map((pair, idx) => (
									<SwiperSlide key={idx}>
										{pair.map((view) => (
											<div className="viewsItem" key={view.viewerId}>
												<div className="viewsuserTile">
													<div className="viewsuserTile-userinfo">
														{view.profilePicture === null ||
														typeof view.profilePicture === "undefined" ? (
															<img
																className="tileAvatarBadge"
																alt=""
																src={
																	process.env.REACT_APP_RECFINDR_API +
																	"/profile/blank.png"
																}
															/>
														) : (
															<img
																className="tileAvatarBadge"
																alt=""
																src={`data:image/jpeg;base64,${view.profilePicture}`}
															/>
														)}
														<div className="viewsuserTile-bio">
															<span className="tileName">
																{view.first_name}
															</span>
															<span className="tileCompany">
																{view.company}
															</span>

															<div className="viewsuserTile-matchinfo">
																<span>Viewed your profile </span>
																<span className="tileDate tag tag-micro tag-light-grey">
																	{view.count} times
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</SwiperSlide>
								))}
							</Swiper>
						) : (
							<div className="no-content-message-panel">
								<h4>No views found</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default RecruiterHeaderProfileViews;