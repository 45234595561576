import { useState, useEffect, isLoading, setLoading } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ClientDirectRecruiterMessage from './ClientDirectRecruiterMessage';
import { set } from 'lodash';
import { faPaperPlane, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ClientSendNewMessage = ({ ruid, first_name }) => {
	const location = useLocation();

	const axiosPrivate = useAxiosPrivate();
	const [isLoading, setLoading] = useState(false);

	const [hasMessaged, setHasMessaged] = useState(false);
	const [newMessage, setNewMessage] = useState('');

	const [msg, setMsg] = useState([]);
	const [reply, setReply] = useState('');

	/** CHECK IF MESSAGED */
	useEffect(() => {
		setLoading(true);
		let isMounted = true;
		const controller = new AbortController();
		const hasMessagedCheck = async () => {
			let data = { uid: ruid };
			try {
				const response = await axiosPrivate.post('/api/messages/check/', data);
				console.log(response.data);
				var msgd = response.data._id;
				setHasMessaged(msgd);
				//setTest([response.data])

				//setLoading(false);
			} catch (err) {
				console.log(err);
			}
			setLoading(false);
		};
		hasMessagedCheck();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [hasMessaged]);
	/** END CHECK IF MESSAGED */

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		console.log('submit hit');

		var query = {
			uid: ruid,
			msg: newMessage,
		};

		const data = JSON.stringify(query);

		console.log(query);

		var sendURL = '/api/messages';
		const SendNew = async () => {
			try {
				const res = await axiosPrivate.post(sendURL, data);

				if (res.data.length === 0) {
					console.log('Array is empty!');
				}
				console.log(res.data);

				setHasMessaged(true);

				/** Start Refresh Chat **/
				///window.location.reload();
				/** End Refresh Chat **/
			} catch (err) {
				console.log(err);
			}
			setLoading(false);
		};
		SendNew();
	};

	if (isLoading) {
		return (
			<div className='msgloader-placeholder ReactModal__Overlay ReactModal__Overlay--after-open msgModalOverlay'>
				<div id='msgModalLoader'>
					<div className='modal-body'>
						<div className='direct-message-panel'>
							<div className='lds-facebook'>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (hasMessaged && hasMessaged.length > 0) {
		return (
			<div>
				<ClientDirectRecruiterMessage dataFromParent={hasMessaged} ruid={ruid.ruid} first_name={first_name} />
			</div>
		);
	} else {
		return (
			<>
				<div className='inbox direct-message-inbox initial-message'>
					<h4>Chat with {first_name}</h4>

					<div className='direct-message-content'>
						<div className='chat-window-placeholder'></div>

						<form onSubmit={handleSubmit} className='replyoptions'>
							<textarea
								value={newMessage}
								onChange={(e) => setNewMessage(e.target.value)}
								type='text'
								placeholder='Start typing...'
								rows='5'
								maxLength={1000}
								minLength={3}
								style={{
									fontFamily: "'LabilGrotesk-Light', Arial, sans-serif FontAwesome",
									width: '100%',
								}}></textarea>

							<button type='submit'>
								<FontAwesomeIcon icon={faPaperPlane} />
							</button>
						</form>
					</div>
				</div>
			</>
		);
	}
};

export default ClientSendNewMessage;
