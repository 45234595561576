import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import RecfindrLogo from '../icons/RecfindrLogo.js';


const RecruiterHeader = (props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const avatarRef = useRef(null);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current && !dropdownRef.current.contains(event.target) &&
            avatarRef.current && !avatarRef.current.contains(event.target)
        ) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <header className={`headerLinks`}>
            <div className="logo">
                <NavLink to="/dashboard">
                    <RecfindrLogo />
                </NavLink>
            </div>
            
            <nav>
                <ul>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/dashboard">Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/inbox">Inbox</NavLink>
                    </li>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/profile">Edit Profile</NavLink>
                    </li>
                    
                    {/* Removed Logout NavLink since we're moving it to dropdown */}
                    
                    <li className="nav-avatar" onClick={toggleDropdown} ref={avatarRef}>
                        {props?.profile_picture === null || typeof props?.profile_picture === 'undefined' ? (
                            <img className="avatar" alt="" src={process.env.REACT_APP_RECFINDR_API+'/profile/blank.png'}></img>
                        ) : (
                            <img className="avatar" alt="" src={`data:image/jpeg;base64,${props.profile_picture}`}></img>
                        )}

                        {dropdownVisible && (
                            <div className="avatar-dropdown" ref={dropdownRef}>
                                <div className="triangle-with-shadow"></div>
                                <NavLink to="/account-settings">Account Settings</NavLink>
                                <NavLink to="/logout">Logout</NavLink>
                            </div>
                        )}
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default RecruiterHeader;