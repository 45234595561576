import { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import SuccessIcon from '../icons/SuccessIcon';
import BlankIcon from '../icons/BlankIcon';

function PersonalQuestionsAccordion({ defaultValue, onPersonalAnswersChange }) {
	const [answers, setAnswers] = useState(() => {
		return { ...defaultValue };
	});

	const [answeredQuestions, setAnsweredQuestions] = useState(() => {
		return Object.entries(defaultValue || {}).reduce((acc, [key, value]) => {
			if (value.length >= 1) acc.push(parseInt(key));
			return acc;
		}, []);
	});

	

	const handleQuestionAnswered = (questionNumber, value) => {
		setAnswers((prevAnswers) => {
			const newAnswers = {
				...prevAnswers,
				[questionNumber]: value,
			};
			// Call the passed in onPersonalAnswersChange prop function with newAnswers
			onPersonalAnswersChange(newAnswers);
			return newAnswers;
		});

		if (value.length >= 1 && !answeredQuestions.includes(questionNumber)) {
			setAnsweredQuestions((prev) => {
				const newAnsweredQuestions = [...prev, questionNumber];
				// Optionally, if you want to pass the answeredQuestions to parent as well, you can do it here.
				// onAnsweredQuestionsChange(newAnsweredQuestions);
				return newAnsweredQuestions;
			});
		} else if (value.length < 1 && answeredQuestions.includes(questionNumber)) {
			setAnsweredQuestions((prev) => {
				const newAnsweredQuestions = prev.filter((q) => q !== questionNumber);
				// Optionally, if you want to pass the answeredQuestions to parent as well, you can do it here.
				// onAnsweredQuestionsChange(newAnsweredQuestions);
				return newAnsweredQuestions;
			});
		}
	};

	const isQuestionDisabled = (questionNumber) => {
		return answeredQuestions.length >= 3 && !answeredQuestions.includes(questionNumber);
	};

	const isQuestionAnswered = (questionNumber) => {
		return answeredQuestions.includes(questionNumber);
	};

	const PersonalQuestions = {
		1: 'The reason I got into recruiting…',
		2: 'My work style in three words…',
		3: 'The biggest hurdle I’ve overcome…',
		4: 'My proudest moment as a recruiter…',
		5: 'A placement I’ll never forget is…',
		6: 'When I’m not recruiting, you can find me…',
		7: 'A book/podcast that influenced my view on work…',
		8: 'The best advice I’ve ever received about recruitment was…',
		9: 'A common misconception about my job is…',
		10: 'If I weren’t a recruiter, I’d probably be…',
	};

	const PersonalQuestionsIntroText = {
		1: 'Share your personal motivation and journey into the recruitment world.',
		2: 'Short, snappy, straight to the point.',
		3: 'Share how you understand the challenges you’ve faced, showing resilience and problem solving skills.',
		4: 'Share a story of success and what you consider a significant achievement.',
		5: 'This could be a memorable or challenging hiring scenario you’ve navigated.',
		6: 'Self explanatory this one.',
		7: 'What has inspired you or shaped your professional ideology.',
		8: 'Share the wisdom around.',
		9: 'Your time to nip those stereotypes in the bud.',
		10: 'Maybe don’t say “anything else.”',
	};

	return (
		<>
			<h4>Answer 3 'about you' questions</h4>
			<Accordion>
				{Object.entries(PersonalQuestions).map(([key, question], i) => (
					<Accordion.Item key={key} eventKey={key}>
						<Accordion.Header disabled={isQuestionDisabled(parseInt(key))}>
							{question}
							{isQuestionAnswered(parseInt(key)) ? <SuccessIcon /> : <BlankIcon />}
						</Accordion.Header>
						<Accordion.Body>
							<hr></hr>
							<p className='intro-text'>{PersonalQuestionsIntroText[key]}</p>
							<textarea
								rows='2'
								maxLength='300'
								type='text'
								id={key}
								name={key}
								value={answers[key] || ''} // Ensure that the value attribute is set based on the answers state
								className={`formgroup p-4 required ${isQuestionAnswered(parseInt(key)) ? 'answered' : ''}`}
								placeholder='Start typing...'
								onChange={(e) => handleQuestionAnswered(parseInt(key), e.target.value)}
								required
							/>
						</Accordion.Body>
					</Accordion.Item>
				))}
			</Accordion>
			<div className='accordionQuestionsFeedback'>{answeredQuestions.length} of 3 questions complete</div>
		</>
	);
}
export default PersonalQuestionsAccordion;
