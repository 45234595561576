import React from 'react';

const BlankIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    height="1em"
    className="blank-circle"
    viewBox="0 0 512 512"
  >
    <path
      fill="var(--color-grey)"
      d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0 0 114.6 0 256s114.6 256 256 256z"
    />
  </svg>

)
export default BlankIcon