import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useCompanyRefreshToken from "../hooks/useCompanyRefreshToken";
import useAuth from '../hooks/useAuth';


const PersistLoginCompany = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useCompanyRefreshToken();
    const { auth } = useAuth();
    //console.log('persist... '+auth.role)

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err)
                navigate('/login')
            }
            finally {
                setIsLoading(false)
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    },[auth?.accessToken, refresh, navigate])

    useEffect(() => {
        //console.log('auth '+JSON.stringify(auth))
        //console.log(`isloading: ${isLoading}`)
        //console.log(`PersistLoginCompany.js aT: ${JSON.stringify(auth.accessToken)}`)
        //console.log(`Auth : ${JSON.stringify(auth)}`)
    }, [isLoading])

    return(
        <>
            {isLoading
                ? <><div className="lds-facebook"><div></div><div></div><div></div></div></>
                : <Outlet />
            }
        </>
    )
}

export default PersistLoginCompany