import React, { useRef, useState } from 'react';
import useAxiosPrivateUploader from '../hooks/useAxiosPrivateUploader';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons";



const FilesUploadComponent = (props) => {

    //console.log(props.picture)

    const axiosPrivateUploader = useAxiosPrivateUploader(); 
    const axiosPrivate = useAxiosPrivate(); 
    //const location = useLocation();

    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);


    const changeHandler = async (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    
        var uploadURL = '/api/upload/'
        const formData = new FormData();
        formData.append('File', event.target.files[0]);
    
        if (event.target.files[0].size > 2000000) {
            alert("Please upload file less than 2MB. Thanks!!");
            $(this).val('');
        } else {
            try {
                const res = await axiosPrivateUploader.post(uploadURL, formData);
                console.log(res);
                window.location.reload(false);
            } catch (err) {
                console.log(err);
            }
        }
    };
    

/*
    const handleUpload = () => {
        var uploadURL = '/api/upload/'
        const formData = new FormData();
        formData.append('File', selectedFile);


        const uploadImage =  async () => {

            if(selectedFile.size > 2000000) {
                alert("Please upload file less than 2MB. Thanks!!");
                $(this).val('');
              } else {

                try {
                    const res = await axiosPrivateUploader.post(uploadURL, formData);
                    //const response = await axiosPrivate.get(profileURL, {
                    //    single: controller.signal
                    //});

                    //setTest([response.data])
                    console.log(res)
                    window.location.reload(false);
                } catch(err) {
                    console.log(err);
                }
            }
        }
            uploadImage()
    };
*/

    const handleRemove = () => {
        var uploadURL = '/api/upload/remove'
        let blank = 'blank.png'
        const data = {profile_picture : blank}

        const removeImage =  async () => {

            try {
                const res = await axiosPrivate.post(uploadURL, data);
                /*const response = await axiosPrivate.get(profileURL, {
                    single: controller.signal
                });*/

                //setTest([response.data])
                window.location.reload(false);
                console.log(res)

            } catch(err) {
                console.log(err);
            }
        }
            removeImage()
    };
    

    const fileInputRef = useRef(null);

    /*const handleClick = () => {
        if (!isSelected) {
          fileInputRef.current.click();
        }
    };*/
    
    return (
        <div className="avatarContainer">

                <div className="avatar-upload-wrapper">
                    <div className="avatar-input-wrapper">
                        <div className="avatar-label">
                        <label>Profile picture upload *</label>
                            <input type="file" name="sampleFile" accept=".gif,.jpg,.jpeg,.png" onChange={changeHandler} id="fileInput" ref={fileInputRef} style={{ display: 'none' }}/>
                        </div>
                        <div className="fileChosen">
                            <label htmlFor="fileInput" id="fileInputLabel" className="btn btn-secondary">Choose a file</label>
                            <div className="chosen">
                            {props?.picture === 'blank.png' || typeof props?.picture === 'undefined' ? (
                                <span className="d-block">.jpg, .jpeg, .png .gif</span>
                            ) : (
                                ''
                            )}
                            </div>
                        </div>
                    </div>
                    <div className="avatar-image-wrapper">
                        <div style={{}}>
                        {props?.picture === null || typeof props?.picture === 'undefined' ? (
                            <img className="avatar" alt="" src={process.env.REACT_APP_RECFINDR_API+'/profile/blank.png'}></img>
                        ) : (
                            <img className="avatar" alt="" src={`data:image/jpeg;base64,${props.picture}`}></img>
                        )}               
                        </div>
                        <div style={{}}>
                        {props?.picture === 'blank.png' ? (
                            ''
                        ) : (
                            <span className="d-block"><FontAwesomeIcon icon={faTimes} onClick={handleRemove} /></span>
                        )}
                        </div>
                    </div>
                </div>

        </div>
            
    )
}

export default FilesUploadComponent