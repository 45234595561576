import Register from './components/Register';
import Login from './components/Login';
import PasswordRecover from './components/PasswordRecover';
import PasswordReset from './components/PasswordReset';
import ActivateAccount from './components/ActivateAccount';

import CompanyRegister from './components/CompanyRegister';
import CompanyPasswordRecover from './components/CompanyPasswordRecover';
import CompanyPasswordReset from './components/CompanyPasswordReset';
import CompanyActivate from './components/CompanyActivateAccount';
import CompanyLogin from './components/CompanyLogin';
import CompanyRequireProfileSetup from './components/CompanyRequireProfileSetup';

import Home from './components/Home';
import Layout from './components/Layout';
import Account from './components/Account';
import Profile from './components/Profile';
import RecruiterFollowers from './components/recruiters-components/RecruitersFollowers';
import ClientFollowing from './components/client-components/ClientFollowing';
//import User from './components/User';
import Search from './components/Search';
import NewRecruiters from './components/NewRecruiters';
import Inbox from './components/Inbox';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Logout from './components/Logout';
import RecruiterSubscribe from './components/recruiters-components/RecruiterSubscribe';
import RequireProfileSetup from './components/RequireProfileSetup';

//import ViewProfile from './components/viewProfile';
import ViewProfileWrapper from './components/viewProfileWrapper';

import Pricing from './components/Pricing';
import Upgrade from './components/PricingUpgrade';

import Welcome from './components/Welcome'
import Client from './components/Client';

import Admin from './components/Admin';


import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import PersistLoginCompany from './components/PersistLoginCompany';
import { Routes, Route } from 'react-router-dom';




/*
const ROLES = [
    5150,
    1984,
    2001,
    3000,
    3001,
    3002
]
*/




function App() {
  

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
            <Route path="login/agency" element={<CompanyLogin />} />
        
        <Route path="register" element={<Register />} />
            <Route path="register/agency" element={<CompanyRegister />} />
        
        <Route path="recover" element={<PasswordRecover />} />
            <Route path="recover/agency" element={<CompanyPasswordRecover />} />

        <Route path="reset" element={<PasswordReset />} />
            <Route path="reset/agency" element={<CompanyPasswordReset />} />

        <Route path="activate" element={<ActivateAccount />} />
           <Route path="activate/agency" element={<CompanyActivate />} />

        


        <Route path="unauthorized" element={<Unauthorized />} />

        {/* we want to protect these routes. */}
        <Route element={<PersistLogin />}>

        <Route element={<RequireAuth allowedRoles={[5150]} />}>
          <Route path="admin" element={<Admin />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002]} />}>
          <Route path="pricing" element={<Pricing />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002]} />}>
          <Route path="upgrade" element={<Upgrade />} />
        </Route>

        <Route path="/u/*" element={<ViewProfileWrapper />} />
        <Route path="/u/:uuid/message" element={<ViewProfileWrapper />} />
        <Route path="/u/:uuid/review" element={<ViewProfileWrapper />} />

        

        
          <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002, 4000]} />}>
              <Route element={<RequireProfileSetup />}>
                <Route path="/" element={<Home />} />
              </Route>
          </Route>

          <Route element={<RequireAuth allowedRoles={[3001, 3002, 4000]} />}>
            <Route path="welcome" element={<Welcome />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3001, 3002]} />}>
            <Route path="dashboard" element={<Home />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002, 4000]} />}>
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002, 4000]} />}>
            <Route path="account" element={<Account />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3001]} />}>
            <Route path="followers" element={<RecruiterFollowers />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3002]} />}>
            <Route path="following" element={<ClientFollowing />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3002]} />}>
            <Route path="search" element={<Search />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3002]} />}>
            <Route path="new-recruiters" element={<NewRecruiters />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3001, 3002]} />}>
            <Route path="inbox" element={<Inbox />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3001]} />}>
            <Route path="subscribe" element={<RecruiterSubscribe />} />
          </Route>
{/*
          <Route element={<RequireAuth allowedRoles={[3001, 3002]} />}>
            <Route path="user" element={<User />} />
          </Route>
  */}
          <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002]} />}>
            <Route path="logout" element={<Logout />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[3000, 3001, 3002]} />}>
            <Route path="client" element={<Client />} />
          </Route>
          
          
        </Route>

        <Route element={<PersistLoginCompany />}>
              <Route element={<CompanyRequireProfileSetup />}>
                  <Route path="agency" element={<Home />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[4000, 3001]} />}>
                <Route path="agency/welcome" element={<Welcome />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[4000, 3001]} />}>
                <Route path="agency/dashboard" element={<Welcome />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[4000]} />}>
                <Route path="agency/pricing" element={<Upgrade />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[4000]} />}>
                <Route path="agency/upgrade" element={<Upgrade />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[4000, 3001]} />}>
                <Route path="agency/logout" element={<Logout />} />
              </Route>
              
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;