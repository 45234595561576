import { useState, useEffect, isLoading, setLoading } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ClientLeaveReview = (ruid) => {
	//const location = useLocation();

	const axiosPrivate = useAxiosPrivate();

	const [isLoading, setLoading] = useState(false);
	const [hasReview, setHasReview] = useState(false);
	const [hasReviewedContent, setHasReviewedContent] = useState('');
	const [leaveReviewContent, setLeaveReviewContent] = useState('');
	const [leaveReviewEmotion, setLeaveReviewEmotion] = useState('');
	const [leaveReviewScore, setLeaveReviewScore] = useState('1');

	// RT - SET SELECTED EMOTION STUFF

	const [selectedEmotion, setSelectedEmotion] = useState(null);

	const handleEmotionSelect = (emotionId) => {
		setLeaveReviewEmotion(emotionId);
		setSelectedEmotion(emotionId); // adding class to selected one
	};

	// RT - SET SELECTED EMOTION STUFF - MAPPING ICONS AND MEANING

	// RT - EMOTE LABELS FOR THEIR MEANING
	const emojiData = [
		{ id: 4, label: 'Successful placement' },
		{ id: 3, label: 'Provided great candidates' },
		{ id: 1, label: 'Great market insight' },
		{ id: 2, label: 'Great listener' },
		{ id: 8, label: 'Did not ghost me' },
	];

	// RT - MAPPING EMOTES
	const emojis = emojiData.map((emoji) => (
		<button
			key={emoji.id}
			className={`emoji-button btn btn-with-icon ${selectedEmotion === String(emoji.id) ? 'selected' : ''}`}
			onClick={() => handleEmotionSelect(String(emoji.id))}>
			<img
				src={`/assets/Recfindr_Emotes/Recfindr_Emote-${emoji.id}.svg`}
				className='img-fluid'
				data-emotion={emoji.id}
				alt={emoji.label}
			/>
			<span className='emoji-label'>{emoji.label}</span>
		</button>
	));

	/** CHECK IF REVIEWED */
	const hasReviewCheck = async () => {
		let data = { uid: ruid };
		try {
			const response = await axiosPrivate.post('/api/reviews/check/', data);

			if (response.data._id) {
				setHasReviewedContent(response.data);
				console.log(response.data);
				setHasReview(true);
				console.log('got it');
			}

			//setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		hasReviewCheck();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	/** END CHECK IF REVIEWED */

	function handleKeyPress(e) {
		if (e.key === 'Enter') {
			//console.log('enter press here! ')
			//handleSubmit(e)
			e.stopPropagation();
		}
	}

	const handleReviewSubmit = (e) => {
		e.preventDefault();

		var query = {
			revieweeId: ruid.ruid,
			reviewScore: leaveReviewScore,
			review: leaveReviewContent,
			reviewEmotion: leaveReviewEmotion,
		};

		const data = JSON.stringify(query);

		console.log(query);

		var sendURL = '/api/reviews';
		const LeaveReview = async () => {
			setLoading(true);
			try {
				const res = await axiosPrivate.post(sendURL, data);

				if (res.data.length === 0) {
					console.log('Array is empty!');
				}
				console.log(res.data.createdAt);
				setHasReview(true);
				//setLoading(false);
			} catch (err) {
				console.log(err);
			}
			setHasReviewedContent({
				review: leaveReviewContent,
				reviewScore: leaveReviewScore,
				reviewEmotion: leaveReviewEmotion,
			});
			setLoading(false);
		};

		if (leaveReviewContent.length < 10) {
			console.log('less than 10 characters');
			alert('Review must be 10 characters or more');
		} else {
			LeaveReview();
		}
	};

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
				.
			</div>
		);
	}

	if (hasReview === true) {
		return (
			<div className='col-md-12'>
				<div className='review-section has-reviewed'>
					<span className='d-none'>Score: {hasReviewedContent.reviewScore}</span>
					<h4>You left the following review</h4>
					<div className='has-reviewed-wrapper'>
						<img
							src={`/assets/Recfindr_Emotes/Recfindr_Emote-${hasReviewedContent.reviewEmotion}.svg`}
							className='img-fluid'
							data-emotion='1'
							alt='Review Emotion'
						/>
						<span className='review-content'>{hasReviewedContent.review}</span>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<>
				<div className='col-md-12'>
					<div className='review-section is-reviewing'>
						<h3>Leave a review</h3>

						<div className='reviewContent'>
							<textarea
								value={leaveReviewContent}
								onChange={(e) => setLeaveReviewContent(e.target.value)}
								type='text'
								placeholder='Write your review...'
								rows='5'
								maxLength={1000}
								minLength={3}
								style={{
									fontFamily: "'LabilGrotesk-Light', Arial, sans-serif FontAwesome",
									width: '100%',
								}}
								onKeyDown={(e) => handleKeyPress(e)}></textarea>

							{/*DUNNO IF WE NEED THIS ANYMORE?*/}
							<div className='reviewScore'>
								<select className='d-none' onChange={(e) => setLeaveReviewScore(e.target.value)}>
									<option value='1'>1</option>
									<option value='2'>2</option>
									<option value='3'>3</option>
									<option value='4'>4</option>
									<option value='5'>5</option>
								</select>
							</div>

							{/*leaveReviewEmotion*/}
							<div id='emotion'>{emojis}</div>

							<input
								className='btn btn-primary'
								type='button'
								value='Leave Review'
								onClick={handleReviewSubmit}
								disabled={!leaveReviewEmotion || leaveReviewContent.length < 10}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
};

export default ClientLeaveReview;
