import { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import useAxiosPrivate from "../../hooks/useAxiosPrivate";



const RecruiterFollowers = () => {
    
    const axiosPrivate = useAxiosPrivate(); 
    const [ profile, setProfile ] = useState();
    const profileURL = '/api/profile/'


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getProfile =  async () => {
            try {
                const response = await axiosPrivate.get(profileURL, {
                    single: controller.signal
                });
                console.log(response.data)
                var profiledata = JSON.parse(response.data);

                const setValues = {
                    username: profiledata.username,
                    role: profiledata.role,
                    password: profiledata.password,
                    first_name: profiledata.first_name,
                    last_name: profiledata.last_name,
                    profile_picture: profiledata.profile_picture,
                    job_title: profiledata.job_title,
                    headline_stat_1: profiledata.headline_stat_1,
                    headline_stat_2: profiledata.headline_stat_2,
                    headline_stat_3: profiledata.headline_stat_3,
                    linkedin_url: profiledata.linkedin_url,
                    website_url: profiledata.website_url,
                    phone_number: profiledata.phone_number,
                    company: profiledata.company,
                    location_1: {
                        name: profiledata.location_1.name,
                        coordinates: profiledata.location_1.coordinates
                    },
                    location_2: {
                        name: profiledata.location_2.name,
                        coordinates: profiledata.location_2.coordinates
                    },
                    industry_1: profiledata.industry_1,
                    industry_2: profiledata.industry_2,
                    biography: profiledata.biography,
                    past_assignment_job_title: profiledata.past_assignment_job_title,
                    past_assignment_location: profiledata.past_assignment_location,
                    past_assignment_salary: profiledata.past_assignment_salary,
                    active_roles_job_title: profiledata.active_roles_job_title,
                    active_roles_salary: profiledata.active_roles_salary,
                    video: profiledata.video
                };



                isMounted && setProfile(setValues);

            } catch(err) {
                console.log(err);
            }
        }
        getProfile();
        return () => {
            isMounted = false;
            controller.abort();
        }
    },[])


    const [followers, setFollowers] = useState("");
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getFollowers =  async () => {
            try {
                const response = await axiosPrivate.get('/api/like', {
                    single: controller.signal
                });

                var followData = JSON.stringify(response.data);
                isMounted && setFollowers(followData);

            } catch(err) {
                console.log(err);
            }
        }
        getFollowers();
        return () => {
            isMounted = false;
            controller.abort();
        }
    },[])




  return (


        <article>

            <h2>Welcome {profile.username}</h2>
            <Link to="/profile" state={{role: profile.role}}>Profile</Link>
            <br/>
            <p className="">{followers}</p>       

            <br />
        </article>
  )
}

export default RecruiterFollowers