import { NavLink, Link } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ArrowScribble from '../icons/ArrowScribble.js';
import EyeIcon from '../icons/EyeIcon.js';
import PaintRollerIcon from '../icons/PaintRollerIcon.js';
import MessageIcon from '../icons/MessageIcon.js';
import PremiumIcon from '../icons/PremiumIcon.js';
import VerifiedIcon from '../icons/VerifiedIcon.js';
import BigScribble from '../icons/BigScribble.js';

const RecruiterHeaderWelcome = ({
	first_name,
	profile_picture,
	uuid,
	verifiedMember,
	paid,
	hasSubscriptionWithoutMembers,
}) => {
	//console.log(first_name, profile_picture, uuid, verifiedMember, paid)
	return (
		<>
			<section className='recruiterWelcomeHeaderWrapper'>
				<BigScribble />
				<div className='container'>
					<div className='row d-flex align-items-center'>
						<div className='col-md-9'>
							<div className='welcomeIntroWrapper'>
								<h1 className='h2'>Hello, {first_name}</h1>
								{paid && <PremiumIcon />}
								{verifiedMember && <VerifiedIcon />}
							</div>
							<div className='welcomeLinks'>
								<NavLink
									className={({ isActive }) =>
										isActive ? 'btn btn-with-icon active' : 'btn btn btn-with-icon inactive'
									}
									to={`/u/${uuid}`}>
									<EyeIcon />
									View my profile
								</NavLink>

								<NavLink
									className={({ isActive }) =>
										isActive ? 'btn btn-with-icon active' : 'btn btn btn-with-icon inactive'
									}
									to='/profile'>
									<PaintRollerIcon />
									Edit my profile
								</NavLink>

								<NavLink
									className={({ isActive }) =>
										isActive ? 'btn btn-with-icon active' : 'btn btn btn-with-icon inactive'
									}
									to='/inbox'>
									<MessageIcon />
									View inbox
								</NavLink>
							</div>
						</div>

						<div className='col-md-3 avatarHolder'>
							{profile_picture === null || typeof profile_picture === 'undefined' ? (
								<img
									className='avatar'
									alt=''
									src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}
								/>
							) : (
								<img className='avatar' alt='' src={`data:image/jpeg;base64,${profile_picture}`} />
							)}

							<ArrowScribble />
						</div>
					</div>
				</div>
			</section>
			<div className='welcomeText'>
				{hasSubscriptionWithoutMembers && (
					<>
						<div className='notification-bar bg-secondary-hover'>
							<div className='container'>
								<div className='col-md-12 d-flex justify-content-between align-items-center'>
									<span>You have an active subscription with unallocated seats</span>

									<a href='/agency/dashboard?rec=true' className='btn btn-dark'>
										Allocate Premium Members
									</a>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default RecruiterHeaderWelcome;
