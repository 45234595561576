import RecruiterDashboard from "./recruiters-components/RecruiterDashboard";
import ClientDashboard from "./client-components/ClientDashboard";
import useAuth from '../hooks/useAuth';

const Home = () => {
    const { auth } = useAuth();
    const roles = auth.role;

    // Check for each role and return the corresponding dashboard
    if (roles.includes(3000)) {
        // Handle role 3000
    } else if (roles.includes(3001)) {
        return <RecruiterDashboard />;
    } else if (roles.includes(3002)) {
        return <ClientDashboard />;
    } else if (roles.includes(4000)) {
        return <>Company Dashboard</>;
    } else {
        return <>error</>;
    }
}

export default Home;
