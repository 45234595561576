//import RecruiterAccount from './recruiters-components/RecruiterAccount';
import ClientAccountWelcome from "./client-components/ClientWelcome";
//import ClientHeaderWelcome from "./client-components/ClientHeaderWelcome";
//import RecruiterProfile from "./recruiters-components/RecruiterProfile";
import RecruiterAccountWelcome from "./recruiters-components/RecruiterWelcome";

import useAuth from '../hooks/useAuth';
import CompanyAccountWelcome from "./company-components/CompanyWelcome";

const Profile= () => {
    const { auth } = useAuth();

    const role = auth.role

    if (role.includes(3001)) {
        return (
            <main className="App">
                <RecruiterAccountWelcome />
            </main>
        );
    } else if (role.includes(3002)) {
        return (
            <main className="App">
                <ClientAccountWelcome />
            </main>
        );
    } else if (role.includes(4000)) {
        return (
            <main className="App">
                <CompanyAccountWelcome/>
            </main>
        );
    } else {
        return (
            <main className="App">
                <>error</>
            </main>
        );
    }

    
}

export default Profile
