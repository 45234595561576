import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import RecfindrLogo from '../icons/RecfindrLogo.js';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ClientNav = (props) => {

	const [dropdownVisible, setDropdownVisible] = useState(false);
	const avatarRef = useRef(null);
	const dropdownRef = useRef(null);

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	const handleClickOutside = (event) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target) &&
			avatarRef.current &&
			!avatarRef.current.contains(event.target)
		) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	/* Start get profile picture */
	const axiosPrivate = useAxiosPrivate();
	const [profilePicture, setProfilePicture] = useState(null);
	const profileURL = '/api/profile/picture';

	const getProfilePicture = async () => {
		try {
			const response = await axiosPrivate.get(profileURL);
			setProfilePicture(JSON.parse(response.data));
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		getProfilePicture();

		return () => {
			//isMounted = false;
			controller.abort();
		};
	},);
	/* End get profile picture */

	return (
		<header className={`headerLinks`}>
			<div className='logo'>
				<NavLink to='/'>
					<RecfindrLogo />
				</NavLink>
			</div>

			<nav>
				<ul>
					<li>
						<NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/dashboard'>
							Matches
						</NavLink>
					</li>

					<li>
						<NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/search'>
							Find a Consultant
						</NavLink>
					</li>

					<li>
						<NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/inbox'>
							Inbox
						</NavLink>
					</li>

					<li>
						<NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to='/profile'>
							Profile
						</NavLink>
					</li>

					<li className='visible-sm'>
						<NavLink to='/logout'>Logout</NavLink>
					</li>

					{/* Removed Logout NavLink since we're moving it to dropdown */}

					<li className='nav-avatar' onClick={toggleDropdown} ref={avatarRef}>
						{profilePicture === null || typeof profilePicture === 'undefined' ? (
							<img
								className='avatar'
								alt=''
								src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}></img>
						) : (
							<img className='avatar' alt='' src={`data:image/jpeg;base64,${profilePicture}`}></img>
						)}

						{dropdownVisible && (
							<div className='avatar-dropdown' ref={dropdownRef}>
								<div className='triangle-with-shadow'></div>
								<NavLink to='/logout'>Logout</NavLink>
							</div>
						)}
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default ClientNav;
