import { useState, useEffect, isLoading, setLoading } from 'react';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import HeartIcon from '../icons/HeartIcon.js';
import MailIcon from '../icons/MailIcon.js';
import VerifiedIcon from '../icons/VerifiedIcon.js';
import PremiumIcon from '../icons/PremiumIcon.js';
import ReviewIcon from '../icons/ReviewIcon.js';
import MatchedScribble from '../icons/MatchedScribble.js';
import MultiScribble from '../icons/MultiScribble.js';
import HideNotificationBar from '../HideNotificationBar.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClientSendNewMessage from './ClientSendNewMessage';
import Modal from 'react-modal/lib/components/Modal';
import ClientHeader from './ClientHeader';

const ClientFollowing = ({ client }) => {
	const axiosPrivate = useAxiosPrivate();

	const [like, setLike] = useState();

	/* GET CLIENT PROFILE PIC START */
	/*const [profilePic, setProfilePic] = useState(null)
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getProfilePic =  async () => {
            try {
                setLoading(true)
                const response = await axiosPrivate.post('/api/clients/profile_picture', {
                    single: controller.signal
                });
                console.log(JSON.stringify(response.data))
                
                setProfilePic(response.data);
                setLoading(false);

            } catch(err) {
                console.log(err);
            }
        }

        getProfilePic();
        return () => {
            isMounted = false;
            controller.abort();
        }
  
    },[])
    
    const headerProps = {
        profile_picture: profilePic,
    }
    
    /* GET CLIENT PROFILE PIC END */

	/* FOLLOWING START */
	const [isLoading, setLoading] = useState(true);
	const [following, setFollowing] = useState('');
	const [ignoreNotice, setIgnoreNotice] = useState(false);


	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const getFollowing = async () => {
			try {
				setLoading(true);
				const keyExists = localStorage.getItem('hideNotice') !== null;
				if (keyExists === 'hideNotice') {
    				setIgnoreNotice(keyExists);
				}
				const response = await axiosPrivate.get('/api/like', {
					single: controller.signal,
				});
				//console.log(JSON.stringify(response.data));
				var followData = response.data;
				isMounted && setFollowing(followData);
				//setTest([response.data])
				setLoading(false);
			} catch (err) {
				console.log(err);
			}
		};

		getFollowing();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [like]);
	/* FOLLOWING END */

	/* LIKE START */
	/** handleLike is not in use but expect this to be requested. Currently just removes match and drops them from page */
	const toggleProfileLike = ({ id, like }) => {
		const query = {
			id,
			like,
		};
		if (like === 1) {
			handleLike(query);
		} else if (like === 0) {
			handleDislike(query);
		}
	};

	const handleLike = async (query) => {
		console.log(query);

		try {
			const res = await axiosPrivate.post('/api/like', query);

			if (res.data.length === 0) {
				console.log('Array is empty!');
			}
			console.log(res.data);
		} catch (err) {
			console.log(err);
		}
		setLike(query);
	};

	const handleDislike = async (query) => {
		console.log(query);

		try {
			const res = await axiosPrivate.post('/api/like', query);

			if (res.data.length === 0) {
				console.log('Array is empty!');
			}
			console.log(res.data);
		} catch (err) {
			console.log(err);
		}
		setLike(query);
	};
	/* LIKE END */

	/*** Msg MODAL */
	const [msgIsOpen, setMsgIsOpen] = useState(false);
	const [msgModalContent, setMsgModalContent] = useState(false);

	function openMsgModal() {
		console.log('open?');
		setMsgIsOpen(true);
	}

	function afterMsgOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeMsgModal() {
		setMsgIsOpen(false);
	}

	const msgModal = (fields) => {
		//console.log(fields.id)
		//console.log(fields.first_name)
		var data = {
			id: fields.id,
			name: fields.first_name,
		};

		setMsgModalContent({
			id: fields.id,
			name: fields.first_name,
		});

		console.log(data);
		openMsgModal();
	};

	<Modal
		isOpen={msgIsOpen}
		onAfterOpen={afterMsgOpenModal}
		onRequestClose={closeMsgModal}
		ariaHideApp={false}
		contentLabel='Msg'
		id='msgModal'
		shouldCloseOnOverlayClick={true}
		overlayClassName='msgModalOverlay'
		className='container'
		style={{
			overlay: {
				backgroundColor: 'rgba(255, 255, 255, 0)',
				zIndex: 10000,
			},
			content: {
				zIndex: 10000,
			},
		}}>
		<div className='modal-body'>
			<div className='direct-message-panel'>
				<button className='closeRec' onClick={closeMsgModal}>
					<FontAwesomeIcon icon={faTimes} />{' '}
				</button>
				<ClientSendNewMessage ruid={msgModalContent?.id} first_name={msgModalContent?.first_name} />
			</div>
		</div>
	</Modal>;

	/*** END Msg MODAL */

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
				.
			</div>
		);
	}

	return (
		<>
		{!ignoreNotice && (
			<>
			<HideNotificationBar storageKey='hideNotice'>
			<div className='notification-bar bg-secondary-hover'>
				<div className='container'>
					<div className='col-md-12 d-flex justify-content-between align-items-center'>
						<span>
							{following?.length
								? `You've matched with ${following.length} consultant${following.length !== 1 ? 's' : ''}. `
								: 'You’ve not matched with anyone yet. '}
							<Link to='/search'>Start searching</Link>
							{following?.length
								? ' to find more recruitment specialists.'
								: ' to find your recruitment specialist.'}
						</span>
						
							<button className='btn'>
								<FontAwesomeIcon icon={faTimes} />
							</button>
						
					</div>
				</div>
			</div>
			</HideNotificationBar>
			</>
		)}

			<div className='hero-header bg-secondary'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<h1 className='h3'>
								Welcome,{' '}
								<div className='nameHolder'>
									<MultiScribble />
									<span>{client?.first_name}</span>
								</div>
							</h1>
						</div>
					</div>
				</div>
			</div>

			<div className='app-main-wrapper'>
				<div className='rf-bg-six d-sm-none d-md-block'></div>
				<section className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<h1 className='h4'>Your matches</h1>

							<div className='followingResults row'>
								{following?.length ? (
									following.map((user, i) => (
										<div key={i} className='col-md-6 flex-grow'>
											<div className='followingResultsTile followingTile'>
												<div className='resultLeft'>
													<div className='avatarWrapper'>
														<div className='avatarImageWrapper'>
															{user?.profile_picture === null ||
															typeof user?.profile_picture === 'undefined' ? (
																<img
																	alt=''
																	src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}></img>
															) : (
																<img alt='' src={`data:image/jpeg;base64,${user?.profile_picture}`}></img>
															)}
														</div>

														<MatchedScribble />
													</div>

													<div className='resultBio'>
														<span className='name'>
															{user?.first_name}{' '}
															{(user?.paidByCompany || user?.paidBySelf) && user?.last_name}
														</span>
														<span className='job-title'>{user?.job_title}</span>
														<span className='tag tag-light-grey'>{user?.industry_1}</span>
													</div>
												</div>
												<div className='resultRight'>
													<div className='actionsHolder'>
														<div className=''>
															<Link
																to={{
																	pathname: `/u/${user?.uuid}/review`,
																}}>
																<ReviewIcon className='reviewIcon' />
															</Link>

															<Link
																to={{
																	pathname: `/u/${user?.uuid}/message`,
																}}>
																<MailIcon className='mailIcon' />
															</Link>
															<HeartIcon
																className='likeIcon'
																onClick={(fields) =>
																	toggleProfileLike({
																		...fields,
																		id: user?._id,
																		like: following ? 0 : 1,
																	})
																}
															/>
														</div>
													</div>

													<Link
														to={'/u/' + user?.uuid}
														state={{ id: user?.uuid }}
														className='btn btn-secondary btn-sm'>
														<span>View profile</span>
													</Link>
												</div>
											</div>
										</div>
									))
								) : (
									<p>
										<Link to='/search' style={{ textDecoration: 'underline' }}>
											Start searching
										</Link>{' '}
										to find your recruitment specialist.
									</p>
								)}
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default ClientFollowing;
