import { useRef, useState, useEffect } from 'react';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../api/axios';
import { Link, useLocation } from 'react-router-dom';
//import WelcomeSetupHeader from './WelcomeSetupHeader';
import Select from 'react-select';

//const USER_REGEX = /^(?:[A-Z\d][A-Z\d_-]{5,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
//const USER_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
// eslint-disable-next-line no-control-regex
const USER_REGEX = /^(?!.*@(?:gmail|googlemail|yahoo|hotmail)\.(?:com|co\.uk)$)(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const ROLE_REGEX = /^((?!Role)(3001|3002)(,(\s)*(?!Role)(3001|3002))*)$/;
const INDUSTRY_REGEX = /^((?!Industry)(Financial Services|Medical\/Healthcare|Pharmaceuticals|FMCG|Construction\/Engineering & Design|Education|eCommerce|Public Sector|Automotive|Aerospace|Transport|Logistics|Engineering))(,(\s)*(?!Industry)(Financial Services|Medical\/healthcare|Pharmaceuticals|FMCG|Construction\/Engineering & Design|Education|eCommerce|Public Sector|Automotive|Aerospace|Transport|Logistics|Engineering))*$/;
const INDUSTRY_FOR_PRE_REG_REGEX = /^Financial Services$/;

const REGISTER_URL = '/register';

const Register = () => {
	const userRef = useRef();
	const errRef = useRef();
	const industryRef = useRef();
	const roleRef = useRef();

	const [user, setUser] = useState('');
	const [validName, setValidName] = useState(false);
	const [userFocus, setUserFocus] = useState(false);

	const [industry, setIndustry] = useState('');
	const [validIndustry, setValidIndustry] = useState(false);
	const [validIndustryForPreReg, setValidIndustryForPreReg] = useState(false);
	const [industryFocus, setIndustryFocus] = useState(false);

	const [continueRegistration, setContinueRegistration] = useState(false);
	const [registerInterest, setRegisterInterest] = useState(false);

	const [pwd, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [role, setRole] = useState('');
	const [validRole, setValidRole] = useState(false);
	const [roleFocus, setRoleFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);

	const [GDPR, setGDPR] = useState(false);
	const [validGDPR, setValidGDPR] = useState(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const item = queryParams.get('item');
	const plan = queryParams.get('plan');
	const hiringmanager = queryParams.get('hiringmanager');
	const coupon = queryParams.get('coupon');


	const handleGDPR = (e) => {
		setGDPR(e.target.checked);
		setValidGDPR(e.target.checked);
		console.log(GDPR);
	};

	useEffect(() => {
		userRef.current.focus();
	}, []);

	useEffect(() => {
		setValidName(USER_REGEX.test(user));
	}, [user]);

	useEffect(() => {
		setValidPwd(PWD_REGEX.test(pwd));
		setValidMatch(pwd === matchPwd);
	}, [pwd, matchPwd]);

	useEffect(() => {
		roleRef.current.focus();
	}, []);

	useEffect(() => {
		setValidRole(ROLE_REGEX.test(role.value));
	}, [role]);

	useEffect(() => {
		industryRef.current.focus();
	}, []);

	useEffect(() => {
		setValidIndustry(INDUSTRY_REGEX.test(industry.value));
		setValidIndustryForPreReg(INDUSTRY_FOR_PRE_REG_REGEX.test(industry.value));
		//console.log(validIndustry)
	}, [industry]);

	useEffect(() => {
		document.body.classList.add('theme-seven');
		// cleanup function
		return () => {
			document.body.classList.remove(''); // or reset to initial color
			// reset other styles
		};
	}, []); // Empty array means this runs once on mount and cleanup on unmount

	useEffect(() => {

		const v1 = USER_REGEX.test(user);
		const v2 = ROLE_REGEX.test(role.value);
		const v3 = INDUSTRY_FOR_PRE_REG_REGEX.test(industry.value);


		if (!v1 || !v2 || !v3) {
			setContinueRegistration(false);
			setRegisterInterest(true);
			//console.log(role.value);
			//return;
		} else {
			//console.log('Industry not supported');
			setContinueRegistration(true);
			setRegisterInterest(false);
			console.log('condition v3 met');
			console.log('handle next');
			console.log(role.value);
		}
	}, [industry, user, role]);

	const [terms, setTerms] = useState('recruitersubscriptionagreement');
    useEffect(() => {
     console.log('role change')
	 	if (role.value == 3001) {
			setTerms('recruitersubscriptionagreement');
		} else if (role.value == 3002) {
			setTerms('hiringmanagersubscriptionagreement');
		}
    }, [role])
	
	


	useEffect(() => {
		setErrMsg('');
	}, [user, pwd, matchPwd]);

	useEffect(() => {
		if (plan) {
			setRole({value: '3001', label: 'Recruiter'});
		} else if (hiringmanager) {
			setRole({value: '3002', label: 'Hiring Manager'});
		}
	}, [plan, hiringmanager]);
	

	const handleSubmit = async (e) => {
		e.preventDefault();
		// if button enabled with JS hack
		const v1 = USER_REGEX.test(user);
		const v2 = PWD_REGEX.test(pwd);
		const v3 = INDUSTRY_REGEX.test(industry.value);
		const v4 = ROLE_REGEX.test(role.value);
		const v5 = GDPR;
		if (!v1 || !v2 || !v3 || !v4 || !v5) {
			setErrMsg('Invalid Entry');
			return;
		}
		try {
			const roleValue = role.value;
			const industryValue = industry.value;

			console.log(JSON.stringify({ user, pwd, role: roleValue, industry: industryValue, item, plan, coupon }));

			const response = await axios.post(
				REGISTER_URL,
				JSON.stringify({ user, pwd, role: roleValue, industry: industryValue, item, plan, coupon }),
				{
					headers: { 'Content-Type': 'application/json' },
					withCredentials: true,
				}
			);
			// TODO: remove console.logs before deployment
			console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))
			setSuccessMessage({
				title: 'Confirm your email address',
				message:
					'Thanks for registering. To complete your registration and activate your account, please check your email for a confirmation link.',
				link: {
					link: '/login',
					anchorText: 'Login',
				},
			});
			setSuccess(true);
			//clear state and controlled inputs
			setUser('');
			setPwd('');
			setMatchPwd('');
			setRole('');
		} catch (err) {
			if (!err?.response) {
				setErrMsg('No Server Response');
			} else if (err.response?.status === 409) {
				setErrMsg('Username Taken');
			} else {
				console.log(err)
				setErrMsg('Registration Failed');
			}
			errRef.current.focus();
		}
	};


	const handleRegisterInterest = async (e) => {
		e.preventDefault();
		console.log('Register Interest');
		const v1 = USER_REGEX.test(user);
		const v2 = ROLE_REGEX.test(role.value);
		const v3 = INDUSTRY_REGEX.test(industry.value);
		if (!v1 || !v2 || !v3) {
			setErrMsg('Invalid Entry');
		} else {
			console.log('PASS');
		}

		try {
			const roleValue = role.value;
			const industryValue = industry.value;
			const response = await axios.post(
				'/register/preRegister',
				JSON.stringify({ user, role: roleValue, industry: industryValue }),
				{
					headers: { 'Content-Type': 'application/json' },
					withCredentials: true,
				}
			);
			// TODO: remove console.logs before deployment
			console.log(JSON.stringify(response?.data));
			//console.log(JSON.stringify(response))
			setSuccessMessage({
				title: 'We will be in touch',
				message:
					'We are launching Recfindr in Finance first (start-up and all that). However, we`ll drop you an email when your Industry is available.',
				link: {
					link: '/register',
					anchorText: 'Return to registration',
				},
			});
			setSuccess(true);
			//clear state and controlled inputs
			setUser('');
			setRole('');
			setIndustry('');
		} catch (err) {
			if (!err?.response) {
				setErrMsg('No Server Response');
			} else if (err.response?.status === 409) {
				setErrMsg('Username Taken');
			} else {
				setErrMsg('Registration Failed');
			}
			errRef.current.focus();
		}
	};

    const roleOptions = [
        //{value:'Role', label:'Role'},
        {value:'3001', label:'Recruiter'},
        {value:'3002', label:'Hiring Manager'},
    ]

    const industryOptions =[
            {value:'Financial Services', label:'Financial Services'},
            {value:'Medical/Healthcare', label:'Medical/Healthcare'},
            {value:'Pharmaceuticals', label:'Pharmaceuticals'},
            {value:'FMCG', label:'FMCG'},
            {value:'Construction/Engineering & Design', label:'Construction/Engineering & Design'},
            {value:'Education', label:'Education'},
            {value:'eCommerce', label:'eCommerce'},
            {value:'Public Sector', label:'Public Sector'},
            {value:'Automotive', label:'Automotive'},
            {value:'Aerospace', label:'Aerospace'},
            {value:'Transport', label:'Transport'},
            {value:'Logistics', label:'Logistics'},
            {value:'Engineering', label:'Engineering'}
    ]
 

	return (
		<>
			{success ? (
				<div className='app-main-wrapper'>
					<section className='container'>
						<div className='row'>
							<div className='col-lg-6 offset-lg-3'>
								<div className='form-success-panel'>
									<h1 className='h3'>{successMessage?.title}</h1>
									<p>{successMessage?.message}</p>

									<p>
										{successMessage?.link && (
											<a className='btn btn-secondary btn-lg' href={successMessage?.link?.link}>
												{successMessage?.link?.anchorText}
											</a>
										)}
									</p>
								</div>
							</div>
						</div>
					</section>
					<div className='rf-bg-four d-sm-none d-md-block'></div>
				</div>
			) : (
				<div className='app-main-wrapper'>
					<section className='container'>
						<div className='row'>
							<div className='col-md-6 offset-md-3'>
								<div id='register' className='form-panel'>
									<h1 className='h2 text-center'>Register</h1>
									<form onSubmit={handleSubmit}>
										<div className='input-live-val-wrapper'>
											<input
												type='text'
												id='username'
												placeholder='Email'
												ref={userRef}
												autoComplete='off'
												onChange={(e) => setUser(e.target.value)}
												value={user}
												required
												aria-invalid={validName ? 'false' : 'true'}
												aria-describedby='uidnote'
												onFocus={() => setUserFocus(true)}
												onBlur={() => setUserFocus(false)}
											/>
											<FontAwesomeIcon icon={faCheck} className={validName ? 'valid' : 'hide'} />
											<FontAwesomeIcon
												icon={faExclamation}
												className={validName || !user ? 'hide' : 'invalid'}
											/>
										</div>

										<p
											id='uidnote'
											className={userFocus && user && !validName ? 'instructions' : 'offscreen'}>
											<span>
												Must be a vaild lowercase work email address an not a free email provider such as gmail, hotmail
												etc
											</span>
										</p>

										<div className='input-live-val-wrapper'>
											<Select
												menuPortalTarget={document.body}
												menuPosition={'fixed'}
												isSearchable
												placeholder='Role'
												value={role}
												ref={roleRef}
												onFocus={() => setRoleFocus(true)}
												onBlur={() => setRoleFocus(false)}
												aria-invalid={validRole ? 'false' : 'true'}
												id='role'
												onChange={(selectedOption) => setRole(selectedOption)}
												type='text'
												required
												options={roleOptions}
												classNamePrefix='rfSelectSingle'
											/>
											<FontAwesomeIcon icon={faCheck} className={validRole ? 'valid' : 'hide'} />
											<FontAwesomeIcon
												icon={faExclamation}
												className={validRole || !role ? 'hide' : 'invalid'}
											/>
										</div>
										<p
											id='roleNote'
											className={roleFocus && role && !validRole ? 'instructions' : 'offscreen'}>
											<span>Select a valid role</span>
										</p>

										<div className='tooltip-container'>
											<img
												src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
												className='tooltip-icon'
												style={{ position: 'absolute', marginTop: '50px', marginLeft: '15px'}}
											/>
											<span 
												className='tooltiptext'
												style={{ position: 'absolute', marginTop: '50px'}}
											>This is the wider sector, you’ll be able to pick the sub-sector (i.e. HR, marketing, engineering, legal etc. during the sign up process)</span>
										</div>
										<div className='input-live-val-wrapper'>
											<Select
												menuPortalTarget={document.body}
												menuPosition={'fixed'}
												placeholder='Industry'
												value={industry}
												ref={industryRef}
												onFocus={() => setIndustryFocus(true)}
												onBlur={() => setIndustryFocus(false)}
												aria-invalid={validIndustry ? 'false' : 'true'}
												id='industry'
												onChange={(selectedOption) => setIndustry(selectedOption)}
												type='text'
												required
												options={industryOptions}
												classNamePrefix='rfSelectSingle'
											/>
											<FontAwesomeIcon icon={faCheck} className={validIndustry ? 'valid' : 'hide'} />
											<FontAwesomeIcon
												icon={faExclamation}
												className={validIndustry || !industry ? 'hide' : 'invalid'}
											/>
										</div>

										<p
											id='industryRef'
											className={industryFocus && industry && !validIndustry ? 'instructions' : 'offscreen'}>
											<span>Please select a Industry</span>
										</p>

										{continueRegistration ? (
											<>
												<div className='input-live-val-wrapper'>
													<input
														type='password'
														id='password'
														placeholder='Set Password'
														className=''
														onChange={(e) => setPwd(e.target.value)}
														value={pwd}
														required
														aria-invalid={validPwd ? 'false' : 'true'}
														aria-describedby='pwdnote'
														onFocus={() => setPwdFocus(true)}
														onBlur={() => setPwdFocus(false)}
													/>

													<FontAwesomeIcon icon={faCheck} className={validPwd ? 'valid' : 'hide'} />
													<FontAwesomeIcon
														icon={faExclamation}
														className={validPwd || !pwd ? 'hide' : 'invalid'}
													/>
												</div>

												<p id='pwdnote' className={pwdFocus && !validPwd ? 'instructions' : 'offscreen'}>
													<span>
														8 to 24 characters.
														<br />
														<br />
														Must include uppercase and lowercase letters, a number and a special character.
														<br />
														<br />
														Allowed special characters: <span aria-label='exclamation mark'>!</span>{' '}
														<span aria-label='at symbol'>@</span> <span aria-label='hashtag'>#</span>{' '}
														<span aria-label='dollar sign'>$</span> <span aria-label='percent'>%</span>
													</span>
												</p>

												<div className='input-live-val-wrapper'>
													<input
														type='password'
														id='confirm_pwd'
														placeholder='Confirm Password'
														className='flex-grow mt-3'
														onChange={(e) => setMatchPwd(e.target.value)}
														value={matchPwd}
														required
														aria-invalid={validMatch ? 'false' : 'true'}
														aria-describedby='confirmnote'
														onFocus={() => setMatchFocus(true)}
														onBlur={() => setMatchFocus(false)}
													/>
													<FontAwesomeIcon
														icon={faCheck}
														className={validMatch && matchPwd ? 'valid' : 'hide'}
													/>
													<FontAwesomeIcon
														icon={faExclamation}
														className={validMatch || !matchPwd ? 'hide' : 'invalid'}
													/>
												</div>
												<p
													id='confirmnote'
													className={matchFocus && !validMatch ? 'instructions' : 'offscreen'}>
													<span>Must match the first password input field.</span>
												</p>

												<div className='checkbox'>
													<label>{role.value == 3002 ? <>I agree to <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/terms`} target='_blank'>terms of service</a>, <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/privacy`} target='_blank'>privacy policy</a> and <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/${terms}`} target='_blank'>subscription agreement</a></> : <>I agree to <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/terms`} target='_blank'>terms of service</a> and <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/privacy`} target='_blank'>privacy policy</a></>}</label>
													<input type='checkbox' checked={GDPR} onChange={handleGDPR} />
												</div>

												<p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive"><span>{errMsg}</span></p>



												<button
													id='registerButton'
													className='btn btn-lg btn-secondary align-self-center'
													disabled={!validName || !validPwd || !validMatch ? true : false || !validGDPR}>
													Register
												</button>
												
											</>
										) : (
											<></>
										)}

										{registerInterest ? (
											// REGISTER INTEREST
											<>
												{validName && validRole && validIndustry ? (
													<div className='checkbox'>
														<label>
															Unfortunately, we`ll be launching Recfindr in Finance first (start-up and all
															that). However, we`ll drop you an email when your Industry is available.
														</label>
														<input type='checkbox' checked={GDPR} onChange={handleGDPR} />
													</div>
												) : (
													''
												)}

												<button
													type='button'
													id='registerButton'
													className={`btn btn-lg btn-secondary align-self-center ${
														!registerInterest ||
														!validName ||
														!validRole ||
														!validGDPR ||
														validIndustryForPreReg ||
														industry === 'Select'
															? 'disabled'
															: ''
													}`}
													disabled={
														!registerInterest ||
														!validName ||
														!validRole ||
														validIndustryForPreReg ||
														industry === 'Select'
															? true
															: false
													}
													onClick={handleRegisterInterest}>
													Continue
												</button>
											</>
										) : (
											<></>
											// CONTINUE REGISTRATION
											//<button type="button" id="registerButton" className={`btn btn-lg btn-secondary align-self-center ${!validName || !validRole || validIndustry ? "disabled" : ""}`} disabled={!validName || !validRole || validIndustry ? true : false} onClick={handleRegisterInterest}>Continue RI ?!?!?!</button>
										)}
									</form>
								</div>

								<div className='col-md-12 text-center'>
									<p>
										Already registered?{' '}
										<Link to='/login' className='hfont'>
											Login
										</Link>
									</p>
									<p>
										<Link to='/recover' className='hfont'>
											Recover Password
										</Link>
									</p>
								</div>
							</div>
						</div>
					</section>
					<div className='rf-bg-four d-sm-none d-md-block'></div>
				</div>
			)}
		</>
	);
};

export default Register;
