//import { useParams } from 'react-router-dom';
import ClientHeader from "./client-components/ClientHeader";
import ClientSearch from "./client-components/ClientSearch.js";



const NewRecruiters = () => {

    return (
        <>
            <ClientHeader />
            <ClientSearch />
        </>
    )

}

export default NewRecruiters