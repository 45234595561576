import React from 'react';

const HeartIcon = (props) => (
  <svg
  {...props}
  className="heart-icon"
  viewBox="467 392 58 57"
  xmlns="http://www.w3.org/2000/svg"
>
  <g
    className="heart-group"
    fill="none"
    fillRule="evenodd"
    transform="translate(467 392)"
  >
    <g
    fill="none"
    fillRule="evenodd"        transform="translate(467 392)"

  >
    <path
        className="heart"
      fill="var(--color-grey)"
      d="M-423.1-366.1c0.5-0.5,0.2-1.3-0.5-1.4l-8.8-1.5c-0.3,0-0.5-0.2-0.6-0.4l-4.2-7.9c-0.3-0.6-1.2-0.6-1.5,0 l-4.2,7.9c-0.1,0.2-0.4,0.4-0.6,0.4l-8.8,1.5c-0.7,0.1-1,1-0.5,1.4l6.3,6.4c0.2,0.2,0.3,0.5,0.2,0.7l-1.3,8.9 c-0.1,0.7,0.6,1.2,1.2,0.9l8.1-4c0.2-0.1,0.5-0.1,0.8,0l8.1,4c0.6,0.3,1.3-0.2,1.2-0.9l-1.3-8.9c0-0.3,0-0.5,0.2-0.7L-423.1-366.1z "

    />
    </g>
    <circle
      className="heart-main-circ"
      fill="var(--color-light-grey)"
      opacity={0}
      cx={29.5}
      cy={29.5}
      r={1.5}
    />
    <g className="heart-grp7" opacity={0} transform="translate(7 6)">
      <circle
        className="oval1"
        fill="var(--color-primary)"
        cx={2}
        cy={6}
        r={2}
      />
      <circle
        className="oval2"
        fill="var(--color-primary-hover)"
        cx={5}
        cy={2}
        r={2}
      />
    </g>
    <g className="heart-grp6" opacity={0} transform="translate(0 28)">
      <circle
        className="oval1"
        fill="var(--color-tertiary)"
        cx={2}
        cy={7}
        r={2}
      />
      <circle
        className="oval2"
        fill="var(--color-tertiary-hover)"
        cx={3}
        cy={2}
        r={2}
      />
    </g>
    <g className="heart-grp3" opacity={0} transform="translate(52 28)">
      <circle
        className="oval2"
        fill="var(--color-secondary)"
        cx={2}
        cy={7}
        r={2}
      />
      <circle
        className="oval1"
        fill="var(--color-secondary-hover)"
        cx={4}
        cy={2}
        r={2}
      />
    </g>
    <g className="heart-grp2" opacity={0} transform="translate(44 6)">
      <circle
        className="oval2"
        fill="var(--color-primary)"
        cx={5}
        cy={6}
        r={2}
      />
      <circle
        className="oval1"
        fill="var(--color-primary)"
        cx={2}
        cy={2}
        r={2}
      />
    </g>
    <g className="heart-grp5" opacity={0} transform="translate(14 50)">
      <circle
        className="oval1"
        fill="var(--color-tertiary)"
        cx={6}
        cy={5}
        r={2}
      />
      <circle
        className="oval2"
        fill="var(--color-tertiary)"
        cx={2}
        cy={2}
        r={2}
      />
    </g>
    <g className="heart-grp4" opacity={0} transform="translate(35 50)">
      <circle
        className="oval1"
        fill="var(--color-primary)"
        cx={6}
        cy={5}
        r={2}
      />
      <circle
        className="oval2"
        fill="var(--color-primary)"
        cx={2}
        cy={2}
        r={2}
      />
    </g>
    <g className="heart-grp1" opacity={0} transform="translate(24)">
      <circle
        className="oval1"
        fill="var(--color-tertiary)"
        cx={2.5}
        cy={3}
        r={2}
      />
      <circle
        className="oval2"
        fill="var(--color-tertiary)"
        cx={7.5}
        cy={2}
        r={2}
      />
    </g>
  </g>
</svg>
)
export default HeartIcon