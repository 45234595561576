import React, { useState, useEffect } from 'react';

const HideNotificationBar = ({ storageKey, children }) => {
    const [visible, setVisible] = useState(true);
    
    useEffect(() => {
        if (localStorage.getItem(storageKey)) {
            setVisible(false);
        }
    }, [storageKey]);
    
    const handleHide = () => {
        localStorage.setItem(storageKey, 'hidden');
        setVisible(false);
    };

    if (!visible) return null;

    return React.cloneElement(children, { onClick: handleHide });
}

export default HideNotificationBar;