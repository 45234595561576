import React from 'react';

const PlayIcon = (props) => (

    <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="play-icon"
    viewBox="0 0 16 16"
  >
    <path
      d="M10.4 7.2c.6.4.6 1.3 0 1.7L7.2 11c-.7.4-1.6 0-1.6-.8V5.9c0-.8.9-1.3 1.6-.8l3.2 2.1z"
      style={{
        fill: "var(--color-primary)",
      }}
    />
    <path
      d="M8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8zm0 1.6C4.5 1.6 1.6 4.5 1.6 8c0 3.5 2.9 6.4 6.4 6.4 3.5 0 6.4-2.9 6.4-6.4 0-3.5-2.9-6.4-6.4-6.4"
      style={{
        fill: "var(--color-primary)",
      }}
    />
  </svg>

)
export default PlayIcon