import React, { useState, useEffect } from 'react';
//const stripe = require('stripe')('sk_test_51MVzPmINZexzOVHwRQ7UQf0EzKQJXzMKOCOi5QzAVBDppzWt8kKNbKtP6bPmd6EGSr9lI7cMcC7qtVYUeGtvLoqE00MCE7qM0r');
import useAxiosPrivate from "../../hooks/useAxiosPrivate";


const RecruiterSubscribe = () => {
  const axiosPrivate = useAxiosPrivate(); 

return (

<section>
  <div className="product">
    <div className="description">
      <h3>Starter plan</h3>
      <h5>$20.00 / month</h5>
    </div>
  </div>
  <form action={process.env.REACT_APP_RECFINDR_API+'/api/subscribe'} method="POST">
    <input type="hidden" name="lookup_key" value="10" />
    <input name="customerid" id="customerid" value="cus_NM8zvyfSBBcutT"></input>
    <button id="checkout-and-portal-button" type="submit">Checkout</button>
  </form>
</section>

    )
}

export default RecruiterSubscribe