import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    // Check if the current route is the pricing page
    const isPricingPage = location.pathname === '/pricing';

    // Function to check if user has any of the allowed roles
    const userHasAllowedRole = auth?.role?.some(role => allowedRoles.includes(role));

    // Redirect logic for pricing page
    if (isPricingPage) {
        if (userHasAllowedRole) {
            // Authenticated but not authorized for pricing, redirect to upgrade
            return <Navigate to="/upgrade" state={{ from: location }} replace />;
        }
        // Allow unauthenticated users or authenticated with proper role
        return <Outlet />;
    }

    // Existing logic for other pages
    return (
        userHasAllowedRole
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;
