import React from 'react';

const PaintRollerIcon = (props) => (

  <svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  height="1em"
  viewBox="0 0 512 512"
>
  <path
    fill="var(--color-black)"
    d="M368 48H48v96h320V48zM48 0h368v192H0V0h48zm200 320h40v192H160V320h40v-88h264V120h-16V72h64v208H248v40z"
  />
</svg>

)
export default PaintRollerIcon