import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Autocomplete from 'react-google-autocomplete';
import FilesUploadComponent from '../FileUploads';
import BackgroundFilesUploadComponent from '../FileUploadsBackground';
import AssignmentForm from '../Assignments';
import Carousel from 'react-bootstrap/Carousel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import $ from 'jquery';

import PersonalQuestionsAccordion from '../recruiters-components/PersonalQuestionsAccordion';
import ProfessionalQuestionsAccordion from '../recruiters-components/ProfessionalQuestionsAccordion';

import AutoSuggestCompany from '../AutoSuggestCompany';
import IndustrySelect from '../industrySelects';
import EmploymentSelect from '../employmentSelects';
import Pricing from '../Pricing';
import industryMaster from '../industryMaster.json';

import LocationIcon from '../icons/LocationIcon';
import useAuth from '../../hooks/useAuth';
import { set } from 'lodash';

const RecruiterAccountWelcome = ({ props, onAnswersChange }) => {
	const { auth } = useAuth();

	const { updateProfileComplete } = useAuth();

	const [isLoading, setLoading] = useState(true);
	const [input, setInput] = useState('');
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	const [showPlans, setShowPlans] = useState(false);
	const [errorMessagePersonal, setErrorMessagePersonal] = useState('');
	const [errorMessageRecruitment, setErrorMessageRecruitment] = useState('');
	const [panelMessage, setPanelMessage] = useState(1);
	const [carouselDone, setCarouselDone] = useState(false);

	const [personalAnswers, setPersonalAnswers] = useState({});
	const [professionalAnswers, setProfessionalAnswers] = useState({});

	const [validateFirstName, setValidateFirstName] = useState();
	const [validateLastName, setValidateLastName] = useState();
	const [validateJobTitle, setValidateJobTitle] = useState();
	const [validateIndustry, setValidateIndustry] = useState();
	const [validateSector, setValidateSector] = useState();
	const [validateSubSector, setValidateSubSector] = useState();
	const [validateEmployment, setValidateEmployment] = useState();
	const [validateCompany, setValidateCompany] = useState();
	const [validateLocation1, setValidateLocation1] = useState();
	const [validateLocation2, setValidateLocation2] = useState();
	const [validateUsername, setValidateUsername] = useState();
	const [validatePhoneNumber, setValidatePhoneNumber] = useState();
	const [validateBiography, setValidateBiography] = useState();
	const [validatePersonalQuestions, setValidatePersonalQuestions] = useState();
	const [validateRecruitmentQuestions, setValidateRecruitmentQuestions] = useState();
	const [bioLength, setBioLength] = useState(0);
	const [intent, setIntent] = useState();

	const [profileComplete, setProfileComplete] = useState(false);

	/** COUNTERS FOR PERSONAL AND RECRUITMENT QUESTIONS */

	//const personalItems = 11; // Replace this with your actual number of items
	//const [personalIndex, setPersonalIndex] = useState(0);

	//const handlePersonalSelect = (personalIndex, e) => {
	//  setPersonalIndex(personalIndex);
	//};

	//const recruitmentItems = 14; // Replace this with your actual number of items
	//const [recruitmentIndex, setRecruitmentIndex] = useState(0);

	// const handleRecruitmentSelect = (recruitmentIndex, e) => {
	//  setRecruitmentIndex(recruitmentIndex);
	//};
	/*
	const initialValues = {
		username: "",
		role: "",
		first_name: "",
		last_name: "",
		profile_picture: "",
		job_title: "",
		calendly_username: "",
		website_url: "",
		phone_number: "",
		company: "",
		location_1: {
			name: "",
			coordinates: "",
		},
		location_2: {
			name: "",
			coordinates: "",
		},
		industry_1: "",
		industry_2: "",
		sector: "",
		sub_sector: "",
		biography: "",
		past_assignment_job_title: "",
		past_assignment_location: "",
		past_assignment_salary: "",
		active_roles_job_title: "",
		active_roles_salary: "",
		video: "",

	};
*/
	//const [ test, setTest ] = useState(initialValues);
	const [profile, setProfile] = useState();

	// START FORM
	function handleChange(e) {
		//console.log(e.target.value)
		//console.log(e.target.id)
		var textOnly = /^[A-Za-z]+$/;
		if (e.target.id === 'first_name') {
			var input = document.getElementById(e.target.id).value;
			if (textOnly.test(input)) {
				// The input is valid, proceed with further processing
				console.log('1');
			} else {
				// The input is invalid, show an error message or take appropriate action
				console.log('0');
			}
		}

		if (e.target.classList.contains('required')) {
			if (!e.target.value == '') {
				e.target.classList.remove('invalid');
			} else {
				e.target.classList.add('invalid');
			}
		}
		const { name, value } = e.target;
		setProfile({
			...profile,
			[name]: value,
		});
	}
/*
	function handlePersonalQuestions(e) {
		const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\/[\w.-]*)*$/;
		const phoneRegex = /^(?!(?:07\d{8}|0300\d{7}|0800\d{7}|44\d{9}))(?:\+\d{1,3}\s)?\(?(?:0\d{1,4})\)?[\s.-]?\d{1,5}[\s.-]?\d{1,5}[\s.-]?\d{1,5}$/;

		//console.log(e.target.value);

		if (emailRegex.test(e.target.value)) {
			setErrorMessagePersonal('Email addresses are not allowed.');
		} else if (urlRegex.test(e.target.value)) {
			setErrorMessagePersonal('Website URLs are not allowed.');
		} else if (phoneRegex.test(e.target.value)) {
			setErrorMessagePersonal('Phone numbers are not allowed.');
		} else {
			setErrorMessagePersonal('');
			const { name, value } = e.target;
			var cleanValue = value.trim();
			setProfile({
				...profile,
				questions: {
					...profile.questions,
					personal: {
						...profile.questions.personal,
						[name]: cleanValue,
					},
				},
			});
		}
	}

	function handleRecruitmentQuestions(e) {
		const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\/[\w.-]*)*$/;
		const phoneRegex = /^(?!(?:07\d{8}|0300\d{7}|0800\d{7}|44\d{9}))(?:\+\d{1,3}\s)?\(?(?:0\d{1,4})\)?[\s.-]?\d{1,5}[\s.-]?\d{1,5}[\s.-]?\d{1,5}$/;

		//console.log(e.target.value)
		if (emailRegex.test(e.target.value)) {
			setErrorMessageRecruitment('Email addresses are not allowed.');
		} else if (urlRegex.test(e.target.value)) {
			setErrorMessageRecruitment('Website URLs are not allowed.');
		} else if (phoneRegex.test(e.target.value)) {
			setErrorMessageRecruitment('Phone numbers are not allowed.');
		} else {
			setErrorMessageRecruitment('');
			const { name, value } = e.target;
			var cleanValue = value.trim();
			setProfile({
				...profile,
				questions: {
					...profile.questions,
					recruitment: {
						...profile.questions.recruitment,
						[name]: cleanValue,
					},
				},
			});
		}
	}
*/
	function handleSubmit() {
		let updateURL;
		updateURL = '/api/recruiters/';

		const data = JSON.stringify(profile);

		const upProfile = async () => {
			try {
				const res = await axiosPrivate.put(updateURL, data);
			} catch (err) {
				console.log(err);
			}
		};
		upProfile();
	}

	const uuid = sessionStorage.getItem('uuid');

	const profileURL = '/api/profile/';

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const getProfile = async () => {
			try {
				const response = await axiosPrivate.get(profileURL, {
					single: controller.signal,
				});
				//console.log(response.data);
				var profiledata = JSON.parse(response.data);

				const setValues = {
					id: profiledata && profiledata.id,
					username: profiledata && profiledata.username,
					role: profiledata && profiledata.role,
					first_name: profiledata && profiledata.first_name,
					last_name: profiledata && profiledata.last_name,
					profile_picture: profiledata && profiledata.profile_picture,
					background_picture: profiledata && profiledata.background_picture,
					job_title: profiledata && profiledata.job_title,
					calendly_username: profiledata && profiledata.calendly_username,
					website_url: profiledata && profiledata.website_url,
					phone_number: profiledata && profiledata.phone_number,
					company: profiledata && profiledata.company,
					questions: {
						personal: profiledata && profiledata.questions && profiledata.questions.personal,
						recruitment: profiledata && profiledata.questions && profiledata.questions.recruitment,
					},
					location_1: {
						name: profiledata && profiledata.location_1 && profiledata.location_1.name,
						coordinates: profiledata && profiledata.location_1 && profiledata.location_1.coordinates,
					},
					location_2: {
						name: profiledata && profiledata.location_2 && profiledata.location_2.name,
						coordinates: profiledata && profiledata.location_2 && profiledata.location_2.coordinates,
					},
					industry_1: profiledata && profiledata.industry_1,
					sector: profiledata && profiledata.sector,
					sub_sector: profiledata && profiledata.sub_sector,
					permanent: profiledata && profiledata.permanent,
					contract: profiledata && profiledata.contract,
					biography: profiledata && profiledata.biography,
					video: profiledata && profiledata.video,
					profile_complete: false,
					stripe: {
						activeSubscription: profiledata && profiledata.stripe && profiledata.stripe.activeSubscription,
					},
					paid: profiledata && profiledata.paid,
					intent: profiledata && profiledata.intent,
				};

				if(profiledata && profiledata.profile_complete) {
					setProfileComplete(profiledata.profile_complete);
				}
				if(profiledata && profiledata.biography) {
					setBioLength(profiledata.biography.length);
				}

				if (profiledata && profiledata.questions) {
					setPersonalAnswers(profiledata?.questions?.personal);
					setProfessionalAnswers(profiledata?.questions?.recruitment);
				}

				if(profiledata.profile_complete){
					setProfileComplete(true);
				}

				if (profiledata?.paid === false && profiledata?.intent?.item && profiledata?.intent?.fulfilled === false) {
					setIntent(profiledata?.intent);
					console.log('Intent:', profiledata?.intent);
				}

				isMounted && setProfile(setValues);
				//console.log(profile)
				setLoading(false);
			} catch (err) {
				console.log(err);
			} finally {
			}
		};
		getProfile();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	/* Start Populates Select Boxes */

	const industriesData = industryMaster;
	const [industryInput, setIndustryInput] = useState('');

	const [industryOptions, setIndustryOptions] = useState([]);

	useEffect(() => {
		// Populate industry dropdown options
		const industries = industriesData.industries.map((industry) => (
			<option key={industry.name} value={industry.name}>
				{industry.name}
			</option>
		));
		setIndustryOptions(industries);
	}, []);

	/* End Populates Select Boxes */

	/* Start personalAnswers UseEffect to update profile state */
	useEffect(() => {
		// This effect will run whenever `personalAnswers` changes.
		if (profile && profile.questions && profile.questions.personal) {
			const updateProfileWithPersonalAnswers = () => {
				setProfile((currentProfile) => ({
					...currentProfile,
					questions: {
						...currentProfile.questions,
						personal: {
							...currentProfile.questions.personal,
							...personalAnswers,
						},
					},
				}));
			};

			if (Object.keys(personalAnswers).length > 0) {
				updateProfileWithPersonalAnswers();
			}
		}
	}, [personalAnswers]);
	/* end personalAnswers UseEffect to update profile state */

	/* Start personalAnswers UseEffect to update profile state */
	useEffect(() => {
		// This effect will run whenever `professionalAnswers` changes.
		if (profile && profile.questions && profile.questions.recruitment) {
			const updateProfileWithprofessionalAnswers = () => {
				setProfile((currentProfile) => ({
					...currentProfile,
					questions: {
						...currentProfile.questions,
						recruitment: {
							...currentProfile.questions.recruitment,
							...professionalAnswers,
						},
					},
				}));
			};

			if (Object.keys(professionalAnswers).length > 0) {
				updateProfileWithprofessionalAnswers();
			}
		}
	}, [professionalAnswers]);
	/* end professionalAnswers UseEffect to update profile state */

	// Function to validate the Vidyard URL
	function validateVidyardURL(event) {
		console.log(event.target.value);
		let url = event.target.value;
		// const urlPattern = /^https?:\/\/share\.vidyard\.com\/watch\/(\w+)/;
		// const match = url.match(urlPattern);
		// if (!match) {
		// 	return false;
		// }

		// Extract the video ID from the URL
		// const videoId = match[1];
		// console.log(videoId);

		setProfile({
			...profile,
			video: url,
		});

		// Return the extracted video ID if the URL is valid
		return url;
	}

	function handleCalendly(event) {
		const inputValue = event.target.value;
		var calendlyUsername;
		// Check if the input value contains the full Calendly URL
		if (inputValue.includes('https://calendly.com/')) {
			const domain = 'https://calendly.com/';
			const username = inputValue.replace(domain, '');

			//console.log("Username:", username);
			var calendlyUsername = username;
		} else {
			var calendlyUsername = event.target.value;
		}

		console.log(calendlyUsername);

		setProfile({
			...profile,
			calendly_username: calendlyUsername,
		});

		return calendlyUsername;
	}

	const bsCarousel = useRef(null);

	const totalItems = 7; // Replace this with your actual number of items
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	const handleClick = () => {
		let nextIndex = (index + 1) % totalItems;
		setIndex(nextIndex);
	};

	// Calculate progress as a percentage
	const progress = ((index + 1) / totalItems) * 100;

	function checkItem() {
		console.log(profile);
		setTimeout(function () {
			if ($('.profileFields .about').hasClass('active') === true) {
				let element = document.getElementById('profileBack');
				element.classList.add('d-none');
			} else {
				let element = document.getElementById('profileBack');
				element.classList.remove('d-none');
			}

			if ($('.profileFields .history').hasClass('active') === true) {
				let elementNext = document.getElementById('profileNext');
				elementNext.classList.add('d-none');
				let elementBack = document.getElementById('profileBack');
				elementBack.classList.add('d-none');
				let elementFinish = document.getElementById('profileFinish');
				elementFinish.classList.remove('d-none');
			}
		}, 1000);
	}
	checkItem();

	const handleSelectionChange = (selectedOptions) => {
		console.log('Selection changed:', selectedOptions);
		// Here you can update the state of the parent component based on the selected options
		const updatedProfile = {
			...profile,
			permanent: selectedOptions.some((option) => option.value === 'permanent'),
			contract: selectedOptions.some((option) => option.value === 'contract'),
		};
		setProfile(updatedProfile);
	};

	const finishProfile = async () => {
		setProfile((prevState) => ({
			...prevState,
			profile_complete: true,
		}));
	};

	useEffect(() => {
		if (profile && profile.profile_complete === true) {
			const sendData = async () => {
				try {
					const data = JSON.stringify(profile);
					const res = await axiosPrivate.put('/api/recruiters/', data);
					console.log(res);
				} catch (err) {
					console.log(err);
				} finally {
					updateProfileComplete(true);
					navigate('/dashboard');
				}
			};

			sendData();
		}
	}, [profile]); // Depend on the entire profile object



	const toggleClick = (text) => (event) => {
		window.scrollTo({
			top: 80,
			behavior: 'smooth',
		});

		checkItem();

		if ($('.profileFields .about').hasClass('active') === true) {
			console.log('about active');
			setPanelMessage(1);

			var aboutValidator = 9;
			if (!profile['first_name']) {
				console.log('First name is required');
				let element = document.getElementById('first_name');
				element.classList.add('invalid');
				let rect = element.getBoundingClientRect();
				window.scrollTo(rect.left, rect.top);
				setValidateFirstName('First name is required');
				aboutValidator -= 1;
			} else {
				document.getElementById('first_name').classList.remove('invalid');
				setValidateFirstName('');
			}

			if (!profile['last_name']) {
				console.log('Last name is required');
				let element = document.getElementById('last_name');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				setValidateLastName('Last name is required');
				aboutValidator -= 1;
			} else {
				document.getElementById('last_name').classList.remove('invalid');
				setValidateLastName('');
			}

			if (!profile['job_title']) {
				console.log('Last name is required');
				let element = document.getElementById('job_title');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				setValidateJobTitle('Job title is required');
				aboutValidator -= 1;
			} else {
				document.getElementById('job_title').classList.remove('invalid');
				setValidateJobTitle('');
			}

			if (!profile['industry_1'] || !profile['industry_1'] === '' || profile.industry_1.length === 0) {
				console.log('At least one industry is required');
				let element = document.getElementById('industry_1');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				const targetElement = document.getElementById('industry_1');
				const labelElement = targetElement.previousElementSibling;

				if (targetElement.classList.contains('invalid')) {
					labelElement.classList.add('error');
				}
				setValidateIndustry('At least one industry is required');
				aboutValidator -= 1;
			} else {
				let element = document.getElementById('industry_1');
				element.classList.remove('invalid');
				const targetElement = document.getElementById('industry_1');
				const labelElement = targetElement.previousElementSibling;
				setValidateIndustry('');
				if (targetElement.classList.contains('error')) {
					labelElement.classList.remove('error');
					setValidateIndustry('');
				}
			}

			if (!profile['sector'] || !profile['sector'] === '' || profile.sector.length === 0) {
				console.log('Sector is required');
				setValidateSector('Sector is required');
				let element = document.getElementById('sector');
				element.classList.add('invalid');

				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				aboutValidator -= 1;
			} else {
				setValidateSector('');
			}

			if (!profile['sub_sector'] || !profile['sub_sector'] === '' || profile.sub_sector.length === 0) {
				//alert('Subsector is required')
				console.log('Subsector is required');
				setValidateSubSector('Subsector is required. Free users can only pick 1 Subsector');
				let element = document.getElementById('sub_sector');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				const targetElement = document.getElementById('sub_sector');
				const labelElement = targetElement.previousElementSibling;

				aboutValidator -= 1;
			} else {
				setValidateSubSector('');
			}

			if (!profile['company']) {
				console.log('Company name is required');
				let element = document.getElementById('company');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				aboutValidator -= 1;
				setValidateCompany('Company name is required');
			} else {
				document.getElementById('company').classList.remove('invalid');
				setValidateCompany('');
			}

			if (!profile['permanent'] && !profile['contract']) {
				console.log('Please select at least one employment type');
				setValidateEmployment('Please select at least one employment type');
				let element = document.getElementById('employment_type');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				aboutValidator -= 1;
			} else {
				setValidateEmployment('');
			}

			if (!profile['location_1']['name'] || !profile['location_1']['coordinates'] === '') {
				console.log('At least one location is required');
				let element = document.getElementById('location_1');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				aboutValidator -= 1;
				setValidateLocation1('At least one location is required');
			} else {
				document.getElementById('location_1').classList.remove('invalid');
				setValidateLocation1('');
			}

			console.log(aboutValidator);

			if (aboutValidator === 9) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(2);
				} else if (text == 'back') {
					bsCarousel.current.prev();
					//setPanelMessage(1)
				}
			}
		} else if ($('.profileFields .contactInfo').hasClass('active') === true) {
			console.log('contact active');

			const USER_REGEX =
				/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

			var contactValidator = 2;
			if (!profile['username']) {
				console.log('Email is required');
				let element = document.getElementById('username');
				element.classList.add('invalid');
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
				contactValidator -= 1;
				setValidateUsername('Username / Email is required');
			} else if (USER_REGEX.test(profile['username'].toLowerCase()) === false) {
				console.log('Email is incorrect format');
				let element = document.getElementById('username');
				element.classList.add('invalid');
				setValidateUsername('');
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
				contactValidator -= 1;
			}

			if (!profile['phone_number']) {
				console.log('Phone number is required');
				let element = document.getElementById('phone_number');
				element.classList.add('invalid');
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
				contactValidator -= 1;
				setValidatePhoneNumber('Phone number is required');
			} else {
				document.getElementById('phone_number').classList.remove('invalid');
				setValidatePhoneNumber('');
			}

			console.log(contactValidator);
			if (contactValidator === 2) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(3);
				} else if (text == 'back') {
					bsCarousel.current.prev();
					setPanelMessage(1);
				}
			}
		} else if ($('.profileFields .biography').hasClass('active') === true) {
			console.log('biography active');

			var biographyValidator = 1;

			if (!profile['biography']) {
				console.log('Biography is required');
				let element = document.getElementById('biography');
				element.classList.add('invalid');
				/*element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});*/
				biographyValidator -= 1;
				setValidateBiography('Please provide a biography');
			} else {
				document.getElementById('biography').classList.remove('invalid');
				setValidateBiography('');
			}

			console.log(biographyValidator);
			if (biographyValidator === 1) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(3);
				} else if (text == 'back') {
					bsCarousel.current.prev();
					setPanelMessage(1);
				}
			}
		} else if ($('.profileFields .personalQs').hasClass('active') === true) {
			console.log('personalQs active');

			//var personalValidator = 3;

			// VALIDATES THE PERSONAL QUESTIONS
			function validateProfileQuestions(profile) {
				const personalQuestions = personalAnswers;
				const personalQuestionKeys = Object.keys(personalQuestions);

				// Check if there are at least 3 personal questions
				if (personalQuestionKeys.length < 3) {
					return false;
				}

				let validCount = 0;

				for (let key of personalQuestionKeys) {
					const question = personalQuestions[key].trim();
					//console.log(question)

					// Check if question length is greater than or equal to 10
					if (question.length < 10) {
						setErrorMessagePersonal('Answers must be more than 10 characters: ' + question);
						continue;
					}

					// Regular expressions to check for domain, email, and phone number
					const domainRegex = /\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z0-9]{2,}\b/;
					const emailRegex = /\S+@\S+\.\S+/;
					const phoneRegex = /\d{5,}/;

					// Check if question includes a domain, email, or phone number
					if (domainRegex.test(question) || emailRegex.test(question) || phoneRegex.test(question)) {
						console.log('Invalid question: ' + question);
						setErrorMessagePersonal('Answers must not contain contact information or domains: ' + question);
						continue;
					}
					// If question is valid, increment valid count
					validCount++;

					// If we have found 3 valid questions, return true
					if (validCount == 3) {
						return true;
					}
				}

				// If less than 3 valid questions were found, return false
				return false;
			}
			// ENDS VALIDATION OF PERSONAL QUESTIONS

			//console.log(personalValidator)
			if (validateProfileQuestions(profile) === true) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(5);
				} else if (text == 'back') {
					bsCarousel.current.prev();
					setPanelMessage(3);
				}
				setValidatePersonalQuestions('');
			} else {
				setValidatePersonalQuestions('At least 3 personal questions required');
			}
		} else if ($('.profileFields .recruitmentQs').hasClass('active') === true) {
			console.log('recruitmentQs active');

			//var recruitmentValidator = 1;

			// VALIDATES THE RECRUITMENT QUESTIONS
			function validateProfileRecruitmentQuestions(profile) {
				const recruitmentQuestions = professionalAnswers;
				const recruitmentQuestionKeys = Object.keys(recruitmentQuestions);

				// Check if there are at least 3 recruitment questions
				if (recruitmentQuestionKeys.length < 3) {
					return false;
				}

				let validCount = 0;

				for (let key of recruitmentQuestionKeys) {
					const question = recruitmentQuestions[key].trim();
					//console.log(question)

					// Check if question length is greater than or equal to 10
					if (question.length < 10) {
						setErrorMessagePersonal('Answers must be more than 10 characters: ' + question);
						continue;
					}

					// Regular expressions to check for domain, email, and phone number
					const domainRegex = /\b(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z0-9]{2,}\b/;
					const emailRegex = /\S+@\S+\.\S+/;
					const phoneRegex = /\d{5,}/;

					// Check if question includes a domain, email, or phone number
					if (domainRegex.test(question) || emailRegex.test(question) || phoneRegex.test(question)) {
						console.log('Invalid question: ' + question);
						setErrorMessagePersonal('Answers must not contain contact information or domains: ' + question);
						continue;
					}
					// If question is valid, increment valid count
					validCount++;

					// If we have found 3 valid questions, return true
					if (validCount == 3) {
						return true;
					}
				}

				// If less than 3 valid questions were found, return false
				return false;
			}
			// ENDS VALIDATION OF RECRUITMENT QUESTIONS

			//console.log(recruitmentValidator)
			if (validateProfileRecruitmentQuestions(profile) === true) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(6);
				} else if (text == 'back') {
					bsCarousel.current.prev();
					setPanelMessage(4);
				}
				setValidateRecruitmentQuestions('');
			} else {
				setValidateRecruitmentQuestions('At least 3 professonal questions required');
			}
		} else if ($('.profileFields .location').hasClass('active') === true) {
			console.log('location active');
			var locationValidator = 1;

			console.log(locationValidator);
			if (locationValidator === 1) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(7);
				} else if (text === 'back') {
					bsCarousel.current.prev();
					setPanelMessage(3);
				}
			}
		} else if ($('.profileFields .pastAssignments').hasClass('active') === true) {
			console.log('pastAssignmentss active');
			var pastAssignmentsValidator = 1;

			console.log(pastAssignmentsValidator);
			if (pastAssignmentsValidator === 1) {
				handleSubmit();
				if (text == 'next') {
					bsCarousel.current.next();
					setPanelMessage(8);
					setCarouselDone(true);
					const profileCarousel = document.getElementById('profileCarousel');
					//profileCarousel.classList.add('offset-md-2');
					profileCarousel.classList.remove('col-md-7');
					profileCarousel.classList.remove('offset-md-2');
					profileCarousel.classList.add('col-md-10');
					profileCarousel.classList.add('offset-md-1');
					profileCarousel.classList.add('profileFinish');
					profileCarousel.classList.add('theme-transparent');
					//const finalSlide = document.getElementById('final');
					//finalSlide.classList.add('theme-four');
				} else if (text === 'back') {
					bsCarousel.current.prev();
					setPanelMessage(5);
				}
			}
		} else if ($('.profileFields .history').hasClass('active') === true) {
			console.log('history active');
			var historyValidator = 1;

			if (profile['profile_complete'] === false) {
				alert('Confirmation is required');
				console.log('Confirmation is required');
				let element = document.getElementById('profile_complete_group');
				element.classList.add('invalid');
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
				historyValidator -= 1;
			}
			console.log(historyValidator);
			if (historyValidator === 1) {
				if (text == 'finish') {
					setProfile((prevState) => ({
						...prevState,
						profile_complete: true,
					}));
					finishProfile();
					console.log('trigger');
				}
			}
		}
	};

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}

	const headerProps = {
		loggedIn: true,
		profile_picture: profile?.profile_picture,
	};

	function handleShowPlans(e) {
		e.preventDefault();
		setShowPlans(true);
	}

	function handleShowPlansHide(e) {
		e.preventDefault();
		setShowPlans(false);
	}



	const handleProfileChange = (newProfile) => {
		console.log(newProfile);
		console.log('hit');
		setProfile((prevProfile) => ({
			...prevProfile,
			...newProfile,
		}));
		//console.log(profile)
	};

	const initialIndustry = profile?.industry_1.map((item) => ({
		value: item,
		label: item,
	}));

	const initialSectors = profile?.sector.map((item) => ({
		value: item,
		label: item,
	}));
	const initialSubSectors = profile?.sub_sector.map((item) => ({
		value: item,
		label: item,
	}));

	//console.log({profile})

	const handlePersonalAnswersChange = (newPersonalAnswers) => {
		setPersonalAnswers(newPersonalAnswers);
		//console.log(newPersonalAnswers);
	};

	const handleProfessionalAnswersChange = (newProfessionalAnswers) => {
		setProfessionalAnswers(newProfessionalAnswers);
		//console.log(newProfessionalAnswers);
	};



	const searchParams = window.location.search;
	if (searchParams === '?free=1') {
		var upProfile = async () => {
			var intentData = {
				intent: {
					fulfilled: null,
					plan: null,
					item: null,
					coupon: null,
				},
			};
			try {
				const res = await axiosPrivate.put('/api/recruiters/', intentData);
				setIntent(null);
			} catch (err) {
				console.log(err.response || err);
			}
		};
		upProfile();
	}

	sessionStorage.setItem('recruiterAccount', true);

	return (
		<>
			{/* Start Intent Notification */}
			{intent ? (
				<>
						<div className='notification-bar bg-grey'>
							<div className='container'>
								<div className='col-md-12 d-flex align-items-center justify-content-center'>

									<form
										action={process.env.REACT_APP_RECFINDR_API + '/api/subscribe'}
										method='POST'
										className='intentForm'>
										<input name='plan' id='plan' readOnly hidden value={intent?.plan}></input>
										{profile?.stripeID && (
											<input
												name='stripeID'
												id='stripeID'
												readOnly
												hidden
												defaultValue={profile?.stripeID}></input>
										)}
										{profile?.id && (
											<input name='userId' id='userId' readOnly hidden defaultValue={profile?.id}></input>
										)}
										{profile?.username && (
											<input name='email' id='email' readOnly hidden defaultValue={profile?.username}></input>
										)}
										{profile?.role && (
											<input name='role' id='role' readOnly hidden defaultValue={profile?.role}></input>
										)}
										<input
											className='userQuantity'
											name='quantity'
											type='number'
											id='quantity'
											aria-describedby=''
											min='1'
											defaultValue='1'
											readOnly
											hidden
										/>
										<input name='item' id='item' readOnly hidden value={intent?.item}></input>
										<input name='coupon' id='item' readOnly hidden value={intent?.coupon}></input>
										<div className='row'>
											<div className='col-md-6 text-end'>
												<span className='hfont'>{profileComplete ? <>You pre selected a plan for {intent.plan}</> : <>Welcome aboard, you pre selected a plan for {intent.plan}</>}</span>
												<span className='d-block small'>
													<a href='/upgrade'>Choose a different plan?</a>
												</span>
											</div>
											<div className='col-md-6'>
												<button id='priceSelect' className='btn btn-secondary btn-lg' type='submit'>
													Checkout
												</button>
												<button id='priceSelect' className='btn btn-black btn-lg' onClick={() => setIntent(null)}>
													Cancel
												</button>
											</div>
										</div>
									</form>

								</div>
							</div>
						</div>
				</>
			) : (
				null
			)}
			{/* End Intent Notification */}

			{/* <RecruiterHeader props={headerProps} /> */}
			<div className='app-main-wrapper onboarding-wrapper'>
				<div className='rf-bg-four d-xs-none d-sm-none d-md-block'></div>
				<section className='container-fluid theme-seven'>
					<article className='container' style={{ position: 'relative' }}>
						<section className=''>
							<div className='row'>
								<div className='col-md-12'>
									<ProgressBar
										now={progress}
										label={`${Math.round(progress)}%`}
										className='profileProgressBar'
									/>
								</div>
							</div>
						</section>
						{showPlans ? (
							<>
								<br />
								<button className='' onClick={handleShowPlansHide}>
									Back to Profile
								</button>
								<Pricing />
							</>
						) : (
							<>
								<>
									<div className='row'>
										<div className='col-md-4'>
											{panelMessage === 1 ? (
												<div>
													<h3>Tell us about yourself</h3>
													{validateFirstName || validateLastName || validateJobTitle || validateIndustry || validateSector || validateSubSector || validateCompany || validateEmployment || validateLocation1 ? <div className='errmsg'>Please complete all of the required fields </div> : null}
													<p>
														Congrats, you{"'"}ve just taken the first step to never making a client cold
														again. Imagine that.
													</p>
													<p>
														First thing{"'"}s first, you need to fill out as much information on your profile
														as possible to make it easy for clients to find you. As well as, standing out from
														everyone else.
													</p>
													{profile?.paid ? (
														''
													) : (
														<p>
															As you're currently on a free account some areas won{"'"}t be shown on your
															profile. It{"'"}s still worth filling them in just in case you decide to{' '}
															<Link to='/pricing' style={{ textDecoration: 'underline' }}>
																upgrade
															</Link>{' '}
															to a premium profile at a later stage.
														</p>
													)}
												</div>
											) : (
												''
											)}

											{panelMessage === 2 ? (
												<div>
													<h3>Your contact info</h3>
													{validateUsername || validatePhoneNumber ? <div className='errmsg'>Please complete all of the required fields </div> : null}

													<p className=''>
														Although the majority of interaction on recfindr will be done via the messaging
														service, some clients may want to reach out to you directly.
													</p>
												</div>
											) : (
												''
											)}

											{panelMessage === 3 ? (
												<div>
													<h3>About you</h3>
													{validateBiography ? <div className='errmsg'>Please complete all of the required fields </div> : null}
													<p className=''>Right, now it{"'"}s your time to shine.</p>
													<p className=''>
														This is what the hiring manager will see. Give em the ol{"'"} razzle dazzle.
													</p>
													<p className=''>
														Write an introductory bio that briefly tells clients what you do. This should be
														short and snappy, think of it as your elevator pitch - you{"'"}ve got a paragraph
														to get their attention.
													</p>
												</div>
											) : (
												''
											)}

											{panelMessage === 4 ? (
												<div>
													<h3>Getting to know you</h3>
													<p className=''>
														Let your clients get to know who you are by answering a few questions about
														yourself.
													</p>
													<p className=''>
														With recfindr, we{"'"}re interested in who you are as an individual because as
														most people know, good business is done between people.
													</p>
													<p className=''>
														Choose up to 3 questions by scrolling through the options below and pop your
														answers in the box.
													</p>
													<p className=''>When you{"'"}re done, hit next.</p>
												</div>
											) : (
												''
											)}

											{panelMessage === 5 ? (
												<div>
													<h3>
														Getting to know {"'"}Work you{"'"}
													</h3>
													<p className=''>
														Now that we{"'"}ve gotten to know personal you, it{"'"}s time to get to know "work
														you". It{"'"}s why the hiring managers are here, after all.
													</p>
													<p>Choose 3 questions and pop your answers in the box.</p>
													<p>When you{"'"}re done, hit next.</p>
												</div>
											) : (
												''
											)}

											{panelMessage === 6 ? (
												<div>
													<h3>Past assignments</h3>
													<p className=''>
														List the placements you{"'"}re proud of. Give credence to your credentials.
													</p>
													<p className=''>It{"'"}s your record of recruitment achievements.</p>
													<p className=''>
														This is your opportunity to highlight what kind of roles you'd like to work on.
													</p>
												</div>
											) : (
												''
											)}
										</div>

										<form
											onSubmit={handleSubmit}
											id='profileCarousel'
											className='recForm col-md-7 offset-md-1'>
											<Carousel
												slide={false}
												id='editProfile'
												className='profileFields'
												interval={null}
												ref={bsCarousel}
												wrap={false}
												activeIndex={index}
												onSelect={handleSelect}>
												<Carousel.Item className='about'>
													<h4>Who are you?</h4>

													<div>
													{profile?.paid ? ( // Only render if profile.paid is true
														<div className="formgroup">
														<FilesUploadComponent picture={profile?.profile_picture} />
														</div>
													) : <p>Free accounts are not able to upload a profile picture. <Link to='/pricing' style={{ textDecoration: 'underline' }}>Upgrade to a premium account</Link> to unlock this feature.</p>}
													</div>

													<div>
													{profile?.paid ? ( // Only render if profile.paid is true
														<div className="formgroup">
														<BackgroundFilesUploadComponent picture={profile?.background_picture} />
														</div>
													) : <p>Free accounts are not able to upload a background picture. <Link to='/pricing' style={{ textDecoration: 'underline' }}>Upgrade to a premium account</Link> to unlock this feature.</p>}
													</div>


													<div className=''>
														<label>First name *</label>
														<input
															type='text'
															id='first_name'
															className='required'
															name='first_name'
															placeholder='First name*'
															defaultValue={profile.first_name}
															onChange={handleChange}
															required
														/>

														{validateFirstName ? <div className='errmsg'>{validateFirstName}</div> : null}
													</div>

													<div className={`${profile?.paid ? 'paid' : 'free'}`}>
														<label>Last name *</label>
														<input
															type='text'
															id='last_name'
															className={`required`}
															name='last_name'
															placeholder='Last name*'
															defaultValue={profile.last_name}
															onChange={handleChange}
															required
														/>
														{validateLastName ? <div className='errmsg'>{validateLastName}</div> : null}
													</div>

													<div className={`${profile?.paid ? 'paid' : 'free'}`}>
														<label>Job title</label>
														<input
															type='text'
															name='job_title'
															id='job_title'
															placeholder='Job Title *'
															className='required'
															title='requried'
															defaultValue={profile.job_title}
															onChange={handleChange}
														/>
														{validateJobTitle ? <div className='errmsg'>{validateJobTitle}</div> : null}
													</div>

													<div className='companySelectWithToolTips'>
														<div className={`${profile?.paid ? 'paid' : 'free'}`}>
															<label>Company</label>
															<div className='formgroup'>
																{/*
																<AutoSuggestCompany profile={profile} setProfile={setProfile} />
																*/}
																<input
																	type='text'
																	name='company'
																	id='company'
																	placeholder='Company name *'
																	className='required'
																	title='requried'
																	defaultValue={profile.company}
																	onChange={handleChange}
																/>

																<div className='tooltip-container'>
																	<img
																		src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
																		className='tooltip-icon'
																	/>
																	<span className='tooltiptext'>Who you work for. Obviously.</span>
																</div>
															</div>
															{validateCompany ? <div className='errmsg'>{validateCompany}</div> : null}
														</div>
													</div>

													<hr></hr>
													<h4>What do you do?</h4>

													<div className='industrySelectWithToolTips'>
														<IndustrySelect
															initialIndustry={initialIndustry}
															errorIndustry={validateIndustry}
															errorSector={validateSector}
															errorSubSector={validateSubSector}
															title1='I work in: *'
															title1_tip='Choose your primary Job market'
															initialSectors={initialSectors}
															title2='My expertise covers: *'
															title2_tip='Pick the industry you have most experience in'
															initialSubSectors={initialSubSectors}
															title3='I specialise in: *'
															title3_tip='Jacks of all trades need not apply. This is a platform for specialists. Choose your tags here.'
															onProfileChange={handleProfileChange}
															role={profile?.role}
															paid={profile?.paid}
														/>
													</div>

													<div className='formgroup' style={{ marginTop: 'var(--spacer-2)' }}>
														<label>What type of employment do you specialise in</label>
														<EmploymentSelect
															id='employment_type'
															permanent={profile.permanent}
															contract={profile.contract}
															onSelectionChange={handleSelectionChange}
														/>
													</div>
													{validateEmployment ? <div className='errmsg'>{validateEmployment}</div> : null}

													<hr></hr>

													<h4>What locations do you recruit in?</h4>

													<div className='locationSelectWithTooltip'>
														<label>This is where you recruit, not where you live or work</label>
														<div className='formgroup formgroup-withicon'>
															<LocationIcon />
															<Autocomplete
																apiKey={process.env.REACT_APP_GOOGLE_API}
																defaultValue={profile.location_1.name}
																id='location_1'
																className='required'
																//placeholder="Location 1"
																options={{
																	types: ['(regions)'],
																	// componentRestrictions: { country: "uk" }
																}}
																onPlaceSelected={(place) => {
																	console.log(place);
																	console.log('Lat', place.geometry.location.lat());
																	console.log('Lng', place.geometry.location.lng());
																	let lng1 = place.geometry.location.lng();
																	let lat1 = place.geometry.location.lat();
																	setProfile((prevState) => ({
																		...prevState,
																		location_1: {
																			...prevState.location_1,
																			name: place.formatted_address,
																			//type: "point",
																			coordinates: [Number(lng1.toFixed(4)), Number(lat1.toFixed(4))],
																		},
																	}))
																}}
																onChange={(event) => {
																	if (event.target.value === '') {
																	  setProfile((prevState) => ({
																		...prevState,
																		location_1: {
																		  ...prevState.location_1,
																		  name: '',
																		  coordinates: [],
																		},
																	  }));
																	}
																  }}
																onKeyDown={(event) => {
																	if (event.key === 'Enter') {
																	  event.preventDefault();
																	}
																  }}
															/>
														</div>

														<div className='formgroup formgroup-withicon'>
															<LocationIcon />
															<Autocomplete
																apiKey={process.env.REACT_APP_GOOGLE_API}
																id='location_2'
																options={{
																	types: ['(regions)'],
																	// componentRestrictions: { country: "EU" }
																}}
																defaultValue={profile.location_2.name}
																onPlaceSelected={(place) => {
																	console.log(place);
																	console.log('Lat', place.geometry.location.lat());
																	console.log('Lng', place.geometry.location.lng());
																	let lng2 = place.geometry.location.lng();
																	let lat2 = place.geometry.location.lat();
																	setProfile((prevState) => ({
																		...prevState,
																		location_2: {
																			...prevState.location_2,
																			name: place.formatted_address,
																			coordinates: [Number(lng2.toFixed(4)), Number(lat2.toFixed(4))],
																		},
																	}));
																}}
																onChange={(event) => {
																	if (event.target.value === '') {
																	  setProfile((prevState) => ({
																		...prevState,
																		location_2: {
																		  ...prevState.location_2,
																		  name: '',
																		  coordinates: [],
																		},
																	  }));
																	}
																  }}
																onKeyDown={(event) => {
																	if (event.key === 'Enter') {
																	  event.preventDefault();
																	}
																}}
															/>
														</div>

														{validateLocation1 ? <div className='errmsg'>{validateLocation1}</div> : null}
													</div>
												</Carousel.Item>
												<Carousel.Item className='contactInfo' id='contactInfo'>
													<div className='row'>
														<div className='col-md-12'>
															<h4>Your contact details</h4>
															<div
																className={`formgroup formgroup-withicon ${profile?.paid ? 'paid' : 'free'}`}>
																<img
																	src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/email.svg'}
																	className='inputIcon'
																/>
																<input
																	type='email'
																	id='username'
																	className='required'
																	placeholder='Email'
																	name='username'
																	defaultValue={profile.username}
																	onChange={handleChange}
																	required
																	disabled
																/>

																<div className='tooltip-container'>
																	<img
																		src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
																		className='tooltip-icon'
																	/>
																	<span className='tooltiptext'>
																		This is the email address you signed up with and cannot be changed.
																	</span>
																</div>
															</div>
															{validateUsername ? <div className='errmsg'>{validateUsername}</div> : null}

															<div
																className={`formgroup formgroup-withicon ${profile?.paid ? 'paid' : 'free'}`}>
																<img
																	src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/phone.svg'}
																	className='inputIcon'
																/>
																<input
																	type='tel'
																	id='phone_number'
																	name='phone_number'
																	className='required'
																	placeholder='Phone number *'
																	defaultValue={profile.phone_number}
																	onChange={handleChange}
																/>
															</div>
															{validatePhoneNumber ? (
																<div className='errmsg'>{validatePhoneNumber}</div>
															) : null}

															<div
																className={`formgroup formgroup-withicon ${profile?.paid ? 'paid' : 'free'}`}>
																<img
																	src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/website.svg'}
																	className='inputIcon'
																/>
																<input
																	type='text'
																	id='website_url'
																	name='website_url'
																	placeholder='More info'
																	defaultValue={profile.website_url}
																	onChange={handleChange}
																/>
															</div>

															<div
																className={`formgroup formgroup-withicon ${profile?.paid ? 'paid' : 'free'}`}>
																<img
																	src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/calendly.svg'}
																	className='inputIcon'
																/>
																<input
																	type='text'
																	id='calendly_username'
																	name='calendly_username'
																	placeholder='Calendar URL'
																	defaultValue={profile.calendly_username}
																	onChange={handleCalendly}
																/>
															</div>

															<div
																className={`formgroup formgroup-withicon ${profile?.paid ? 'paid' : 'free'}`}>
																<img
																	src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/vidyard.svg'}
																	className='inputIcon'
																/>
																<input
																	type='text'
																	name='video'
																	placeholder='Video Link'
																	defaultValue={profile.video}
																	onChange={validateVidyardURL}
																/>

																<div className='tooltip-container'>
																	<img
																		src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
																		className='tooltip-icon'
																	/>
																	<span className='tooltiptext'>
																	Enter a URL to a video so you can present a short video about yourself
																	</span>
																</div>
															</div>
														</div>
													</div>
												</Carousel.Item>
												<Carousel.Item className='biography'>
													<h4>Give us an overarching bio of yourself</h4>

													<p>
														That could be how you got into recruitment, the company you work for, anything.
													</p>
													<div className=''>
														{validateBiography ? <div className='errmsg'>{validateBiography}</div> : null}

														<div className={` ${profile?.paid ? 'paid' : 'free'}`}>
															<textarea
																rows='7'
																type='text'
																id='biography'
																className=''
																placeholder='Start typing... *'
																name='biography'
																defaultValue={profile.biography}
																onChange={(event) => {
																	handleChange(event);
																	setBioLength(event.target.value.length);
																  }}
																maxLength='600'
															/>
															<div>{bioLength}/600</div>
															{validateBiography ? <div className='errmsg'>{validateBiography}</div> : null}
														</div>
													</div>
												</Carousel.Item>
												<Carousel.Item className='personalQs'>
													{validatePersonalQuestions ? (
														<div className='errmsg'>{validatePersonalQuestions}</div>
													) : null}
													{errorMessagePersonal ? <div className='errmsg'>{errorMessagePersonal}</div> : null}
													<PersonalQuestionsAccordion
														defaultValue={profile.questions.personal}
														onPersonalAnswersChange={handlePersonalAnswersChange}
													/>
												</Carousel.Item>

												<Carousel.Item className='recruitmentQs'>
													{validateRecruitmentQuestions ? (
														<div className='errmsg'>{validateRecruitmentQuestions}</div>
													) : null}
													<ProfessionalQuestionsAccordion
														defaultValue={profile.questions.recruitment}
														onProfessionalAnswersChange={handleProfessionalAnswersChange}
													/>
												</Carousel.Item>

												<Carousel.Item className='pastAssignments'>
													<AssignmentForm paid={profile?.paid} />
												</Carousel.Item>

												<Carousel.Item className='history' id='final'>
													<div className=''>
														<div className='col-lg-8 offset-lg-2 theme-four' style={{ borderRadius: '30px' }}>
															<div className='finalPanel'>
																<div
																	className='formgroup'
																	id='profile_complete_group text-center'
																	style={{ textAlign: 'center' }}>
																	<h2>Congratulations</h2>
																	<p className=''>You did it.</p>

																	<p>You're officially one step closer to saying goodbye to hard core BD.</p>

																	<p>
																		If you missed anything, then don{"'"}t forget you can jump into your
																		profile and add extra information at any time.{' '}
																	</p>

																	<p>
																		Remember, if you{"'"}re currently on a free account and want to upgrade,
																		make sure you{"'"}ve filled out the premium profile areas too by heading
																		to the "edit profile" tab once you{"'"}re done.{' '}
																	</p>

																	<p className='mt-4'>I agree that the information provided is accurate.</p>

																	<div className='btn-celebrate-wrapper'>
																		<button
																			id='profileFinish'
																			className='btn btn-secondary btn-lg align-self-center'
																			onClick={finishProfile}>
																			Let's go
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</Carousel.Item>
											</Carousel>
										</form>

										<div className='d-flex flex-row align-items-center justify-content-end'>
											<button id='profileBack' className='profileBack' onClick={toggleClick('back')}>
												Back
											</button>
											{/* <button id='profileNext' className='profileNext' onClick={toggleClick('next')}>
												Next
											</button> */}
											<button id='profileNext' className='profileNext' onClick={toggleClick('next')}>
												{index === 5 ? 'Finish' : 'Next'}
											</button>
										</div>
									</div>
								</>
							</>
						)}
					</article>
				</section>
			</div>
		</>
	);
};

export default RecruiterAccountWelcome;
