import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Autocomplete from "react-google-autocomplete";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SterlingIcon from "./icons/SterlingIcon";
import LocationIcon from "./icons/LocationIcon";


const AssignmentForm = ({ paid }) => {
	const axiosPrivate = useAxiosPrivate();

	const [jobTitle, setJobTitle] = useState("");
	const [salary, setSalary] = useState("");
	const [location, setLocation] = useState("");

	const [assignments, setAssignments] = useState([]);
	const [limit, setLimit] = useState(false);

	useEffect(() => {
		//let isMounted = true;
		const controller = new AbortController();
		if (!paid && assignments.length >= 1) {
			setLimit(true);
		} else {
			setLimit(false);
		}
		return () => {
			//isMounted = false;
			controller.abort();
		};
	},);

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const getAssignments = async () => {
			try {
				const response = await axiosPrivate.get("/api/assignments", {
					single: controller.signal,
				});
				//console.log(response.data)
				//var Assignmentsdata = JSON.parse(response.data);

				isMounted && setAssignments(response.data);
			} catch (err) {
				console.log(err);
			}
		};
		getAssignments();
		return () => {
			isMounted = false;
			controller.abort();
		};
	},[]);
	/*
    const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page
    addAssignment();
    }
*/
	const addAssignment = async () => {
		try {
			const data = {
				job_title: jobTitle,
				salary: salary,
				location: {
					name: location.location?.name,
					coordinates: location.location?.coordinates,
				},
			};
			console.log(data);
			const res = await axiosPrivate.put("/api/assignments", data);
			console.log(res)
		} catch (err) {
			console.log(err);
		} finally {
			console.log("finally");
			//let isMounted = true;
			const controller = new AbortController();
			try {
				const response = await axiosPrivate.get("/api/assignments", {
					single: controller.signal,
				});
				//console.log(response.data);
				console.log("finally data");
				//var Assignmentsdata = JSON.parse(response.data);

				//isMounted && setAssignments(response.data);
				setAssignments(response.data);
			} catch (err) {
				console.log(err);
			}
		}
	};

	const handleDelete = async (id) => {
		try {
			const data = JSON.stringify({
				id,
			});
			console.log(data);
			const res = await axiosPrivate.delete("/api/assignments/" + id);
			console.log(res)
		} catch (err) {
			console.log(err);
		} finally {
			let isMounted = true;
			const controller = new AbortController();
			try {
				const response = await axiosPrivate.get("/api/assignments", {
					single: controller.signal,
				});
				console.log(response.data);
				//var Assignmentsdata = JSON.parse(response.data);

				isMounted && setAssignments(response.data);
			} catch (err) {
				console.log(err);
			}
		}
	};

	// RT - Utility function to format show the salary as currency
	const formatCurrency = (amount) => {
		if (amount) {
		// Convert to a number if it's a string
		const numberAmount = Number(amount);
		// Format the number as currency
		return new Intl.NumberFormat('en-GB', {
			style: 'currency',
			currency: 'GBP',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(numberAmount);
		}
		return "Not Defined";
	};

	return (
		<>
			{/*limit ? ('Free accounts are limited to displaying only their latest assignment') : ''*/}

			{assignments.length <= 2 ? (
				<div className="row">
					<div className="col-md-12">
						<h4>Add a Placement</h4>
						<div className="">
							<label>Job Title</label>
							<input
								type="text"
								id="job_title"
								placeholder="Job title"
								value={jobTitle}
								onChange={(event) => setJobTitle(event.target.value)}
							/>
						</div>

						<div className="">
							<label>Salary</label>

							<div
								className="formgroup formgroup-withicon">
								<SterlingIcon />
								<input
									id="salary"
									type="number"
									placeholder="Salary"
									value={salary}
									onChange={(event) => setSalary(event.target.value)}
								/>
							</div>
						</div>

                        <div className="">
							<label>Location</label>

							<div
								className="formgroup formgroup-withicon">
								<LocationIcon />
								<Autocomplete
								apiKey={process.env.REACT_APP_GOOGLE_API}
								//defaultValue={profile.location_1.name}
								id="location_1"
								className="required"
								placeholder="Location"
								onPlaceSelected={(place) => {
									console.log(place);
									console.log("Lat", place.geometry.location.lat());
									console.log("Lng", place.geometry.location.lng());
									let lng1 = place.geometry.location.lng();
									let lat1 = place.geometry.location.lat();
									setLocation((prevState) => ({
										...prevState,
										location: {
											...prevState.location,
											name: place.formatted_address,
											//type: "point",
											coordinates: [
												Number(lng1.toFixed(4)),
												Number(lat1.toFixed(4)),
											],
										},
									}));
								}}
								onKeyPress={(e) => {
									e.key === "Enter" && e.preventDefault();
								}}
							/>
							</div>
						</div>

						<div className="col-md-12 text-end">
							<button
								type="button"
								className="addAssignment hfont"
								onClick={(e) => addAssignment()}
							>
								<span>
									<FontAwesomeIcon icon={faPlus} />
								</span>{" "}
								Add placement
							</button>
						</div>
						<hr></hr>
					</div>
				</div>
			) : (
				<p id="uidnote" className="instructions">
					<span>Maximum number of placements reached</span>
				</p>
			)}

			<div className={`pastAssignments ${limit ? "ghosted" : ""}`}>
                
                {assignments.length > 0 && <h4>Your Placements</h4>}

				{paid ? (
					""
				) : (
					<p className="instructions">
					<span>Free accounts are limited to displaying only their latest assignment</span>
				</p>
				)}

				{assignments?.map((assignment) => (
					<div className={`assignment-panel`} key={assignment._id}>
						<div className="assignmentWrapper">
							<label>Job Title:</label>
							<input id="" type="text" value={assignment.job_title} disabled />
						</div>
						<div className="assignmentWrapper">
							<label>Salary: </label>
							<input
								id=""
								type="text"
								value={formatCurrency(assignment?.salary)}
								disabled
							/>
						</div>
						<div className="assignmentWrapper">
							<label>Location: </label>
							<input
								id=""
								type="text"
								value={
									assignment?.location.name
										? assignment.location.name
										: "Not Defined"
								}
								disabled
							/>
						</div>

						<div className="text-end">
							<button
								type="button"
								className="removeAssignment hfont"
								data-id={assignment?._id}
								onClick={() => handleDelete(assignment?._id)}
							>
								<span>
									<FontAwesomeIcon icon={faMinus} />
								</span>{" "}
								Remove
							</button>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default AssignmentForm;