import { useLayoutEffect } from "react";
import RecruiterAccountWelcome from "./recruiters-components/RecruiterWelcome";
import ClientAccountWelcome from "./client-components/ClientWelcome";
import CompanyAccountWelcome from "./company-components/CompanyWelcome";

import useAuth from "../hooks/useAuth";

const Welcome = (props) => {
    

    const { auth } = useAuth();
    const role = auth?.role;

    useLayoutEffect(() => {
        document.body.classList.add('theme-seven');
        const html = document.documentElement;
        html.classList.add('theme-seven');
        return () => {
            html.classList.remove('theme-seven');
        };
    });

    if (role.includes(3000)) {
        // Handle role 3000
    } else if (role.includes(3001)) {
        return <RecruiterAccountWelcome />;
    } else if (role.includes(3002)) {
        return <ClientAccountWelcome />;
    } else if (role.includes(4000)) {
        return <CompanyAccountWelcome/>;
    } else {
        return <>error</>;
    }


}
export default Welcome