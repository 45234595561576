import React from 'react';

const EnvelopeIcon = (props) => (

<svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  height="1em"
  viewBox="0 0 512 512"
>
  <path
    fill="var(--color-black)"
    d="m48 150.8 208 143 208-143V112H48v38.8zM464 209 256 352 48 209v191h416V209zM0 400V64h512v384H0v-48z"
  />
</svg>

)
export default EnvelopeIcon