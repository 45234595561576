import React from 'react';

const CopyLinkIcon = (props) => (

<svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 448 512"
  >
    <path
      fill="var(--color-black)"
      d="M400 336H176V48h156.1l67.9 67.9V336zm48-240L352 0H128v384h320V96zM48 128H0v384h320v-96h-48v48H48V176h48v-48H48z"
    />
  </svg>

)
export default CopyLinkIcon