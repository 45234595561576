import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowScribble from '../icons/ArrowScribble.js';
import DOMPurify from 'dompurify';

const ClientInbox = (props) => {
	const axiosPrivate = useAxiosPrivate();
	const [isLoading, setLoading] = useState(true);
	const [inbox, setInbox] = useState('');
	const [totalUnread, setTotalUnread] = useState(0);
	const [chatId, setChatId] = useState('');
	const [msg, setMsg] = useState([]);
	const [reply, setReply] = useState('');
	const [hostName, setHostName] = useState('');
	const [hostPicture, setHostPicture] = useState('');
	const [hostMatched, setHostMatched] = useState('');
	const [recruiterUUID, setRecruiterUUID] = useState('');
	const [activeMessage, setActiveMessage] = useState(null);

	// RT - SCROLL TO BOTTOM OF MESSAGES SOLUTION

	const [isMessagesLoading, setMessagesLoading] = useState(false);

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		const scrollContainer = document.querySelector('.tmsgbox > div');
		if (scrollContainer) {
			scrollContainer.scrollTop = scrollContainer.scrollHeight;
		}
	};

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const getInbox = async () => {
			try {
				const response = await axiosPrivate.get('/api/messages/client', {
					single: controller.signal,
				});
				console.log(response.data);
				var inboxData = response.data;
				isMounted && setInbox(inboxData);
				console.log('inBOX');
				console.log(inbox);

				setTotalUnread(response.data[2]);

				setLoading(false);
			} catch (err) {
				console.log(err);
			}
		};
		getInbox();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, []);

	const handleOpenMessage = (e) => {
		setMessagesLoading(true);
		//setLoading(true);
		e.preventDefault();
		console.log('submit hit');
		console.log(e.currentTarget.value);

		const clickedButton = e.currentTarget;
		const listItem = clickedButton.parentNode;
		const unreadCountSpan = listItem.querySelector('.unreadCount');
		unreadCountSpan?.classList.add('d-none');

		let deduct = totalUnread - e.currentTarget.getAttribute('data-unread');
		setTotalUnread(deduct);

		const controller = new AbortController();

		const messageId = e.currentTarget.value;
		setActiveMessage(messageId);

		console.log(e.currentTarget.value);
		setChatId(e.currentTarget.value);
		let data = { chat_id: e.currentTarget.value };

		setHostName(e.currentTarget.getAttribute('name'));
		setHostPicture(e.currentTarget.getAttribute('data-photo'));
		setRecruiterUUID(e.currentTarget.getAttribute('data-uuid'));
		setHostMatched(e.currentTarget.getAttribute('data-matched'));

		console.log(e.currentTarget.photo);

		console.log(data);
		var msgUrl = '/api/messages/thread';
		const getMsg = async () => {
			try {
				const res = await axiosPrivate.post(msgUrl, data);

				if (res.data.length === 0) {
					console.log('Array is empty!');
				}
				console.log(res.data);
				setMsg(res.data.messages);
				setLoading(false);
			} catch (err) {
				console.log(err);
			}
			setLoading(false);
			setMessagesLoading(false);
			scrollToBottom();
		};
		getMsg();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		//setLoading(true);

		//console.log('submit hit')

		var sanitizedReply = DOMPurify.sanitize(reply);
		sanitizedReply = sanitizedReply.replace(/\n/g, '<br/>');

		var query = {
			chat_id: chatId,
			//uid:"63c94aef414b92dcf37e0f53",
			msg: sanitizedReply,
		};

		const data = JSON.stringify(query);

		console.log(query);

		if (reply < 2) {
			alert('Message length must be over 3 characters');
			setLoading(false);
		} else {
			var replyURL = '/api/messages';
			const Reply = async () => {
				try {
					const res = await axiosPrivate.post(replyURL, data);

					if (res.data.length === 0) {
						console.log('Array is empty!');
					}
					console.log(res.data);

					let refreshdata = { chat_id: chatId };
					console.log(refreshdata);
					var msgUrl = '/api/messages/thread';
					const getMsg = async () => {
						try {
							const res = await axiosPrivate.post(msgUrl, refreshdata);

							if (res.data.length === 0) {
								console.log('Array is empty!');
							}
							console.log(res.data);
							setMsg(res.data.messages);
							setReply('');
							setLoading(false);
						} catch (err) {
							console.log(err);
						}
						setLoading(false);
					};
					getMsg();

					setLoading(false);
				} catch (err) {
					console.log(err);
				}
				scrollToBottom();
				setLoading(false);
			};
			Reply();
		}
	};

	const headerProps = {
		profile_picture: inbox[1]?.profile.profile_picture,
		header_color: 'theme-eight',
	};

	function handleKeyPress(e) {
		if (e.key === 'Enter') {
			//console.log('enter press here! ')
			//handleSubmit(e)
			e.stopPropagation();
		}
	}

	function formatDate(dateString) {
		const options = {
			hour: 'numeric',
			minute: 'numeric',
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		};

		const formattedDate = new Date(dateString).toLocaleString('en-US', options);
		return formattedDate;
	}

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
				.
			</div>
		);
	}

	return (
		<>
			<div className='app-main-wrapper'>
				<section className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<h1 className='h3'>Your Inbox</h1>
						</div>
					</div>

					<div className='clientInbox clientInbox-style2'>
						<div className='row equal-cols'>
							<div className='col-md-4'>
								<div className='inboxContacts'>
									<div className='inboxContacts-header'>
										<h2 className='h3'>Conversations</h2>
										{totalUnread > 0 && (
											<>
												<span className='totalUnread'>{totalUnread ? totalUnread : null}</span>
											</>
										)}
									</div>

									<div className='inboxContactsScroll'>
										{inbox[0]?.length ? (
											<ul className='inboxList-wrapper'>
												{inbox[0].map((msg, i) => (
													<li key={i} className={`inboxList ${activeMessage === msg._id ? 'active' : ''}`}>
														<button
															className='inboxContactBtn'
															onClick={handleOpenMessage}
															value={msg?._id}
															data-uuid={msg?.users?.recipient?.uuid}
															data-photo={msg?.users?.recipient?.profile?.profile_picture}
															name={msg?.users?.recipient?.profile?.first_name}
															data-unread={msg?.unread}
															data-matched={msg?.users?.recipient?.like}>
															<div className='inboxContact-avatarwrapper'>
																{msg?.users?.recipient?.profile?.profile_picture === null ||
																typeof msg?.users?.recipient?.profile?.profile_picture === 'undefined' ? (
																	<img
																		className='inboxAvatarBadge'
																		alt=''
																		src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}></img>
																) : (
																	<img
																		className='inboxAvatarBadge'
																		alt=''
																		src={`data:image/jpeg;base64,${msg?.users?.recipient?.profile?.profile_picture}`}></img>
																)}
																{msg.unread > 0 && (
																	<>
																		<span className='unreadCount'>{msg?.unread}</span>
																	</>
																)}
															</div>

															<div className='inboxContact-contentwrapper'>
																<span className='lastUpdated'>{msg?.lastUpdated}</span>
																<span className='hfont uname'>
																	{msg?.users?.recipient?.profile?.first_name}{' '}
																	{(msg?.users?.recipient?.profile?.paidBySelf ||
																		msg?.users?.recipient?.profile?.paidBySelf) &&
																		msg?.users?.recipient?.profile?.last_name}
																</span>
																<span className='preview'>{msg?.messages?.[0].message}...</span>
															</div>
														</button>
													</li>
												))}
											</ul>
										) : (
											<div className='no-messages-wrapper'>
												<span className='info-icon'>i</span>
												<span>No messages</span>
											</div>
										)}
									</div>
								</div>
							</div>

							<div className='col-md-8'>
								<div className='direct-message-panel'>
									{!activeMessage && inbox[0]?.length ? (
										<div className='choose-conversation'>
											<ArrowScribble />
											Choose a conversation
										</div>
									) : !inbox[0]?.length ? (
										<div className='no-conversations'>
											<ArrowScribble />
											Your conversations will display here
										</div>
									) : (
										<div className='direct-message-inbox'>
											{hostName && (
												<div className='message-receiver'>
													{hostPicture === null || typeof hostPicture === 'undefined' ? (
														<img
															className='inboxAvatarBadge'
															alt=''
															src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}></img>
													) : (
														<img
															className='inboxAvatarBadge'
															alt=''
															src={`data:image/jpeg;base64,${hostPicture}`}></img>
													)}
													<h4>
														<a href={`${process.env.PUBLIC_URL}/u/${recruiterUUID}`}>
															{hostName ?? hostName}
														</a>
													</h4>
												</div>
											)}

											<div className='direct-message-content'>
												{chatId?.length ? (
													<>
														<div className='tmsgbox'>
															<div ref={messagesEndRef}>
																{isMessagesLoading ? ( // Conditional rendering based on isMessagesLoading
																	<div className='message-loading-indicator'>
																		<div className='lds-facebook'>
																			<div></div>
																			<div></div>
																			<div></div>
																		</div>
																	</div>
																) : (
																	<ul className='msgbox-messages' id='client-messages'>
																		{msg.map((item, i) => (
																			<li key={i} className={`message-holder holder-${item.sender.role.includes(3001) ? '3001' : '3002'}`}>
																				<div className={`message-bubble ${item?.sender?.role.includes(3001) ? 'role3001' : 'role3002'}`}>
																					<div className='message-sender-wrapper'>
																						{item?.sender?.profile?.profile_picture === null ||
																						typeof item?.sender?.profile?.profile_picture === 'undefined' ? (
																							<img
																								className='inboxAvatarBadge small'
																								alt=''
																								src={
																									process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'
																								}></img>
																						) : (
																							<img
																								className='inboxAvatarBadge small'
																								alt=''
																								src={`data:image/jpeg;base64,${item?.sender?.profile?.profile_picture}`}></img>
																						)}
																						<span className='msgbox-username'>
																							{item?.sender?.profile?.first_name}
																						</span>
																					</div>

																					<span className='msgbox-message'>
																						<div
																							dangerouslySetInnerHTML={{
																								__html: item?.message,
																							}}
																						/>
																					</span>

																					{/*<span className="msgbox-time">{formatDate(item?.sent)}</span>*/}
																				</div>
																			</li>
																		))}
																	</ul>
																)}
															</div>
														</div>

														{hostMatched === 'false' ? (
															<h4 style={{ marginTop: 'var(--spacer-9)', marginBottom: 'var(--spacer-9)' }}>
																You are no longer matched with <a href={`${process.env.PUBLIC_URL}/u/${recruiterUUID}`}>{hostName}</a>.
															</h4>
														) : (
															<></>
														)}
														<form className={`replyoptions ${hostMatched === 'false' ? 'd-none' : ''}`}>
															<textarea
																value={reply}
																onChange={(e) => setReply(e.target.value)}
																type='text'
																placeholder='Start typing...'
																rows='5'
																maxLength={1000}
																minLength={3}
																style={{
																	fontFamily: "'LabilGrotesk-Light', Arial, sans-serif FontAwesome",
																	width: '100%',
																}}
																onKeyDown={(e) => handleKeyPress(e)}></textarea>
															<button onClick={handleSubmit}>
																<FontAwesomeIcon icon={faPaperPlane} />
															</button>
														</form>
													</>
												) : (
													<></>
												)}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className='rf-bg-six d-sm-none d-md-block'></div>
			</div>
		</>
	);
};

export default ClientInbox;
