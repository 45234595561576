import React from 'react';

const RecfindrLogo = (props) => (

<svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="recfindr-logo"
    viewBox="0 0 719.1 146.7"

  >
    <g fill="var(--color-black);">
      <path d="M0 47.3h33.3l-.9 21.3c4.6-19.5 19.3-25 34.3-21.3v33.3c-14.8-2.8-33.3-.6-33.3 22.2v38.9H0V47.3zM72 94.5c0-29.5 21.1-50.9 50.4-50.9 32.6 0 53.2 26.7 47.8 61.3h-64.3c1.1 8.3 8 14.6 17.2 14.6 7.8 0 14.4-4.4 16.9-10.7l28.5 4.1c-3.3 17.8-21.1 32.6-45.8 32.6-29.6 0-50.7-21.5-50.7-51zm65.8-12.2c0-8-6.5-13.9-15.4-13.9-8.7 0-15.4 5.7-16.5 13.9h31.9zM177.6 94.5c0-28.9 21.7-50 51.3-50 27 0 48.3 16.1 49.1 40.8l-33 2.6c-.7-8.2-7.6-14.1-16.3-14.1-10.6 0-17.8 8.7-17.8 20.7 0 11.7 7.2 20.4 17.8 20.4 8.7 0 15.6-6.7 16.3-15.6l33 2.2c-.4 25.9-21.7 43-49.1 43-29.6 0-51.3-21.1-51.3-50zM346.5 9.1v26.3c-6.5-1.9-14.8 0-14.8 11.5v.4H391v94.5h-33.3V75.1h-25.9v66.7h-33.3V75.1h-15.7V47.3h15.7v-.4c-.1-28.5 23.9-41.5 48-37.8zM374.3 0c11.1 0 19.5 8.5 19.5 19.4 0 11.1-8.3 19.5-19.5 19.5-10.9 0-19.5-8.3-19.5-19.5 0-10.8 8.6-19.4 19.5-19.4zM403 47.3h33.3l-.7 15.4c4.6-10.8 14.3-17.3 27.2-17.3 20 0 34.6 15.9 34.6 38v58.4h-33.3v-53c0-10.2-5.2-16.5-13.9-16.5-8.5 0-13.9 5.9-13.9 15.7v53.7H403V47.3zM506.5 94.5c0-28.9 17.8-49.1 43-49.1 10.6 0 19.4 3.7 25.9 10.2V12.1h33.4v129.6h-33.3v-8.3c-6.5 6.5-15.4 10.2-25.9 10.2-25.3 0-43.1-20.2-43.1-49.1zm52.3 21.7c10.7 0 18.5-9.1 18.5-21.7s-7.8-21.7-18.5-21.7-18.5 9.1-18.5 21.7 7.7 21.7 18.5 21.7zM616.6 54.3l32.6-7 3.5 21.1c.6-20 13.7-28.5 29.1-28l7 32.6c-15.2.4-32.6 6.5-28 28.7l8 38-32.6 7-19.6-92.4zM680.1 124.2c0-10.9 8.5-19.4 19.5-19.4 10.9 0 19.5 8.5 19.5 19.4s-8.5 19.5-19.5 19.5c-11-.1-19.5-8.6-19.5-19.5z" />
    </g>
</svg>

)
export default RecfindrLogo