import { useRef, useState, useEffect } from "react";
//import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';
//import { Link } from "react-router-dom";

//const USER_REGEX = /^(?:[A-Z\d][A-Z\d_-]{5,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
//const RECOVER_URL = '/register/reset';

const PasswordReset = () => {
    //const userRef = useRef();
    const errRef = useRef();
    //const roleRef = useRef();

    //const [success, setSuccess] = useState(false);
    const [validKey, setValidkey] = useState(false);

    useEffect(() => {
        //userRef.current.focus();
    },)


    useEffect(() => {
        handleKey();
    },)

/** ONLOAD HANDLE KEY */

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const key = getUrlParameter('key')
    console.log( key )


    const handleKey = async () => {
        // if button enabled with JS hack
        
        try {
            console.log(JSON.stringify({ key: key }))
            const response = await axios.post('/register/activate/company',
                JSON.stringify({ key }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
        
        
            setValidkey(true);
            //clear state and controlled inputs
        
        } catch (err) {
            console.log(err)
            errRef.current.focus();
        }
    }
/** END ONLOAD HANDLE KEY */

    return (

        <>
			{key ? (
				<>
					{validKey ? (
						<div className='app-main-wrapper'>
							<section className='container'>
								<div className='row'>
									<div className='col-md-6 offset-md-3'>
										<div className='form-success-panel'>
											<h1 className='h3'>Account activated</h1>
											<p>Your account has been successfully activated.</p>
											<p>
												<a href='/login/agency' className='btn btn-secondary'>
													Return to Login
												</a>
											</p>
										</div>
									</div>
								</div>
							</section>
							<div className='rf-bg-four d-sm-none d-md-block'></div>
						</div>
					) : (
						<>
							<div className='app-main-wrapper'>
								<section className='container'>
									<div className='row'>
										<div className='col-md-6 offset-md-3'>
											<div className='form-success-panel'>
												<h1 className='h3'>Invalid activation key</h1>
												<p>
													There's a problem with your activation key. If the problem persists, please contact
													support
												</p>
												<p>
													<a href='/login/agency' className='btn btn-secondary'>
														Return to Login
													</a>
												</p>
											</div>
										</div>
									</div>
								</section>
								<div className='rf-bg-four d-sm-none d-md-block'></div>
							</div>
						</>
					)}
				</>
			) : (
				<></>
			)}
		</>
    )
}

export default PasswordReset
