import RecruiterAccount from './recruiters-components/RecruiterAccount';
import ClientAccountWelcome from "./client-components/ClientWelcome";

import useAuth from '../hooks/useAuth';

const Account= () => {
    const { auth } = useAuth();

    console.log(auth.role)
    const role = auth.role

    if (role === 3000){

        return (
            <>
                
            </>
        )
        
    } else if (role === 3001) {
     
        return (
            <section>
                <RecruiterAccount />
                <br />
            </section>
        )

    } else if ( role === 3002) {
    
        return (
            <section>
                <ClientAccountWelcome />
                <br />
            </section>
        )

    } else {
        return(
            <>error</>
        )
    }

    
}

export default Account
