import { useRef, useState, useEffect } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';
import { Link } from "react-router-dom";

//const USER_REGEX = /^(?:[A-Z\d][A-Z\d_-]{5,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
//const RECOVER_URL = '/register/reset/company';

const PasswordReset = () => {
    //const userRef = useRef();
    const errRef = useRef();
    //const roleRef = useRef();

    //const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [validKey, setValidkey] = useState(false);

    useEffect(() => {
        //userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])


    useEffect(() => {
        setErrMsg('');
    }, [pwd, matchPwd])

    useEffect(() => {
        handleKey();
    },)

/** ONLOAD HANDLE KEY */

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };
    const key = getUrlParameter('key')
    console.log( key )


    const handleKey = async () => {
        // if button enabled with JS hack
        
        try {
            console.log(JSON.stringify({ key: key }))
            const response = await axios.post('/register/reset/company',
                JSON.stringify({ key }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
        
        
            setValidkey(true);
            //clear state and controlled inputs
        
        } catch (err) {
            console.log(err)
            /*
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Not Found');
            } else {
                setErrMsg('Error, Please Contact Support')
            }*/
            errRef.current.focus();
        }
    }
/** END ONLOAD HANDLE KEY */


    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v2 = PWD_REGEX.test(pwd);

        if (!v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            console.log(JSON.stringify({ key, pwd }))
            const response = await axios.post('/register/newpassword/company',
                JSON.stringify({ key, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            //clear state and controlled inputs
        } catch (err) {
            /*
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Email Not Found');
            } else {
                setErrMsg('Error, Please Contact Support')
            }
            errRef.current.focus();*/
        }
    }




    return (

        <>
        <div className='app-main-wrapper'>
            <section className='container'>
                <div className='row'>
                    <div className='col-lg-6 offset-lg-3'>

                    
        {success ? (
            <section className="container">
            <div id="register">
                <h1 className="h2 text-center">Password updated</h1>
                <p>
                    <a href="/login/agency" className='btn btn-lg btn-secondary align-self-center'>Sign In</a>
                </p>
            </div>
        </section>
        ) : (
            <>
            {validKey ? (
                <>
                <section className="container">
                <div id="register">
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <h1 className="h3 text-center">Agency Password Reset</h1>
                    <form onSubmit={handleSubmit}>
                        <div className='input-live-val-wrapper'>
                            <input
                                type="password"
                                id="password"
                                placeholder="Password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                            <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                            <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                        </div>
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                            8 to 24 characters.<br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </p>


                            <div className='input-live-val-wrapper'>
                                <input
                                    type="password"
                                    id="confirm_pwd"
                                    placeholder="Confirm Password"
                                    onChange={(e) => setMatchPwd(e.target.value)}
                                    value={matchPwd}
                                    required
                                    aria-invalid={validMatch ? "false" : "true"}
                                    aria-describedby="confirmnote"
                                    onFocus={() => setMatchFocus(true)}
                                    onBlur={() => setMatchFocus(false)}
                                />
                                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                            </div>
                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                Must match the first password input field.
                            </p>
                            <input
                                type="key"
                                id="key"
                                value={key}
                                required
                                hidden
                            />

                        <button id="recoveryButton" className="btn btn-secondary btn-lg align-self-center" disabled={!validPwd || !validMatch ? true : false}>Reset Password</button>
                    </form>
                </div>
            </section>
            <section className="container">
                <div className="col-lg-6 offset-lg-3 text-center">
                    <p>
                        <Link className="hfont" to="/login/agency"> Login</Link>
                    </p>
                </div>
            </section>
            </>
            ) : (
            <>
            
            <div id="register">
            <h1 className="h3 text-center">Invalid Reset Key</h1>
            </div>
            
            <section className="container">
                <div className="col-lg-6 offset-lg-3 text-center">
                    <p>
                        <Link className="hfont" to="/login/agency"> Login</Link>
                    </p>
                </div>
            </section>
            </>
        )}
            </>
        )}

                    </div>
                </div>
            </section>
        </div>
        
        </>
    )
}

export default PasswordReset
