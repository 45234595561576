import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";


const RequireProfileSetup = () => {
    const { auth } = useAuth();
    const location = useLocation();

    //console.log('inside RequireProfileSetup')
    //console.log(auth)


    if(auth.profile_complete === true){
        return(
            <Navigate to="/dashboard" state={{ from: location }} replace />
        );
    } else {
        return(
            <Navigate to="/welcome" state={{ from: location, role: auth.role }} replace />
        );
    }

}

export default RequireProfileSetup;
