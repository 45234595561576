import React, { useState, useRef, useEffect, useMemo } from 'react';
import { faCheck, faTimes, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal/lib/components/Modal';
import ViewProfile from '../viewProfile';

import { axiosPrivate } from '../../api/axios';

import HeartIcon from '../icons/HeartIcon.js';
import MailIcon from '../icons/MailIcon.js';
import VerifiedIcon from '../icons/VerifiedIcon.js';
import PremiumIcon from '../icons/PremiumIcon.js';
import MatchedScribble from '../icons/MatchedScribble.js';

// For swiper (imports)
import { Swiper, SwiperSlide, Navigation, Pagination } from 'swiper/react';
import SwiperCore from 'swiper';
//import ClientGeoSearch from "./ClientGeoSearch";
// For swiper (import styles)
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ClientSearchRecruiterTile = ({
	recruiter,
	clientid,
	listRecruiters,
	loadMore,
	loadPrev,
	showingPage,
	totalPages,
}) => {
	const [following, setFollowing] = useState(recruiter.following);

	// For swiper (states for visibility and active profile index)
	const [isProfileSliderVisible, setProfileSliderVisible] = useState(false);
	const [activeProfileIndex, setActiveProfileIndex] = useState(null);
	//const [updateRecruiters, setUpdateRecruiters] = useState();
	const [setRecruiters, setListRecruiters] = useState(listRecruiters);

	const [showLastSlideMessage, setShowLastSlideMessage] = useState(false);

	// RT - For bootstrap alert

	const [showAlert, setShowAlert] = useState(false);

	const [activeSlide, setActiveSlide] = useState(0); // To keep track of the active slide
	const [activeID, setActiveID] = useState(); // To keep track of the active slide recruiter _id
	const [activeFollowState, setActiveFollowState] = useState(); // To keep track of the active slide recruiter follow state
	const [refresh, setRefresh] = useState(); // To keep track of the active slide recruiter follow state

	// For swiper (swiper ref)
	const swiperRef = useRef(null);

	// For swiper (logic to call profiles)

	const viewProfile = (e) => {
		e.preventDefault();

		const element = e.currentTarget;
		const id = element.getAttribute('data-id');
		console.log(id);

		//console.log(dataId)

		//setRecruiterProfile(e.target.dataset.id)
		//console.log(e)

		//const clickedIndex = e.target.dataset.id;
		console.log(listRecruiters);
		const clickedIndex = listRecruiters.findIndex((recruiter) => recruiter.uuid === id);
		console.log('Clicked index:', clickedIndex);

		if (clickedIndex !== -1) {
			setProfileSliderVisible(true);
			setActiveProfileIndex(clickedIndex);
			document.body.style.overflow = 'hidden'; // Add this line
		}

		// Cleanup function for overflow hidden bug
		return () => {
			document.body.style.overflow = ''; // Reset to default
		};
	};

	// For swiper (handle opening / closing swiper + disabling body scrolling)

	const swiperInstance = swiperRef.current;

	const handleCloseSwiper = () => {
		setProfileSliderVisible(false);
		setActiveProfileIndex(null);
		document.body.style.overflow = 'auto';
	};

	const handleShowSwiper = () => {
		document.body.style.overflow = 'hidden';
		setProfileSliderVisible(true);

		return () => {
			document.body.style.overflow = ''; // Reset to default
		};
	};

	// For swiper (handle slide navigation)

	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.swiper.slidePrev();
		}
	};

	const handleNextSlide = () => {
		//loadMore()
		if (swiperRef.current) {
			swiperRef.current.swiper.slideNext();
		}
	};

	const resetSwiper = () => {
		console.log('Reset Swiper');
		//
		if (swiperInstance && swiperInstance.swiper) {
			handleCloseSwiper();
			//swiperInstance.swiper.destroy(true, true);
			//swiperRef.current.swiper.update();
			console.log('destroy swiper');
		}
		console.log('load more');
		loadMore(); // Load more slides when you're on the last slide
		//console.log('init')
	};

	const toggleLike = (id, like) => {
		setFollowing(like);
		const updatedList = listRecruiters.map((recruiter) => {
			if (recruiter._id === id) {
				return {
					...recruiter,
					following: like,
				};
			}
			return recruiter;
		});
		//console.log('toggle in tile');
		//console.log(updatedList);
		setRefresh(!refresh);
		//setListRecruiters(updatedList);
	};

	//// RT - HandleLike with alert notification attempt

	let alertVisible = false;
	let displayTimeout = null;
	let hideTimeout = null;

	const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to manage button disabled/enabled
	const [likeButtonTimeout, setLikeButtonTimeout] = useState(null); // State to manage the timeout

	const [isRequestInProgress, setIsRequestInProgress] = useState(false);

	const handleLike = async (id) => {
		if (isRequestInProgress) return; // Prevent further execution if a request is already in progress
		setIsRequestInProgress(true);
		console.log(isRequestInProgress)
		//console.log('handle like in tile')
		setFollowing(true);
		var query = {
			id: id.id,
			like: 1,
		};

		try {
			const res = await axiosPrivate.post('/api/like', query);
			if (res.status === 201) {
				// Your existing logic here...
				//console.log(res.data);
			} else {
				console.error('Request failed with status:', res.status);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setTimeout(() => {
				setIsRequestInProgress(false); // Re-enable the function after a 1-second delay
				console.log(isRequestInProgress)
			}, 2000); // 1000 milliseconds = 1 second
		}
	};

	const handleDislike = async (id) => {
		if (isRequestInProgress) return;
		setIsRequestInProgress(true);
		//console.log('handle dislike in tile')

		setFollowing(false);
		var query = {
			id: id.id,
			like: 0,
		};

		try {
			const res = await axiosPrivate.post('/api/like', query);
			if (res.status === 201) {
				// Your existing logic here...
				//console.log(res.data);
			} else {
				console.error('Request failed with status:', res.status);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setTimeout(() => {
				setIsRequestInProgress(false); // Re-enable the function after a 1-second delay
			}, 2000); // Delay re-enabling the function to prevent rapid clicks
		}
	};



	

	const handleSwiperInit = (swiper) => {
		// This will be called when the Swiper is first initialized.
		// Use this callback to get the data-key of the initial slide.
		setActiveSlide(swiper.activeIndex);
		const activeSlideEl = swiper.slides[swiper.activeIndex];
		const slideKey = activeSlideEl.getAttribute('data-id');
		setActiveID(activeSlideEl.getAttribute('data-id'));
		setActiveFollowState(activeSlideEl.getAttribute('data-following'));
		//console.log('Current Slide Key:', slideKey);
	};

	const swiperSlides = React.useMemo(() => {
		return listRecruiters.map((recruiter, index) => (
			<SwiperSlide key={recruiter.uuid} data-id={recruiter._id} data-following={recruiter.following}>
				<ViewProfile
					uuid={recruiter.uuid}
					clientsearch='true'
					clientid={clientid}
					toggleLike={toggleLike}
					handleLike={handleLike}
					handleDislike={handleDislike}
					listRecruiters={listRecruiters}
					isRequestInProgress={isRequestInProgress}
				/>
			</SwiperSlide>
		));
	}, [listRecruiters]);

	/*** VIEW PROFILE MODAL */

	/* STOPPING PROPAGATION OF THE MATCH LIKE BUTTON CLICK - NOW THAT WHOLE PANEL IS CLICKABLE */

	const onLikeHolderClick = (e, recruiter) => {
		e.stopPropagation(); // Stop the event from propagating up to parent elements
		//console.log(recruiter);
		if (following) {
			//handleDislike({ id: recruiter._id });
		} else {
			//handleLike({ id: recruiter._id });
		}
	};

	return (
		<>
			{isProfileSliderVisible && (
				<div className={`swiper-outer-container ${isProfileSliderVisible ? 'swiper-visible' : ''}`}>
					<div className='container'>
						{listRecruiters.length > 0 ? (
							<Swiper
								ref={swiperRef}
								onSwiper={handleSwiperInit}
								autoHeight={true}
								centeredSlides={true}
								slidesPerView='auto'
								spaceBetween={10}
								initialSlide={activeProfileIndex}
								onSlideChange={(swiper) => {
									// Set the active slide index
									setActiveSlide(swiper.activeIndex);
									const activeSlideEl = swiper.slides[swiper.activeIndex];
									const slideKey = activeSlideEl.getAttribute('data-id');
									setActiveID(activeSlideEl.getAttribute('data-id'));
									setActiveFollowState(activeSlideEl.getAttribute('data-following'));
									//console.log('Current Slide Key:', slideKey);
									//console.log(swiper.activeIndex)
									if (swiper.activeIndex === swiper.slides.length - 1) {
										setShowLastSlideMessage(true);
									} else {
										setShowLastSlideMessage(false);
									}
								}}>
								{swiperSlides}
							</Swiper>
						) : null}
					</div>
					<div className='swiper-toolbar'>
						<button className='swiper-toolbar-prev btn btn-white' onClick={handlePrevSlide}>
							<FontAwesomeIcon icon={faArrowLeft} />
						</button>

						<button className='swiper-toolbar-next btn btn-white' onClick={handleNextSlide}>
							<FontAwesomeIcon icon={faArrowRight} />
						</button>
					</div>
					<button className='swiper-close-button' onClick={handleCloseSwiper}>
						<FontAwesomeIcon icon={faTimes} />
					</button>
				</div>
			)}

			{showLastSlideMessage === true && totalPages != showingPage ? (
				<div className='alert alert-success like-alert white-alert custom-fade-in'>
					<span>
						You've reached the end of page {showingPage ? showingPage : null}. Your search returned{' '}
						{totalPages ? totalPages : null} pages
					</span>
					<button className='btn' onClick={resetSwiper}>
						Load more
					</button>
				</div>
			) : null}

			<div className={`col-md-4 flex-grow ${recruiter.showResultsClass}`}>
				<div
					className={`searchResultsTile ${following ? 'followingTile' : ''} ${recruiter.showResultsClass}`}
					onClick={(e) => viewProfile(e)}
					data-id={recruiter?.uuid}>
					<div className='resultTop'>
						<div className='tileBadgesHolder'>
							{recruiter.premium && <PremiumIcon />}
							{recruiter.profile.verifiedMember && <VerifiedIcon />}
						</div>

						<div className='mailHolder' onClick={(e) => onLikeHolderClick(e, recruiter)}>
							<MailIcon className='mailIcon' />
						</div>

						<div className='likeHolder' onClick={(e) => onLikeHolderClick(e, recruiter)}>
							<div
								className={`like-dislike-button ${isRequestInProgress ? 'd-none' : ''}`}
								onClick={!isRequestInProgress ? (fields) =>
								  following ? handleDislike({ ...fields, id: recruiter._id }) : handleLike({ ...fields, id: recruiter._id }) : undefined}>
								<HeartIcon className='likeIcon' />
							</div>
						</div>
					</div>

					<div className='resultMiddle'>
						<div className='avatarWrapper'>
							<div className='avatarImageWrapper'>
								{recruiter?.profile?.profile_picture === null ||
								typeof recruiter?.profile?.profile_picture === 'undefined' ? (
									<img alt='' src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}></img>
								) : (
									<img alt='' src={`data:image/jpeg;base64,${recruiter?.profile?.profile_picture}`}></img>
								)}
							</div>

							<MatchedScribble />
						</div>

						<div className='resultBio'>
							<span className='name'>
								{recruiter?.profile?.first_name} {recruiter?.premium && <>{recruiter?.profile?.last_name}</>}
							</span>

							<span className='job-title'>{recruiter?.profile.job_title}</span>
						</div>
					</div>
					<div className='resultBottom'>
						<span className='tag review-count'>
							{recruiter?.reviewCount} review{recruiter?.reviewCount !== 1 && 's'}
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default ClientSearchRecruiterTile;
