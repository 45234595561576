import { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import _, { set } from 'lodash';
import './ClientSearch.css';
import industryMaster from '../industryMaster.json';
import IndustrySelect from '../industrySelects';
import ClientSearchRecruiterTile from './ClientSearchRecruiterTile';
import { loadGoogleMaps } from '../loadGoogleMaps';
import ArrowScribble from '../icons/ArrowScribble.js';
import Select from 'react-select';

const ClientSearch = ({ props, clientid, clientIndustry, clientSector, clientSubSector }) => {
	const [isLoading, setLoading] = useState(true);
	//const [recruiters, setRecruiters] = useState()
	const [listRecruiters, setListRecruiters] = useState([]);
	const [newRecruiters, setNewRecruiters] = useState([]);

	const axiosPrivate = useAxiosPrivate();

	/*State*/
	const [input, setInput] = useState('');
	const [distance, setDistance] = useState('');
	// new state variable
	//const [submittedInput, setSubmittedInput] = useState("");
	/*On Form Submit*/
	const [searchLocation, setSearchLocation] = useState({});
	const [refreshState, setRefreshState] = useState(false);

	/** HANDLE LOCATION SEARCH BY GEO BOUNDARY START **/

	const [showing, setShowing] = useState(null);
	const [totalResults, setTotalResults] = useState(null);
	const [totalPages, setTotalPages] = useState(null);
	const [showingStart, setShowingStart] = useState(null);
	const [showingEnd, setShowingEnd] = useState(null);
	const [showingPage, setShowingPage] = useState(null);
	const [sortBy, setSortBy] = useState('default');
	const [endResults, setEndResults] = useState(0);
	const [hasSearched, setHasSearched] = useState(0);

	/** VARIABLES FOR NEW PAGINATION */
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage, setPostsPerPage] = useState(10);

	const [page, setPage] = useState(1);

	/** REQUIRED FOR CLIENT GEO SEARCH */
	const [resultsClear, setResultsClear] = useState([]);
	const [polyData, setPolyData] = useState({});
	const [skip, setSkip] = useState(0); // Initial skip value
	const [prevPolyData, setPrevPolyData] = useState(null);

	// State variables for checkboxes
	const [isPermanent, setIsPermanent] = useState(true);
	const [prevPerm, setPrevPerm] = useState(null);

	const [isContract, setIsContract] = useState(true);
	const [prevContract, setPrevContract] = useState(null);

	const [hideMatched, setHideMatched] = useState(false);

	const [isAny, setIsAny] = useState(false);
	const [prevAny, setPrevAny] = useState(null);

	const [jobType, setJobType] = useState('any');
	const [prevJobType, setPrevJobType] = useState(null);

	// RT amended jobtype options

	const jobTypeOptions = [
		{ value: 'any', label: 'Any' },
		{ value: 'permanent', label: 'Permanent' },
		{ value: 'contract', label: 'Contract' },
	];

	// RT amended handlejobtype

	function handleJobTypeChange(selectedOption) {
		const value = selectedOption.value;
		setJobType(value);

		switch (value) {
			case 'permanent':
				setIsPermanent(true);
				setIsContract(false);
				setIsAny(false);
				break;
			case 'contract':
				setIsPermanent(false);
				setIsContract(true);
				setIsAny(false);
				break;
			case 'any':
				setIsPermanent(true);
				setIsContract(true);
				setIsAny(true);
				break;
			default:
				break;
		}
	}

	// RT - placeholder filter or sort by select options

	const sortByOptions = [
		{ value: 'default', label: 'Default' },
		{ value: 'premium', label: 'Premium' },
		{ value: 'verified', label: 'Verified' },
	];

	// RT - placeholder filter or sort by states

	const [selectedSortOption, setSelectedSortOption] = useState('premium');

	// RT - filter or sort by empty handlers for now
	const [updateCounter, setUpdateCounter] = useState(0);
	function handleSortChange(selectedOption) {
		setListRecruiters([]);
		setSkip(0); // Reset skip
		setPage(1);
		setSelectedSortOption(selectedOption);
		setUpdateCounter((prevCounter) => prevCounter + 1);
	}

	var limit = 12; // Limit of items to be displayed per request

	// const loadPage = (e) => {
	// 	if (e) {
	// 		e.preventDefault();
	// 	}

	// 	setListRecruiters([]); // Clear prevList
	// 	setSkip((prevSkip) => prevSkip + limit); // Increment skip by limit
	// 	setPage((prevPage) => prevPage + 1);
	// };
	function loadPage(index) {

		var page = index.target.id
		// if (e) {
		// 	e.preventDefault();
		// }

		setListRecruiters([]); // Clear prevList
		setSkip((prevSkip) => prevSkip + (limit*page))
		setPage(index.target.id);
	};
	
	const loadMore = (e) => {
		if (e) {
			e.preventDefault();
		}
		setListRecruiters([]); // Clear prevList
		setSkip((prevSkip) => prevSkip + limit); // Increment skip by limit
		setPage((prevPage) => prevPage + 1);
	};

	const loadPrev = (e) => {
		if (e) {
			e.preventDefault();
		}
		setLoading(true);
		setListRecruiters([]); // Clear prevList
		setSkip((prevSkip) => prevSkip - limit); // Increment skip by limit
		setPage((prevPage) => prevPage - 1);
		console.log(listRecruiters);
		setLoading(false);
	};

	const handleSearch = (e) => {
		const skip = (page - 1) * limit;
		setSkip(0);
		//console.log('useEffect');

		if (polyData === 0) {
			setLoading(false);
		} else {
			//setLoading(true);
			//console.log('Data has been updated:', polyData);

			// Compare polyData with its previous value

			if (JSON.stringify(prevPolyData) !== JSON.stringify(polyData)) {
				setListRecruiters([]); // Clear prevList
				setSkip(0); // Reset skip
				setPrevPolyData(polyData); // Update prevPolyData
				console.log('polyData has changed');
			}

			if (JSON.stringify(prevPerm) !== JSON.stringify(isPermanent)) {
				setListRecruiters([]); // Clear prevList
				setSkip(0); // Reset skip
				setPrevPerm(isPermanent); // Update prevPerm
				console.log('isPermanent has changed');
			}

			if (JSON.stringify(prevContract) !== JSON.stringify(isContract)) {
				setListRecruiters([]); // Clear prevList
				setSkip(0); // Reset skip
				setPrevContract(isContract); // Update prevContact
				console.log('isContract has changed');

				if (JSON.stringify(prevAny) !== JSON.stringify(isAny)) {
					setListRecruiters([]); // Clear prevList
					setSkip(0); // Reset skip
					setPrevAny(isAny); // Update prevAny
					console.log('isAny has changed');
				}
			}

			const selectedIndustryValues = industryInput.map((option) => option.value);
			const selectedSectorValues = sectorInput.map((option) => option.value);
			const selectedSubSectorValues = subSectorInput.map((option) => option.value);

			var query = {
				coordinates: polyData,
				keyword: input,
				industry: selectedIndustryValues,
				sector: selectedSectorValues,
				sub_sector: selectedSubSectorValues,
				//distance: distance,
				permanent: isPermanent,
				contract: isContract,
				//hideMatched: hideMatched,
				selectedSortOption,
				limit,
				skip,
				page,
			};

			const data = JSON.stringify(query);

			var advSearch = '/api/recruiters/within';
			const search = async () => {
				setLoading(true);
				setHasSearched(1);
				try {
					const res = await axiosPrivate.post(advSearch, data);
					if (res.data.recruiters.length === 0) {
						console.log('Array is empty!');
						setHasSearched(0);
						setLoading(false);
						setEndResults(1);
					}

					const sortedData = res.data.recruiters.sort((a, b) => {
						if (a.following && !b.following) {
							return -1;
						}
						if (!a.following && b.following) {
							return 1;
						}
						return 0;
					});

					console.log('res.data.recruiters')
					console.log(res.data.recruiters)

					setShowing(res.data.showing);
					setTotalResults(res.data.totalResults);
					setShowingStart(res.data.showing.start);
					setShowingEnd(res.data.showing.end);
					setShowingPage(res.data.showing.page);
					setTotalPages(res.data.totalPages);
					setSortBy(res.data.showing.sortBy);

					//setListRecruiters(prevList => [...prevList, ...res.data.recruiters]);
					setListRecruiters(res.data.recruiters);
					//setListRecruiters(sortedData);

					setLoading(false);
				} catch (err) {
					console.log(err);
					//setLoading(false);
				}
			};
			search();
		}
	};

	useEffect(() => {
		handleSearch();
		//}, [polyData, input, industryInput, sectorInput, subSectorInput, distance, skip, isPermanent, isContract, hideMatched, jobType, page, isAny, selectedSortOption]);
	}, [skip, updateCounter]);

	const [query, setQuery] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [highlightedIndex, setHighlightedIndex] = useState(-1);
	const [shouldFetch, setShouldFetch] = useState(true);

	const inputRef = useRef(null);

	useEffect(() => {
		//loadGoogleMapsApi()
		loadGoogleMaps();
		if (window.google && shouldFetch) {
			const autocompleteService = new window.google.maps.places.AutocompleteService();

			if (query.length > 2) {
				autocompleteService.getPlacePredictions(
					{
						input: query,
						// componentRestrictions: { country: 'gb' },
					},
					(predictions, status) => {
						if (status === window.google.maps.places.PlacesServiceStatus.OK) {
							setSuggestions(predictions);
						}
					}
				);
			}
		}
	}, [query, shouldFetch]); // add shouldFetch as a dependency

	// Controls the highlighted suggestion with keyboard input
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const handleKeyDown = (e) => {
		if (e.key === 'ArrowDown') {
			setHighlightedIndex((prev) => Math.min(prev + 1, suggestions.length - 1));
		} else if (e.key === 'ArrowUp') {
			setHighlightedIndex((prev) => Math.max(prev - 1, 0));
		} else if (e.key === 'Enter' && highlightedIndex >= 0) {
			e.preventDefault();
			const selectedPlace = suggestions[highlightedIndex];
			setQuery(selectedPlace.description);
			setSuggestions([]);
			setHighlightedIndex(-1);
			setShouldFetch(false); // Set to false when a place is selected

			selectPlace(selectedPlace); // The value of "selectedPlace" will fetch the polygon
		}
	};

	const handleGeoInputChange = (e) => {
		//console.log('handleGeoInputChange')
		setQuery(e.target.value);
		setShouldFetch(true); // Set to true when the input changes
		setSkip(0);
	};

	const selectPlace = (place) => {
		setQuery(place.description); // Update query with the selected description
		setSuggestions([]); // Clear suggestions
		setHighlightedIndex(-1);
		setShouldFetch(false);

		const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
		placesService.getDetails({ placeId: place.place_id }, (result, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				const viewport = result.geometry.viewport;
				//console.log(result.geometry.viewport);

				const northEast = viewport.getNorthEast();
				const southWest = viewport.getSouthWest();

				const north = northEast.lat();
				const east = northEast.lng();
				const south = southWest.lat();
				const west = southWest.lng();

				if (south && west && north && east) {
					const geoJsonPolygon = {
						type: 'Polygon',
						coordinates: [
							[
								[west, south], // Bottom-left corner
								[west, north], // Top-left corner
								[east, north], // Top-right corner
								[east, south], // Bottom-right corner
								[west, south], // Close the polygon
							],
						],
					};
					//console.log(geoJsonPolygon);
					setPolyData(geoJsonPolygon);
					setResultsClear([]);
				}
			}
		});
	};
	/** HANDLE LOCATION SEARCH BY GEO BOUNDARY END **/

	/* GET FOLLOWING */
	useEffect(() => {
		let isMounted = true;
		getFollowing();
		return () => {
			isMounted = false;
		};
	}, []);

	const [following, setFollowing] = useState('');

	const getFollowing = async () => {
		console.log('get following');
		//handleSubmit();
	};

	var opts = {
		errorCorrectionLevel: 'H',
		type: 'image/jpeg',
		quality: 0.3,
		margin: 1,
		color: {
			dark: '#010599FF',
			light: '#FFBF60FF',
		},
	};

	/* END GET FOLLOWING */

	/** SETUP INDUSTRY, SECTOR, SUBSECTOR **/
	const industriesData = industryMaster;

	const [industryOptions, setIndustryOptions] = useState([]);
	const [sectorOptions, setSectorOptions] = useState([]);
	const [subSectorOptions, setSubSectorOptions] = useState([]);

	const [industryInput, setIndustryInput] = useState([]);
	const [sectorInput, setSectorInput] = useState([]);
	const [subSectorInput, setSubSectorInput] = useState([]);

	useEffect(() => {
		// Populate industry dropdown options
		const industries = industriesData.industries.map((industry) => (
			<option key={industry.name} value={industry.name}>
				{industry.name}
			</option>
		));
		setIndustryOptions(industries);
	}, []);

	let initialIndustry, initialSectors, initialSubSectors;
	initialIndustry = '';
	initialSectors = '';
	initialSubSectors = '';
	/*
	if (Array.isArray(clientIndustry)) {
		initialIndustry = clientIndustry.map(item => ({
			value: item,
			label: item
		}));
	} else {
		initialIndustry = '';
	}

	if (Array.isArray(clientSector)) {
		initialSectors = clientSector.map(item => ({
			value: item,
			label: item
		}));
	} else {
		initialSectors = '';
	}

	if (Array.isArray(clientSubSector)) {
		initialSubSectors = clientSubSector.map(item => ({
			value: item,
			label: item
		}));
	} else {
		initialSubSectors = '';
	}
*/

	const handleInputChange = (fields) => {
		setListRecruiters([]);
		setSkip(0); // Reset ski
		// Assuming fields.industry_1, fields.sector, and fields.sub_sector are arrays
		const industryOptions = fields.industry_1.map((item) => ({
			value: item,
			label: item,
		}));
		const sectorOptions = fields.sector.map((item) => ({
			value: item,
			label: item,
		}));
		const subSectorOptions = fields.sub_sector.map((item) => ({
			value: item,
			label: item,
		}));

		// Update the state with the options
		setIndustryInput(industryOptions);
		setSectorInput(sectorOptions);
		setSubSectorInput(subSectorOptions);

		setSectorOptions(sectorOptions);
		setSubSectorOptions(subSectorOptions);
		//console.log(industryInput, sectorInput, subSectorInput)
	};
	//const isButtonDisabled = sectorOptions.length === 0 || subSectorOptions.length === 0;
	const isButtonDisabled = sectorOptions.length === 0;
	/** END SETUP INDUSTRY, SECTOR, SUBSECTOR **/

	if (isLoading) {
		return (
			<div className='App'>
				<div className='lds-fullscreen lds-facebook'>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	}

	const customStyles = {
		overlay: {
			zIndex: 1000, // Set this to the value you need
		},
	};

	return (
		<article className='clientSearch'>
			{/*props?.default_location?.name*/}

			{/* permanent colour strip */}

			{hasSearched === 0 ? (
				<div className='notification-bar bg-secondary'>
					<div className='container'>
						<div className='col-md-12 d-flex align-items-center justify-content-center'>
							<span className='h4'>
								<div className='searchtTitleHolder'>
									<ArrowScribble />
									Start your search below
								</div>
							</span>
						</div>
					</div>
				</div>
			) : null}

			<div className='app-main-wrapper-condensed'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<div className='form-panel-search'>
								<h1 className='h3'>Find a Consultant</h1>

								<form className=''>
									{/** SEARCH LAYOUT - REMOVE clientSearchLayout-option2 CLASS TO RETURN TO PREVIOUS STYLE */}

									<div className='searchOptions clientSearchLayout clientSearchLayout-option2'>
										{/** INDUSTRY SELECTS */}

										<IndustrySelect
											initialIndustry={industryInput}
											title1={'I am looking for a recruitment consultant in:'}
											title1_tip={'Lorem ipsum glora sit amet'}
											initialSectors={sectorInput}
											title2={'Who specialises in:'}
											title2_tip={'Lorem ipsum glora sit amet'}
											initialSubSectors={subSectorInput}
											title3={'Who has experience with:'}
											title3_tip={'Lorem ipsum glora sit amet'}
											onProfileChange={handleInputChange}
											onChange={handleInputChange}
										/>

										{/** LOCATION SEARCH WITHIN GEO BOUNDARY INPUT START */}

										<div className='geoSearch selector-group'>
											<label>Location:</label>
											<div className='tooltip-container'>
												<img
													src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
													className='tooltip-icon'
												/>
												<span className='tooltiptext'>Lorem ipsum for geoSearch</span>
											</div>
											<div className='geoSearch-input-wrapper'>
												<input
													ref={inputRef}
													type='text'
													value={query}
													onChange={handleGeoInputChange} // use handleInputChange instead
													onKeyDown={handleKeyDown}
													placeholder='Enter a location...'
												/>
												<ul className='geoSearch-selector'>
													{suggestions.map((suggestion, index) => (
														<li
															key={index}
															onClick={() => selectPlace(suggestion)}
															className={index === highlightedIndex ? 'highlighted' : ''}>
															{suggestion.description}
														</li>
													))}
												</ul>
											</div>
										</div>

										{/* Jobtype select */}

										<div className='selector-group'>
											<p>Permanent / Contract:</p>

											<div className='tooltip-container'>
												<img
													src={process.env.REACT_APP_PUBLIC_URL + '/assets/fm/info-icon-yellow.svg'}
													className='tooltip-icon'
												/>
												<span className='tooltiptext'>Lorem ipsum for Contract</span>
											</div>

											{/* Job Type Dropdown - amended to react Selects */}
											<Select
												menuPortalTarget={document.body}
												menuPosition={'fixed'}
												options={jobTypeOptions}
												value={jobTypeOptions.find((opt) => opt.value === jobType)}
												onChange={handleJobTypeChange}
												placeholder='Select Job Type'
												classNamePrefix='rfSelectSingle'
												id='job_type'
											/>

											{/* Hide Matched checkbox 
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={hideMatched}
                                          onChange={handleHideMatchedChange}
                                        />
                                        Hide Matched
                                      </label>
                                      */}
										</div>

										<div className='searchButton'>
											<label htmlFor='searchButton'></label>
											<button
												className='btn btn-secondary btn-md'
												onClick={handleSearch}
												disabled={isButtonDisabled}>
												Search
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div className='searchResultsToolbar'>
						<div className='toolbar-left'>
							<div className='toolbar-item'>
								Showing {totalResults ? totalResults : '0'} results across {totalPages ? totalPages : '0'}{' '}
								pages
							</div>
						</div>

						<div className='toolbar-right'>
							<div className='toolbar-sorting'>
								{/** RT - PLACEHOLDER 'SORT' DROPDOWN **/}

								<form className='SearchSorting'>
									<div className='sort-dropdown'>
										<label htmlFor='sortSelect'>Sort by:</label>

										<Select
											id="sortSelect"
											menuPortalTarget={document.body}
											menuPosition={'fixed'}
											options={sortByOptions}
											value={selectedSortOption}
											onChange={handleSortChange}
											placeholder="Premium"
											classNamePrefix="rfSelectSingleSortingDropdown"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>

					{/** SEARCH RESULTS SECTION */}

					<div className='searchResults row'>
						{/* has not searched notifcation bar */}

						{listRecruiters.map((recruiter) => (
							<ClientSearchRecruiterTile
								key={recruiter._id}
								recruiter={recruiter}
								clientid={clientid}
								listRecruiters={listRecruiters}
								loadMore={loadMore}
								loadPrev={loadPrev}
								newRecruiters={newRecruiters}
								totalPages={totalPages}
								showingPage={showingPage}
							/>
						))}
					</div>

					{/* No results strip */}

					{listRecruiters < 0 ? (
						<div className='notification-bar bg-grey'>
							<div className='container'>
								<div className='col-md-12 d-flex align-items-center justify-content-center'>
									<span className='hfont'>Oops! Came up empty. How about casting a wider net?</span>
								</div>
							</div>
						</div>
					) : null}

					<div className='d-flex align-items-center justify-content-center'>

						<div className='searchPagination'>
							<div className='btn-wrapper'>
								{showingPage > 1 && listRecruiters.length > 0 ? (
									<button className='btn btn-primary btn-sm align-self-center' onClick={loadPrev}>
										Previous
									</button>
								) : null}
								</div>
								<div className='pagination-count'>
									{[...Array(totalPages)].map((elementInArray, index) => (
										<span onClick={loadPage} id={index+1} className={`pageNumber ${showingPage == index+1 ? 'activePage' : ''}`}>{index + 1} </span>
									)
									)}
								</div>
								<div className='btn-wrapper'>
									{showingPage != totalPages && listRecruiters.length > 0 ? (
										<button className='btn btn-primary btn-sm align-self-center' onClick={loadMore}>
											Next
										</button>
									) : null}
								</div>
							
						</div>

						{/* <div className='searchPagination'>
							<div className='btn-wrapper'>
								{showingPage > 1 && listRecruiters.length > 0 ? (
									<button className='btn btn-primary btn-sm align-self-center' onClick={loadPrev}>
										Previous
									</button>
								) : null}
							</div>

							<div className='pagination-count'>
								Page {showingPage ? showingPage : '0'} of {totalPages ? totalPages : '0'}
							</div>

							<div className='btn-wrapper'>
								{showingPage != totalPages && listRecruiters.length > 0 ? (
									<button className='btn btn-primary btn-sm align-self-center' onClick={loadMore}>
										Next 
									</button>
								) : null}
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</article>
	);
};

export default ClientSearch;
