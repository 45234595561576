import { useLocation, NavLink } from 'react-router-dom';
import { useState, useEffect, memo } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import Modal from 'react-modal/lib/components/Modal';
import { faTimes, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeartIcon from './icons/HeartIcon.js';
import MailIcon from './icons/MailIcon.js';
import ReviewIcon from './icons/ReviewIcon.js';
import VerifiedIcon from './icons/VerifiedIcon.js';
import PremiumIcon from './icons/PremiumIcon.js';
import PlayIcon from './icons/PlayIcon.js';
import PaintRollerIcon from './icons/PaintRollerIcon.js';
import MatchedScribble from './icons/MatchedScribble.js';
import MessageIcon from './icons/MessageIcon.js';
import PhoneIcon from './icons/PhoneIcon.js';
import CalendlyIcon from './icons/CalendlyIcon.js';
import LinkIcon from './icons/LinkIcon.js';
import LocationIcon from './icons/LocationIcon';
import SterlingIcon from './icons/SterlingIcon';

import useAuth from '../hooks/useAuth';
//import { set } from "lodash";

import ClientLeaveReview from './client-components/ClientLeaveReview';
//import ClientDirectRecruiterMessage from "./client-components/ClientDirectRecruiterMessage";
import ClientSendNewMessage from './client-components/ClientSendNewMessage';

const ViewProfile = ({
	uuid,
	toggleLike,
	handleLike,
	handleDislike,
	listRecruiters,
	sendBackgroundImage,
}) => {
	//console.log(props.uuid)

	const axiosPrivate = useAxiosPrivate();
	const [loading, setLoading] = useState(true);

	const [profile, setProfile] = useState();

	const [personalQuestions, setPersonalQuestions] = useState();
	const [recruitmentQuestions, setRecruitmentQuestions] = useState();

	const [following, setFollowing] = useState(false);
	const [like, setLike] = useState(following);

	const { auth } = useAuth();

	var url;
	if (uuid) {
		var url = uuid;
	} else {
		var url = window.location.href.split('/')[4];
	}

	const toggleProfileLike = ({ id, like }) => {
		const query = {
			id,
			like,
		};
		if (like === 1) {
			setLike('matched');
			setFollowing(true);
			handleLike(query);
			toggleLike(id, true);
		} else if (like === 0) {
			setLike('unmatched');
			setFollowing(false);
			handleDislike(query);
			toggleLike(id, false);
		}
	};

	// RT - SHOW ALL / SHOW MORE SECTOR TAGS SOLUTION

	const [showAllTags, setShowAllTags] = useState(false);

	const toggleTags = () => {
		setShowAllTags(!showAllTags);
	};

	// const allTags = [
	// 	...(profile?.[0]?.profile?.industry_1 ? [profile?.[0]?.profile?.industry_1] : []),
	// 	...(profile?.[0]?.profile?.sector || []),
	// 	...(profile?.[0]?.profile?.sub_sector || []),
	// ];
	const industryTags = [
		...(profile?.[0]?.profile?.industry_1 ? [profile?.[0]?.profile?.industry_1] : [])
	];
	const sectorTags = [
		...(profile?.[0]?.profile?.sector || []),
	];
	const subsectorTags = [
		...(profile?.[0]?.profile?.sub_sector || []),
	];

	// const tagsToShow = showAllTags ? allTags : allTags.slice(0, 3);
	const industryTagsToShow = showAllTags ? industryTags : industryTags.slice(0, 3);
	const sectorTagsToShow = showAllTags ? sectorTags : sectorTags.slice(0, 3);
	const subsectorTagsToShow = showAllTags ? subsectorTags : subsectorTags.slice(0, 3);

	const hiddenTagsCount = sectorTags.length - 3; // Number of tags that are not initially shown

	// RT - SHOW MORE REVIEWS SOLUTION

	const initial_reviews_count = 3;

	// State to keep track of how many reviews to show
	const [visibleReviewsCount, setVisibleReviewsCount] = useState(initial_reviews_count);
	const [showAllReviews, setShowAllReviews] = useState(false); // New state to toggle view

	const toggleReviewsDisplay = () => {
		if (showAllReviews) {
			setVisibleReviewsCount(initial_reviews_count); // Show only the initial three reviews
			setShowAllReviews(false);
		} else {
			setVisibleReviewsCount(profile?.[1]?.reviews?.content?.length || 0); // Show all reviews
			setShowAllReviews(true);
		}
	};

	// Calculate the number of reviews not currently shown
	//const additionalReviewsCount = (profile?.[1]?.reviews?.content?.length || 0) - visibleReviewsCount;

	// RT - Utility function to format salary
	const formatSalary = (salary) => {
		if (!salary) return '';
		const salaryNumber = parseInt(salary, 10);
		return `\u00A3${salaryNumber.toLocaleString('en-UK')}`; // Format as poundage
	};

	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(dateString).toLocaleDateString(undefined, options);
	};

	useEffect(() => {
		//console.log('useEffect')
		let isMounted = true;
		const controller = new AbortController();
		setLoading(true);
		//console.log(auth)
		if (auth.cuid) {
			//console.log('clientID '+auth.cuid)
			//console.log('requesting profile')
			//console.log('loggedin use axios private');
			//var questions;
			const getProfile_Private = async () => {
				const public_view_private = '/api/private/profile/' + url + '/' + auth.cuid;
				//console.log(public_view_private);
				//console.log('fetch private');
				try {
					const res = await axiosPrivate.get(public_view_private);
					if (res.data.length === 0) {
						console.log('Array is empty!');
					}
					//console.log(res.data)

					setProfile(res.data);
					setPersonalQuestions(res.data?.[0]?.profile?.questions?.personal);
					setRecruitmentQuestions(res.data?.[0]?.profile?.questions?.recruitment);

					if (sendBackgroundImage) {
						const sendData = () => {
							sendBackgroundImage(res.data?.[0]?.profile?.background_picture);
						};
						sendData();
					}
					setFollowing(res.data?.[4]?.following);
					if (res.data.length > 0) {
						setLoading(false);
					}
					setLoading(false);
				} catch (err) {
					console.log(err);
				} finally {
					//console.log('client finally')
				}
			};
			getProfile_Private();
		} else if (!auth.cuid) {
			console.log('not loggedin use fetch public');
			var questions;
			const getProfile = async () => {
				const fetchURL = process.env.REACT_APP_RECFINDR_API + '/api/public/profile/' + url;
				//console.log('fetchURL');
				try {
					const response = await fetch(fetchURL);
					const jsonData = await response.json();

					setProfile(jsonData);
					setPersonalQuestions(jsonData?.[0]?.profile?.questions?.personal);
					setRecruitmentQuestions(jsonData?.[0]?.profile?.questions?.recruitment);

					if (sendBackgroundImage) {
						const sendData = () => {
							sendBackgroundImage(jsonData?.[0]?.profile?.background_picture);
						};
						sendData();
					}

					setLoading(false);
					if (jsonData.length > 0) {
						setLoading(false);
					}
				} catch (error) {
					console.log('Error fetching data:', error);
				} finally {
					//console.log('public finally')
				}
			};
			getProfile();
		}
		return () => {
			//isMounted = false;
			controller.abort();
		};
	}, [toggleLike, listRecruiters]);

	const [reviewIsOpen, setReviewIsOpen] = useState(() => {
		return window.location.pathname.endsWith('/review');
	});

	const [reviewModalContent, setReviewModalContent] = useState(false);

	function openReviewModal() {
		setReviewIsOpen(true);
	}

	function afterReviewOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeReviewModal() {
		setReviewIsOpen(false);
	}

	const reviewModal = (e) => {
		var data = {
			id: e.target.dataset.id,
		};
		//console.log(e.target.dataset.id )
		//var getProfile = '/api/recruiters/view/';
		setReviewModalContent({
			id: e.target.dataset.id,
			name: e.target.dataset.name,
		});

		console.log(data);
		openReviewModal();
	};

	/*** END Review MODAL */

	const cannotReview = (e) => {
		alert('Disabled: Recruiters cannot review their own profile');
	};

	/*** Msg MODAL */
	//const [msgIsOpen, setMsgIsOpen] = useState();
	const [msgModalContent, setMsgModalContent] = useState(false);
	const [msgIsOpen, setMsgIsOpen] = useState(() => {
		return window.location.pathname.endsWith('/message');
	});

	function openMsgModal() {
		setMsgIsOpen(true);
	}

	function afterMsgOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeMsgModal() {
		setMsgIsOpen(false);
	}

	const msgModal = (e) => {
		var data = {
			id: e.target.dataset.id,
		};
		//console.log(e.target.dataset.id )
		//var getProfile = '/api/recruiters/view/';
		setMsgModalContent({
			id: e.target.dataset.id,
			name: e.target.dataset.name,
		});

		console.log(data);
		openMsgModal();
	};

	/*** END Msg MODAL */

	// RT - CONTACT INFO REVEAL SOLUTION

	const [emailRevealed, setEmailRevealed] = useState(false);
	const [phoneRevealed, setPhoneRevealed] = useState(false);

	function ensureProtocol(url) {
		if (!url) return url; // If the URL is not provided, return as is
		if (!/^(?:f|ht)tps?:\/\//.test(url)) {
			return `https://${url}`; // Prepend with https:// if the protocol is missing
		}
		return url; // If the protocol is already there, return the original URL
	}

	// Handle Interactions
	const handleInteraction = async (interactionType) => {
		//console.log(interactionType)
		//console.log(profile[0]._id)
		const interactionDetails = {
			interactions: [
				{
					userId: profile[0]._id, // replace this with the ID of the user being interacted with
					interactionType: interactionType, // replace this with the appropriate type
					//interactingUser: 'userId', // replace this with the ID of the interacting user
				},
			],
		};
		try {
			const res = await axiosPrivate.post('/api/interactions', interactionDetails);

			if (res.data.length === 0) {
				console.log('Array is empty!');
			}
			console.log(res.data);
			setLike('matched');
			//setRecruiters(res.data)
			//handleSubmit()
		} catch (err) {
			console.log(err);
		}
	};

	// RT - INTERACTION REVEAL For email
	const handleEmailClick = () => {
		if (!emailRevealed) {
			setEmailRevealed(true);
			handleInteraction('email');
		}
	};

	// RT - INTERACTION REVEAL For phone
	const handlePhoneClick = () => {
		if (!phoneRevealed) {
			setPhoneRevealed(true);
			handleInteraction('phone');
		}
	};

	const reportUser = async (e) => {
		e.preventDefault();

		const reportedProfileId = e.currentTarget.getAttribute('data-id');
		const reportedProfileUuid = e.currentTarget.getAttribute('data-uuid');
		console.log('Reporting user:', reportedProfileId, reportedProfileUuid);

		try {
			const res = await axiosPrivate.post('/api/inappropriate', {
				reportedProfileUuid,
				reportedProfileId,
			});

			if (res.data.length === 0) {
				console.log('Array is empty!');
			}
			console.log(res.data);
			//setRecruiters(res.data)
			//handleSubmit()
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			{loading ? (
				<>
					<div className='lds-fullscreen lds-facebook'>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</>
			) : (
				<>
					{/*auth && auth?.accessToken ? (<>logged in view</>) : (<>logged out view</>)*/}

					{/* CHECK LOGGED IN STATE? IF TRUE SHOW PROILE, ELSE REQ LOGIN*/}

					{auth?.cuid ? (
						<>
							<Modal
								isOpen={reviewIsOpen}
								onAfterOpen={afterReviewOpenModal}
								onRequestClose={closeReviewModal}
								shouldCloseOnOverlayClick={true}
								ariaHideApp={false}
								contentLabel='Review'
								id='reviewModal'
								overlayClassName='reviewModalOverlay'
								className='container'
								style={{
									overlay: {
										backgroundColor: 'rgba(000, 000, 000, 0.05)',
										zIndex: 10000,
									},

									content: {
										zIndex: 10000,
									},
								}}>
								<div className='modal-body'>
									<div className='review-panel'>
										<button className='closeRec' onClick={closeReviewModal}>
											<FontAwesomeIcon icon={faTimes} />{' '}
										</button>
										<ClientLeaveReview ruid={profile?.[0]?._id} />
									</div>
								</div>
							</Modal>

							<Modal
								isOpen={msgIsOpen}
								onAfterOpen={afterMsgOpenModal}
								onRequestClose={closeMsgModal}
								ariaHideApp={false}
								contentLabel='Msg'
								id='msgModal'
								shouldCloseOnOverlayClick={true}
								overlayClassName='msgModalOverlay'
								className='container'
								style={{
									overlay: {
										backgroundColor: 'rgba(255, 255, 255, 0)',
										zIndex: 10000,
									},
									content: {
										zIndex: 10000,
									},
								}}>
								<div className='modal-body'>
									<div className='direct-message-panel'>
										<button className='closeRec' onClick={closeMsgModal}>
											<FontAwesomeIcon icon={faTimes} />{' '}
										</button>
										<ClientSendNewMessage
											ruid={profile?.[0]?._id}
											first_name={profile[0]?.profile?.first_name}
										/>
									</div>
								</div>
							</Modal>

							<div className={`viewProfile ${following ? 'followingProfile' : ''}`}>
								<div className='viewProfile-wrapper'>
									<div className='profile-toplevel-info-wrapper'>
										<div className='profileTopbar-wrapper'>
											{auth.role === 3001 && (
												<div className='profileTopbar-recruitertoolbar'>
													<NavLink
														className={({ isActive }) =>
															isActive ? 'btn btn-with-icon active' : 'btn btn btn-with-icon inactive'
														}
														to='/profile'>
														<PaintRollerIcon />
														Edit my profile
													</NavLink>
												</div>
											)}

											{/****** leave a review logic? ******/}

											{/****** reviews with paid / unpaid can revew ******/}

											{/* {profile[0]?.profile?.[0]?.paidBySelf || profile[0]?.profile?.paidByCompany ? (
												<>
													{profile[0]?._id !== auth?.cuid && auth.role !== 3001 && (
														<>
															{following ? (
																<div
																	className='reviewHolder'
																	onClick={reviewModal}
																	data-id={profile[0]?.profile?._id}
																	data-name={profile[0]?.profile?.first_name}>
																	<ReviewIcon className='ReviewIcon' />
																</div>
															) : (
																<div className='reviewHolder'>
																	<ReviewIcon className='ReviewIcon' />
																</div>
															)}
														</>
													)}
												</>
											) : (
												<></>
											)} */}

											{/****** reviews anyone can review ******/}

											{profile[0]?._id !== auth?.cuid && auth.role !== 3001 && (
												<>
													{following ? (
														<div
															className='reviewHolder'
															onClick={reviewModal}
															data-id={profile[0]?.profile?._id}
															data-name={profile[0]?.profile?.first_name}>
															<ReviewIcon className='ReviewIcon' />
														</div>
													) : (
														<div className='reviewHolder'>
															<ReviewIcon className='ReviewIcon' />
														</div>
													)}
												</>
											)}

											{/****** msg and like icons ******/}

											{auth && auth?.accessToken && (
												<>
													{
														// Check if the role is not 3001; only clients can match
														auth.role === 3001 ? null : (
															<>
																<div className='mailHolder'>
																	<MailIcon
																		className='mailIcon'
																		style={{
																			pointerEvents: following ? 'auto' : 'none',
																		}}
																		onClick={following ? msgModal : undefined}
																		data-id={profile[0]?.profile?._id}
																		data-name={profile[0]?.profile?.first_name}
																	/>
																</div>
																<div className={`likeHolder`}>
																	<HeartIcon
																		className={`likeIcon matchIcon`}
																		onClick={(fields) =>
																			toggleProfileLike({
																				...fields,
																				id: profile?.[0]?._id,
																				like: following ? 0 : 1,
																			})
																		}
																	/>
																</div>
															</>
														)
													}
												</>
											)}
										</div>

										<div className='profileIntro-wrapper'>
											<div className='profilePicture-wrapper'>
												<MatchedScribble />
												{profile?.[0]?.profile?.profile_picture === null ||
												typeof profile?.[0]?.profile?.profile_picture === 'undefined' ? (
													<img
														className='profilePicture'
														alt=''
														src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}></img>
												) : (
													<img
														className='profilePicture'
														alt=''
														src={`data:image/jpeg;base64,${profile?.[0]?.profile?.profile_picture}`}></img>
												)}
											</div>

											<div className='userinfoWrapper'>
												<div className='usernameWrapper'>
													<span className='username h3'>
														{profile?.[0]?.profile?.first_name}{' '}
														{(profile?.[0]?.profile?.paidByCompany || profile?.[0]?.profile?.paidBySelf) && (
															<>{profile?.[0]?.profile?.last_name}</>
														)}
													</span>
													<div className='BadgesHolder'>
														{/* Premium Member badge */}
														{(profile[0]?.profile?.paidByCompany || profile[0]?.profile?.paidBySelf) && (
															<span className='licence true'>
																<PremiumIcon />
															</span>
														)}

														{/* Verified Member badge */}
														{profile[0]?.profile?.verifiedMember && (
															<span className='verified true'>
																<VerifiedIcon />
															</span>
														)}
													</div>
												</div>

												{/* user job title */}
												<span className='jobtitle'>{profile?.[0]?.profile?.job_title}</span>

												{/* Expertise tags */}
												<div className='expertiseWrapper'>
													<div className='tagsWrapper'>
														{industryTagsToShow.map((tag, index) => (
															<span className='tag tag-tertiary' key={index}>
																{tag}
															</span>
														))}
														{sectorTagsToShow.map((tag, index) => (
															<span className='tag tag-success' key={index}>
																{tag}
															</span>
														))}
														{subsectorTagsToShow.map((tag, index) => (
															<span className='tag tag-secondary' key={index}>
																{tag}
															</span>
														))}
														{hiddenTagsCount > 0 && (
															<button onClick={toggleTags}>
																{showAllTags ? '- less' : `+ ${hiddenTagsCount} more`}
															</button>
														)}
													</div>
												</div>

												{/* Work locations tags */}
												<div className='LocationsWrapper'>
													<div className='tagsWrapper'>
														{profile[0]?.profile?.location_1?.name ? (
															<span className='tag tag-white'>
																<LocationIcon />
																{profile[0]?.profile?.location_1?.name}
															</span>
														) : null}

														{profile[0]?.profile?.location_2?.name ? (
															<span className='tag tag-white'>
																<LocationIcon />
																{profile[0]?.profile?.location_2?.name}
															</span>
														) : null}
													</div>
												</div>
											</div>
										</div>

										{
											following ? (
												/* START CHECK IF FOLLOWED */ <>
													<div className='profileContact-wrapper'>
														<div className='basic-info-contact'>
															<h4 className='mb-0'>Contact me directly:</h4>

															{profile?.[5]?.premium ? (
																''
															) : (
																<div className='non-premium-msg-wrapper'>
																	Contact details are restricted to premium users
																</div>
															)}

															<span className='email btn btn-with-icon'>
																<MessageIcon className='mailIcon' />
																{emailRevealed ? (
																	<a
																		target='_blank'
																		rel='noopener'
																		href={`mailto:${profile?.[0]?.username}?subject=Message from RecFindr`}>
																		{profile?.[0]?.username}
																	</a>
																) : (
																	<span role='button' tabIndex='0' onClick={handleEmailClick}>
																		Show email
																	</span>
																)}
															</span>

															<span
																className={`phone btn btn-with-icon ${
																	!profile?.[0]?.profile?.phone_number ? 'disabled' : ''
																}`}
																aria-disabled={!profile?.[0]?.profile?.phone_number}>
																<PhoneIcon className='phoneIcon' />
																{profile?.[0]?.profile?.phone_number ? (
																	phoneRevealed ? (
																		<a href={`tel:${profile?.[0]?.profile?.phone_number}`}>
																			{profile?.[0]?.profile?.phone_number}
																		</a>
																	) : (
																		<span role='button' tabIndex='0' onClick={handlePhoneClick}>
																			Show phone number
																		</span>
																	)
																) : (
																	<span>No phone number</span>
																)}
															</span>

															<span
																className={`website_url btn btn-with-icon ${
																	!profile?.[0]?.profile?.website_url ? 'disabled' : ''
																}`}
																aria-disabled={!profile?.[0]?.profile?.website_url}>
																<LinkIcon className='LinkIcon' />
																<a
																	href={
																		profile?.[0]?.profile?.website_url
																			? ensureProtocol(profile?.[0]?.profile?.website_url)
																			: '#!'
																	}
																	onClick={(e) => {
																		if (!profile?.[0]?.profile?.website_url) {
																			e.preventDefault();
																		} else {
																			handleInteraction('website');
																		}
																	}}
																	target='_blank'
																	rel='noopener noreferrer'
																	style={{
																		pointerEvents: profile?.[0]?.profile?.website_url ? 'auto' : 'none',
																	}}>
																	More info
																</a>
															</span>

															<span
																className={`calendly btn btn-with-icon ${
																	!profile?.[0]?.profile?.calendly_username ? 'disabled' : ''
																}`}
																aria-disabled={!profile?.[0]?.profile?.calendly_username}>
																<CalendlyIcon className='calendlyIcon' />
																<a
																	href={
																		profile?.[0]?.profile?.calendly_username
																			? `${profile?.[0]?.profile?.calendly_username}`
																			: '#!'
																	}
																	onClick={(e) => {
																		if (!profile?.[0]?.profile?.calendly_username) {
																			e.preventDefault();
																		} else {
																			handleInteraction('calendly');
																		}
																	}}
																	target='_blank'
																	rel='noopener noreferrer'
																	style={{
																		pointerEvents: profile?.[0]?.profile?.calendly_username ? 'auto' : 'none',
																	}}>
																	Calendar
																</a>
															</span>
														</div>
													</div>
												</>
											) : (
												''
											) /* END CHECK IF FOLLOWED */
										}
									</div>

									<div className='profileBody-wrapper'>
										{/****** BIOGRAPHY PANEL ******/}
										<div className='profileBody-column'>
											<div className='profile-panel'>
												<h4>About {profile?.[0]?.profile?.first_name} </h4>

												{profile?.[0]?.profile?.video ? (
													<>
														{profile?.[5]?.premium ? (
																<span className='video-link'>
																<a
																	onClick={() => handleInteraction('video')}
																	href={`${profile?.[0]?.profile?.video}`}
																	target='_blank'
																	rel='noopener'>
																	<PlayIcon /> Watch {profile?.[0]?.profile?.first_name}'s intro video
																</a>
															</span>
															) : (
																<div className='non-premium-msg-wrapper'>
																	Has a video but uses a free account
																</div>
															)}
													</>
												) : (
													''
												)}

												<span>{profile?.[0]?.profile?.biography}</span>
											</div>

											{/****** PERSONAL QUESTIONS ******/}

											<div className='profileQuestions-wrapper personalQuestions'>
												<div className='questionWrapper'>
													<h6 className=''>Personal You</h6>
												</div>
												{personalQuestions?.['1'] ? (
													<div className='questionWrapper'>
														<span className='question'>The reason I got into recruiting…</span>
														<span className='answer'>{personalQuestions?.['1']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['2'] ? (
													<div className='questionWrapper'>
														<span className='question'>My work style in three words…</span>
														<span className='answer'>{personalQuestions?.['2']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['3'] ? (
													<div className='questionWrapper'>
														<span className='question'>The biggest hurdle I’ve overcome…</span>
														<span className='answer'>{personalQuestions?.['3']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['4'] ? (
													<div className='questionWrapper'>
														<span className='question'>“My proudest moment as a recruiter…</span>
														<span className='answer'>{personalQuestions?.['4']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['5'] ? (
													<div className='questionWrapper'>
														<span className='question'>A placement I’ll never forget is…</span>
														<span className='answer'>{personalQuestions?.['5']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['6'] ? (
													<div className='questionWrapper'>
														<span className='question'>When I’m not recruiting, you can find me…</span>
														<span className='answer'>{personalQuestions?.['6']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['7'] ? (
													<div className='questionWrapper'>
														<span className='question'>A book/podcast that influenced my view on work…</span>
														<span className='answer'>{personalQuestions?.['7']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['8'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															The best advice I’ve ever received about recruitment was…”
														</span>
														<span className='answer'>{personalQuestions?.['8']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['9'] ? (
													<div className='questionWrapper'>
														<span className='question'>A common misconception about my job is…</span>
														<span className='answer'>{personalQuestions?.['9']}</span>
													</div>
												) : (
													''
												)}

												{personalQuestions?.['10'] ? (
													<div className='questionWrapper'>
														<span className='question'>If I weren’t a recruiter, I’d probably be…</span>
														<span className='answer'>{personalQuestions?.['10']}</span>
													</div>
												) : (
													''
												)}
												{personalQuestions?.['11'] ? (
													<div className='questionWrapper'>
														<span className='question'>If I weren’t a recruiter, I’d probably be…</span>
														<span className='answer'>{personalQuestions?.['11']}</span>
													</div>
												) : (
													''
												)}
											</div>

											{/****** RECRUITMENT QUESTIONS ******/}

											<div className='profileQuestions-wrapper recruitmentQuestions'>
												<div className='questionWrapper'>
													<h6 className=''>Work You</h6>
												</div>
												{recruitmentQuestions?.['1'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															What are some notable placements you’ve made recently
														</span>
														<span className='answer'>{recruitmentQuestions?.['1']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['2'] ? (
													<div className='questionWrapper'>
														<span className='question'>What’s your sweet spot in recruitment and why?</span>
														<span className='answer'>{recruitmentQuestions?.['2']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['3'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															How do you go about understanding a company’s culture and specific needs for a
															role?
														</span>
														<span className='answer'>{recruitmentQuestions?.['3']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['4'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															Can you give an example of how you’ve tailored your recruitment strategy to fit
															a client’s unique requirements?
														</span>
														<span className='answer'>{recruitmentQuestions?.['4']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['5'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															What’s your typical process for sourcing and vetting candidates?
														</span>
														<span className='answer'>{recruitmentQuestions?.['5']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['6'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															How do you ensure the quality of the candidates you present?
														</span>
														<span className='answer'>{recruitmentQuestions?.['6']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['7'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															How frequently do you update your clients during the recruitment process?
														</span>
														<span className='answer'>{recruitmentQuestions?.['7']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['8'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															What’s your preferred method of communication, and how flexible are you with
															adapting to communication styles?
														</span>
														<span className='answer'>{recruitmentQuestions?.['8']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['9'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															What networks or platforms do you primarily use for sourcing candidates?
														</span>
														<span className='answer'>{recruitmentQuestions?.['9']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['10'] ? (
													<div className='questionWrapper'>
														<span className='question'>
															How do you leverage your network and resources to find hard-to-reach or passive
															candidates?
														</span>
														<span className='answer'>{recruitmentQuestions?.['10']}</span>
													</div>
												) : (
													''
												)}

												{recruitmentQuestions?.['11'] ? (
													<div className='questionWrapper'>
														<span className='question'>Talk through your process for advertising jobs?</span>
														<span className='answer'>{recruitmentQuestions?.['11']}</span>
													</div>
												) : (
													''
												)}
											</div>
										</div>

										<div className='profileBody-column'>
											{/****** RENDER REVIEWS ******/}

											<div className='profile-panel'>
												<h4>My Reviews</h4>

												{profile?.[1]?.reviews?.content?.length ? (
													<>
														{profile?.[1]?.reviews?.content?.slice(0, visibleReviewsCount).map((item, i) => (
															<div className='reviews' key={i}>
																<div className='reviewWrapper'>
																	<div className='reviewItem'>
																		<div className='reviewTile'>
																			<div className='reviewTile-user'>
																				{item?.reviewerId?.profile?.profile_picture === null ||
																				typeof item?.reviewerId?.profile?.profile_picture === 'undefined' ? (
																					<img
																						className='clientAvatar'
																						alt=''
																						src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}
																					/>
																				) : (
																					<img
																						className='clientAvatar'
																						alt=''
																						src={`data:image/jpeg;base64,${item?.reviewerId?.profile?.profile_picture}`}
																					/>
																				)}
																				<div className='reviewTile-userinfo'>
																					<span className='review-name'>
																						{item?.reviewerId?.profile.first_name}
																					</span>
																					<span className='review-company'>
																						{item?.reviewerId?.profile.company}
																					</span>
																				</div>
																			</div>
																			<div className='reviewTile-body'>
																				<span className='review-content' data-reviewid={i}>
																					"{item?.review}"
																				</span>
																				<span className='review-date'>
																					{item?.createdAt && formatDate(item?.createdAt)}
																				</span>
																			</div>
																			<div className='review-emote'>
																				<img
																					src={
																						`/assets/Recfindr_Emotes/Recfindr_Emote-` +
																						item?.reviewEmotion +
																						`.svg`
																					}
																					alt='Emoticon'
																					className='emoticon'
																				/>
																				{item?.reviewEmotion === 1 ? (
																					<span>Great Market Insight</span>
																				) : item?.reviewEmotion === 4 ? (
																					<span>Successful Placement</span>
																				) : item?.reviewEmotion === 3 ? (
																					<span>Provided Great Candidates</span>
																				) : item?.reviewEmotion === 2 ? (
																					<span>Great Listener</span>
																				) : item?.reviewEmotion === 8 ? (
																					<span>Didn't Ghost me</span>
																				) : (
																					``
																				)}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														))}

														{profile?.[1]?.reviews?.content?.length > initial_reviews_count && (
															<div className='toggle-reviews' onClick={toggleReviewsDisplay}>
																{showAllReviews
																	? '- Less'
																	: `+ ${
																			profile?.[1]?.reviews?.content?.length - initial_reviews_count
																	  } more`}
															</div>
														)}
													</>
												) : (
													<div className='reviews no-reviews-panel'>
														<span>{profile?.[0]?.profile?.first_name} has not yet received any reviews.</span>
													</div>
												)}
											</div>

											{/****** PAST ASSIGNMENTS / PLACEMENTS ******/}

											<div className='profile-panel past-assignments'>
												<h4 className='h4'>Previous Placements</h4>
												{profile && profile[3] && profile[3].assignments ? (
													<>
														{
															// Ensure assignments is an array
															Array.isArray(profile[3].assignments) ? (
																profile[3].assignments.map((item, i) => (
																	<div className='assignmentWrapper' key={i}>
																		<div className='assignment-panel'>
																			<span>{item?.job_title}</span>
																			<span>
																				<LocationIcon />
																				{item?.location.name}
																			</span>
																			<span>
																				<SterlingIcon />
																				{formatSalary(item?.salary)}
																			</span>
																		</div>
																	</div>
																))
															) : (
																// Handle the case where assignments is a single object
																<div className='assignmentWrapper'>
																	<div className='assignment-panel'>
																		<span>{profile[3].assignments?.job_title}</span>
																		<span>
																			<LocationIcon />
																			{profile[3].assignments?.location.name}
																		</span>
																		<span>
																			<SterlingIcon />
																			{formatSalary(profile[3].assignments?.salary)}
																		</span>
																	</div>
																</div>
															)
														}
													</>
												) : (
													<>
														<span>{profile?.[0]?.profile?.first_name} has no past placements to show.</span>
													</>
												)}
											</div>
										</div>
									</div>

									<a
										className='flag-profile'
										onClick={reportUser}
										data-id={profile[0]?._id}
										data-uuid={profile[0]?.uuid}>
										<FontAwesomeIcon icon={faFlag} /> Report as inappropriate
									</a>
								</div>
							</div>
						</>
					) : (
						<>
							<span>Must be logged in to view profiles</span>
						</>
					)}
					{/* END CHECK LOGGED IN STATE? IF TRUE SHOW PROILE, ELSE REQ LOGIN*/}
				</>
			)}
		</>
	);
};

export default memo(ViewProfile);
