import React from 'react';

const BackgroundAvatarOverlay = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="background-avatar-overlay"
    viewBox="0 0 248 132"
  >
    <path
      fill="var(--color-white)"
      d="M200.1 75.5H40.5c-5.4 0-9.7 4.4-9.7 9.7V132h179.1V85.2c0-5.4-4.4-9.7-9.8-9.7z"
    />
    <circle cx={56} cy={99.9} r={13.6} fill="var(--color-grey)" />
    <path
      fill="var(--color-grey)"
      d="M198.3 132h-156v-6c0-2.3 1.9-4.2 4.2-4.2h147.6c2.3 0 4.2 1.9 4.2 4.2v6zM76.9 97.4h31.5v3.9H76.9zM76.9 87.7h53.4v8.2H76.9zM76.9 108.4h11.4v3.9H76.9zM90.5 108.4h11.4v3.9H90.5z"
    />
  </svg>

)
export default BackgroundAvatarOverlay