import React from 'react';

const EyeIcon = (props) => (

  <svg
  {...props}
  xmlns="http://www.w3.org/2000/svg"
  height="1em"
  viewBox="0 0 576 512"
>
  <path
    fill="var(--color-black)"
    d="M129.1 361.4C93.6 327.2 67.7 286.9 52.5 256c15.1-30.9 41-71.2 76.6-105.4C171.8 109.5 224.9 80 288 80s116.2 29.5 158.9 70.6c35.6 34.3 61.5 74.5 76.6 105.4-15.1 30.9-41 71.2-76.6 105.4C404.2 402.5 351.1 432 288 432s-116.2-29.5-158.9-70.6zM288 480c158.4 0 258-149.3 288-224-30-74.7-129.6-224-288-224S30 181.3 0 256c30 74.7 129.6 224 288 224zm0-144c-44.2 0-80-35.8-80-80 0-5.4.5-10.6 1.5-15.7L288 256l-15.7-78.5c5.1-1 10.3-1.5 15.7-1.5 44.2 0 80 35.8 80 80s-35.8 80-80 80zm-128-80c0 70.7 57.3 128 128 128s128-57.3 128-128-57.3-128-128-128c-8.6 0-17 .8-25.1 2.5-50.3 10-90 49.5-100.3 99.7l-.1.7c-1.6 8.1-2.5 16.5-2.5 25.1z"
  />
</svg>

)
export default EyeIcon