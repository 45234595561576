import React from "react";
import { NavLink } from "react-router-dom";
import RecfindrLogo from '../icons/RecfindrLogo.js';


const FinishProfileNav = (props) => {

    return (
        <header className={`headerLinks`}>
            <div className="logo">
                <NavLink to="/">
                    <RecfindrLogo />
                </NavLink>
            </div>
            
            
        </header>
    )
}

export default FinishProfileNav;