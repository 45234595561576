import { useLayoutEffect } from "react";
import ClientInbox from "./client-components/ClientInbox";
import RecruiterInbox from "./recruiters-components/RecruiterInbox";
import useAuth from "../hooks/useAuth";

const Inbox = () => {
    const { auth } = useAuth();
    var role = auth.role;
    //console.log(role)

    useLayoutEffect(() => {
        //const html = document.documentElement;
    });
    

    const userRole = auth.role;

    if (userRole.includes(3000)){

        
        
    } else if (userRole.includes(3001)) {

        
        return (
            <>
                <RecruiterInbox/>
            </>
        )

    } else if (userRole.includes(3002)) {

        
        return (
            <section>
                <ClientInbox />
            </section>
        )

    } else { 

        return(
            <>error!</>
        )
    }

}

export default Inbox
