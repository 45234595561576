import React, { useState, useEffect, useRef } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import jquery from 'jquery';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faCheck,
	faArrowLeft,
	faArrowRight,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import LinkIcon from '../icons/LinkIcon.js';
import CopyLinkIcon from '../icons/CopyLinkIcon.js';
import ArrowNextIcon from '../icons/ArrowNextIcon.js';
import ArrowPrevIcon from '../icons/ArrowPrevIcon.js';
import ArrowScribble from '../icons/ArrowScribble.js';

// For swiper (imports)
import { Swiper, SwiperSlide, Navigation, Pagination } from 'swiper/react';
import SwiperCore from 'swiper';
// For swiper (import styles)
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

//Modal.setAppElement('#app');

const RecruiterReviews = ({ props }) => {
	const axiosPrivate = useAxiosPrivate();
	const [reviews, setReviews] = useState(false);
	const [topThree, setTopThree] = useState(false);
	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState(false);
	const [reviewToDelete, setReviewToDelete] = useState(null); // State to keep track of the review to be deleted
	const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] = useState(false); // State to control the delete confirmation modal

	const reviewsRef = useRef(null);

	const [updateCount, setUpdateCount] = useState(0);
	const triggerUpdate = () => {
		setUpdateCount((prevCount) => prevCount + 1);
	};

	/** CHECK IF REVIEWED */
	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();
		const reviewsCheck = async () => {
			try {
				const response = await axiosPrivate.get('/api/reviews/my-reviews/');
				//console.log(response.data);
				setReviews(response.data.content);
				setTopThree(response.data.topThree);
			} catch (err) {
				console.log(err);
			}
		};
		reviewsCheck();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [updateCount]);
	/** END CHECK IF REVIEWED */

	const readFullReview = (e) => {
		setModalContent({
			first_name: reviews[e.target.dataset.id].reviewerId.profile.first_name,
			profile_picture: reviews[e.target.dataset.id].reviewerId.profile.profile_picture,
			content: reviews[e.target.dataset.id].review,
			createdAt: reviews[e.target.dataset.id].createdAt,
			reviewEmotion: reviews[e.target.dataset.id].reviewEmotion,
			reviewScore: reviews[e.target.dataset.id].reviewScore,
		});
		setIsOpen(true);
		//console.log(reviews[e.target.dataset.id].review)
		jquery('[reviewid=' + e.target.dataset.id + ']').html(reviews[e.target.dataset.id].review);
	};

	let subtitle;

	//START REVIEW MODAL
	function openModal() {
		setIsOpen(true);
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
	}

	function closeModal() {
		setIsOpen(false);
	}
	// END REVIEW MODAL

	//START GET MORE REVIEWS MODAL
	const [modalGetMoreIsOpen, setGetMoreIsOpen] = useState(false);
	const handleGetMoreReviews = (e) => {
		//console.log('get more reviews')
		setGetMoreIsOpen(true);
	};

	function openGetMoreModal() {
		setGetMoreIsOpen(true);
	}

	function afterOpenGetMoreModal() {}

	function closeGetMoreModal() {
		setGetMoreIsOpen(false);
	}
	//  END GET MORE REVIEWS MODAL

	const [isCopied, setIsCopied] = useState(false);

	const handleCopy = () => {
		navigator.clipboard.writeText(process.env.REACT_APP_PUBLIC_URL + `/u/` + props);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 3000);
	};
	// START SETUP SWIPER

	// For swiper (states for visibility and active profile index)
	const [isProfileSliderVisible, setProfileSliderVisible] = useState(false);
	const [activeProfileIndex, setActiveProfileIndex] = useState(null);
	const [activeSlide, setActiveSlide] = useState(null);
	const [activeID, setActiveID] = useState(null);

	// For swiper (swiper ref)
	const swiperRef = useRef(null);

	// For swiper (handle opening / closing swiper + disabling body scrolling)

	const swiperInstance = swiperRef.current;

	const handleCloseSwiper = () => {
		setProfileSliderVisible(false);
		setActiveProfileIndex(null);
		document.body.style.overflow = 'auto';
	};

	const handleShowSwiper = () => {
		document.body.style.overflow = 'hidden';
		setProfileSliderVisible(true);
	};

	// For swiper (handle slide navigation)

	const handlePrevSlide = () => {
		{
			/* if (swiperRef.current) {
			swiperRef.current.swiper.slidePrev();

		}*/
		}

		// RT - SWIPER PAG DISABLED - CALLING AFTER INIT

		if (swiperRef.current) {
			swiperRef.current.swiper.slidePrev();
			updateNavigationButtons(swiperRef.current.swiper);
		}
	};

	const handleNextSlide = () => {
		{
			/*
            if (swiperRef.current) {
                swiperRef.current.swiper.slideNext();
            }
        }*/
		}

		if (swiperRef.current) {
			swiperRef.current.swiper.slideNext();
			updateNavigationButtons(swiperRef.current.swiper);
		}
	};

	const resetSwiper = () => {
		console.log('Reset Swiper');
		//
		if (swiperInstance && swiperInstance.swiper) {
			handleCloseSwiper();
			//swiperInstance.swiper.destroy(true, true);
			//swiperRef.current.swiper.update();
			console.log('destroy swiper');
		}
		console.log('load more');
		//console.log('init')
	};

	const handleSwiperInit = (swiper) => {
		setActiveSlide(swiper.activeIndex);
		const activeSlideEl = swiper.slides[swiper.activeIndex];
		setActiveID(activeSlideEl.getAttribute('data-id'));

		// RT - SWIPER PAG DISABLED - CALLING AFTER INIT
		updateNavigationButtons(swiper);
	};

	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(dateString).toLocaleDateString(undefined, options);
	};

	const removeReview = async (_id, revieweeId) => {
		const data = {
			reviewId: _id,
			revieweeId: revieweeId,
		};

		try {
			const response = await axiosPrivate.post('/api/reviews/remove/', data);
			console.log(response.data);
			triggerUpdate();
		} catch (err) {
			console.log(err);
		}
	};

	const approveReview = async (_id, revieweeId) => {
		const data = {
			reviewId: _id,
			revieweeId: revieweeId,
		};

		try {
			const response = await axiosPrivate.post('/api/reviews/approve/', data);
			console.log(response.data);
			triggerUpdate();
		} catch (err) {
			console.log(err);
		}
	};

	const swiperSlides = React.useMemo(() => {
		//console.log(reviews);

		for (let i = 0; i < reviews.length; i += 3) {
			const reviewGroup = reviews.slice(i, i + 3);

			return reviews.map((item, i) => (
				<SwiperSlide key={item?._id} data-id={item?.revieweeId}>
					<div className='reviewItem' key={i}>
						<div className='reviewTile'>
							<div className='reviewTile-cta'>
								<button className='btn btn-icon' onClick={readFullReview} data-id={i}>
									<LinkIcon />
								</button>
							</div>

							<div className='reviewTile-user'>
								{item?.reviewerId?.profile?.profile_picture === null ||
								typeof item?.reviewerId?.profile?.profile_picture === 'undefined' ? (
									<img
										className='clientAvatar'
										alt=''
										src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}
									/>
								) : (
									<img
										className='clientAvatar'
										alt=''
										src={`data:image/jpeg;base64,${item?.reviewerId?.profile?.profile_picture}`}
									/>
								)}

								<div className='reviewTile-userinfo'>
									<span className='review-name'>{item?.reviewerId?.profile?.first_name}</span>
									<span className='review-company'>{item?.reviewerId?.profile?.company}</span>
								</div>
							</div>

							<div className='reviewTile-body'>
								<span className='review-content' data-reviewid={i}>
									"{item.review.substring(0, 240)}..."
								</span>
								<span className='review-date'>{item?.createdAt && formatDate(item?.createdAt)}</span>
							</div>
							<div className='reviewTile-actions'>
								{item.approved ? (
									<>
										<button className='btn btn-xs btn-with-icon' disabled>
											<FontAwesomeIcon icon={faCheck} />
											Approved
										</button>
										<button className='btn btn-xs btn-with-icon' onClick={() => openConfirmDeleteModal(item)}>
											<FontAwesomeIcon icon={faTimes} />
											Remove
										</button>
									</>
								) : (
									<>
										<button
											className='btn btn-xs btn-with-icon'
											onClick={() => approveReview(item._id, item.revieweeId)}>
											<FontAwesomeIcon icon={faCheck} />
											Approve
										</button>
										<button className='btn btn-xs btn-with-icon' onClick={() => openConfirmDeleteModal(item)}>
											<FontAwesomeIcon icon={faTimes} />
											Remove
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</SwiperSlide>
			));
		}
	}, [reviews]);

	// END SETUP SWIPER

	// RT - SWIPER DISABLED BUTTONS STUFF

	const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);

	const updateNavigationButtons = (swiper) => {
		setIsPrevButtonDisabled(swiper.isBeginning);
		setIsNextButtonDisabled(swiper.isEnd);
	};

	// RT - CONFIRM REVIEW DELETION MODAL

	const openConfirmDeleteModal = (review) => {
		setReviewToDelete(review); // Set the review to be deleted
		setConfirmDeleteModalIsOpen(true); // Open the modal
	};

	const closeConfirmDeleteModal = () => {
		setConfirmDeleteModalIsOpen(false);
		setReviewToDelete(null); // Reset the review to be deleted
	};

	const handleDeleteReview = () => {
		if (reviewToDelete) {
			removeReview(reviewToDelete._id, reviewToDelete.revieweeId); // Call your existing removeReview function
			closeConfirmDeleteModal();
		}
	};

	return (
		<>
			<Modal
				isOpen={modalGetMoreIsOpen}
				onAfterOpen={afterOpenGetMoreModal}
				onRequestClose={closeGetMoreModal}
				contentLabel='Get More Reviews Modal'
				id='getMoreReviewsModal'
				overlayClassName='ViewreviewlinkModalOverlay'
				className='container'
				parentSelector={() => document.querySelector('.myReviewsWrapper')}
				style={{
					overlay: {
						backgroundColor: 'rgba(000, 000, 000, 0.05)',
						zIndex: 10000,
					},
					content: {
						zIndex: 10000,
					},
				}}>
				<div className='modal-body'>
					<div className='reviewlink-wrapper'>
						<button className='closeRec' onClick={closeGetMoreModal}>
							<FontAwesomeIcon icon={faTimes} />{' '}
						</button>
						<h3>Share your unique link to get more reviews</h3>
						<div className='d-flex align-items-center'>
							<ArrowScribble />
							<span className='copyLink btn btn-with-icon icon-right' onClick={handleCopy}>
								{process.env.REACT_APP_PUBLIC_URL + `/u/` + props}{' '}
								{isCopied ? <CopyLinkIcon /> : <CopyLinkIcon />}
							</span>
							{isCopied && <span className='copy-confirmation'>&#10004; Copied</span>}
						</div>
					</div>
				</div>

				{/*{modalContent?.createdAt}*/}
			</Modal>

			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				contentLabel='Review Modal'
				id='viewReviewModal'
				overlayClassName='ViewreviewModalOverlay'
				className='container'
				parentSelector={() => document.querySelector('.myReviewsWrapper')}
				style={{
					overlay: {
						backgroundColor: 'rgba(000, 000, 000, 0.05)',
						zIndex: 10000,
					},
					content: {
						zIndex: 10000,
					},
				}}>
				<div className='modal-body'>
					<div className='review-wrapper'>
						<button className='closeRec' onClick={closeModal}>
							<FontAwesomeIcon icon={faTimes} />{' '}
						</button>

						<div className='review-header'>
							{modalContent?.profile_picture === null ||
							typeof modalContent?.profile_picture === 'undefined' ? (
								<img
									className='clientAvatar'
									alt=''
									src={process.env.REACT_APP_RECFINDR_API + '/profile/blank.png'}
								/>
							) : (
								<img
									className='clientAvatar'
									alt=''
									src={`data:image/jpeg;base64,${modalContent?.profile_picture}`}
								/>
							)}
							<h5 className='name mb-0'>
								{modalContent?.first_name} {modalContent?.last_name}
							</h5>
						</div>
						<div className='review-content'>
							<p className=''>"{modalContent.content}"</p>
						</div>
						<div className='review-emote'>
							<img
								src={`/assets/Recfindr_Emotes/Recfindr_Emote-` + modalContent?.reviewEmotion + `.svg`}
								className='reviewEmotion'
							/>
							{modalContent?.reviewEmotion == 1 ? (
								<span>Great Market Insight</span>
							) : modalContent?.reviewEmotion == 4 ? (
								<span>Successful Placement</span>
							) : modalContent?.reviewEmotion == 3 ? (
								<span>Provided Great Candidates</span>
							) : modalContent?.reviewEmotion == 2 ? (
								<span>Great Listener</span>
							) : modalContent?.reviewEmotion == 8 ? (
								<span>Didn't Ghost me</span>
							) : (
								``
							)}
						</div>
					</div>
				</div>

				{/*{modalContent?.createdAt}*/}
			</Modal>

			<Modal
				isOpen={confirmDeleteModalIsOpen}
				onRequestClose={closeConfirmDeleteModal}
				contentLabel='Confirm Delete'
				id='deleteReviewModal'
				overlayClassName='deletereviewModalOverlay'
				className='container'
				parentSelector={() => document.querySelector('.myReviewsWrapper')}
				style={{
					overlay: {
						backgroundColor: 'rgba(000, 000, 000, 0.05)',
						zIndex: 10000,
					},
					content: {
						zIndex: 10000,
					},
				}}>
				<div className='modal-body'>
					<div className='reviewlink-wrapper'>
						<button className='closeRec' onClick={closeConfirmDeleteModal}>
							<FontAwesomeIcon icon={faTimes} />{' '}
						</button>
						<span className='info-icon'>i</span>
						<h4>Confirm Review Removal</h4>
						<p>Are you sure you want to remove this review? This action is permanent and cannot be undone.</p>
						<div className='d-flex align-items-center gap-2'>
							<button className='btn btn-grey' onClick={closeConfirmDeleteModal}>
								Cancel
							</button>
							<button className='btn btn-tertiary' onClick={handleDeleteReview}>
								Remove review
							</button>
						</div>
					</div>
				</div>

				{/*{modalContent?.createdAt}*/}
			</Modal>

			<section className='myReviewsWrapper'>
				<div className='container'>
					<div className='dashboardToolbar'>
						<div className=''>
							<h3 className='mb-0'>Your reviews</h3>
						</div>
						<div className=''>
							<button
								className={`swiper-toolbar-prev btn btn-white ${isPrevButtonDisabled ? 'disabled' : ''}`}
								onClick={handlePrevSlide}
								disabled={isPrevButtonDisabled}>
								<ArrowPrevIcon />
							</button>
							<button
								className={`swiper-toolbar-next btn btn-white ${isNextButtonDisabled ? 'disabled' : ''}`}
								onClick={handleNextSlide}
								disabled={isNextButtonDisabled}>
								<ArrowNextIcon />
							</button>
						</div>
					</div>

					<div className='dashboardPanel'>
						<div className='row'>
							<div className='col-md-4'>
								<div className='dashboardExplainerPanel'>
									<span className='info-icon'>i</span>
									<h5>Reviews intro / reviews matter text</h5>
									<p>Reviews are important and help you stand out text, do a thing and lorem ipsum glory.</p>
									<a className='btn btn-with-icon btn-secondary' onClick={handleGetMoreReviews}>
										<LinkIcon /> Get your review link{' '}
									</a>
								</div>
							</div>
							<div className='col-md-8'>
								{reviews?.length > 0 ? (
									<Swiper
										ref={swiperRef}
										onSwiper={handleSwiperInit}
										autoHeight={false}
										centeredSlides={false}
										slidesPerView='2'
										initialSlide={activeProfileIndex}
										onSlideChange={(swiper) => {
											// Set the active slide index
											setActiveSlide(swiper.activeIndex);
											const activeSlideEl = swiper.slides[swiper.activeIndex];
											const slideKey = activeSlideEl.getAttribute('data-id');
											setActiveID(activeSlideEl.getAttribute('data-id'));
										}}
										className='reviewSwiper'>
										{swiperSlides}
									</Swiper>
								) : (
									<div className='no-content-message-panel'>
										<h4>No reviews yet message</h4>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default RecruiterReviews;
