import React from 'react';

const ArrowNextIcon = (props) => (

  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    height="1em"
    viewBox="0 0 448 512"
  >
    <path
      fill="var(--color-black)"
      d="m422.6 278.6 22.7-22.6-22.6-22.6-144-144L256 66.7 210.8 112l22.6 22.6 89.4 89.4H0v64h322.7l-89.4 89.4-22.5 22.6 45.2 45.3 22.6-22.6 144-144z"
    />
  </svg>

)
export default ArrowNextIcon