import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";


const CompanyRequireProfileSetup = ({ profileSetup }) => {
    const { auth } = useAuth();
    const location = useLocation();

    console.log(auth)


    if(auth.profile_complete === true){
        return(
            <Navigate to="/agency/dashboard" state={{ from: location }} replace />
        );
    } else {
        return(
            <Navigate to="/agency/welcome" state={{ from: location, role: auth.role }} replace />
        );
    }
/*
    return (
        profileSetup?.includes(auth.profile_complete)
            ? <Outlet />
            : auth?.profile_complete
                ? <Navigate to="/welcome" state={{ from: location, role: auth.role }} replace />
                : <Navigate to="/inbox" state={{ from: location }} replace />
    );
    */
}

export default CompanyRequireProfileSetup;
