import React from 'react';

const VerifiedIcon = (props) => (

    <svg
        {...props}
        className="verified-icon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="17.8px"
        height="17.8px"
        viewBox="0 0 17.8 17.8"
        xmlSpace="preserve"
    >
        <path
        fill="var(--color-primary)"
        d="M8.9,0C4,0,0,4,0,8.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9S13.8,0,8.9,0z M7.8,12.6L4.3,9.2l1.3-1.3l2.1,2.1 l4.4-4.8l1.3,1.2L7.8,12.6z"
        />
    </svg>

)
export default VerifiedIcon